import type { OnInit } from '@angular/core';
import { Component, ElementRef, HostListener, Renderer2, ViewChild, inject } from '@angular/core';

const ANGLE = 90;
const ANGLE_ZERO = 0;

@Component({
    selector: 'app-portrait-mode',
    templateUrl: './portrait-mode.component.html',
    styleUrls: ['./portrait-mode.component.scss'],
})
export class PortraitModeComponent implements OnInit {
    private readonly render = inject(Renderer2);

    @ViewChild('root', { static: true }) rootEl!: ElementRef<HTMLDivElement>;

    @HostListener('window:orientationchange', ['$event'])
    onOrientationChange(_event: Event): void {
        this.applyStyles();
    }

    private isMobileDevice(): boolean {
        return /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    private checkStyles(angle: number): void {
        switch (angle) {
            case -ANGLE:
            case ANGLE:
                this.applyLandscapeStyles();
                break;
            default:
                this.applyPortraitStyles();
                break;
        }
    }

    private checkStylesByWidthHeight(): void {
        // set timeout to wait for window to resize to get correct window size
        setTimeout(() => {
            const isLandscape = window.innerWidth > window.innerHeight;
            if (isLandscape) {
                this.applyLandscapeStyles();
            } else {
                this.applyPortraitStyles();
            }
        }, 100);
    }

    private applyLandscapeStyles(): void {
        this.render.setStyle(this.rootEl.nativeElement, 'display', 'flex');
        this.render.setStyle(document.documentElement, 'overflow', 'hidden');
        this.render.setProperty(document.body, 'scroll', 'no');
    }

    private applyPortraitStyles(): void {
        this.render.setStyle(document.documentElement, 'overflow', 'scroll');
        this.render.setProperty(document.body, 'scroll', 'yes');
        this.render.setStyle(this.rootEl.nativeElement, 'display', 'none');
    }

    ngOnInit(): void {
        this.applyStyles();
    }

    applyStyles(): void {
        if (window.screen.orientation != null) {
            this.checkStyles(window.screen.orientation.angle ?? ANGLE_ZERO);
        } else if (this.isMobileDevice()) {
            this.checkStylesByWidthHeight();
        }
    }
}
