import { getFromStorage } from './storage';

/* eslint-disable @typescript-eslint/naming-convention */
export const switchToAppAndCloseWindow = (): void => {
    const device = getFromStorage('DEVICE');
    const protocol = getFromStorage('PROTOCOL');
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!protocol || !device) {
        return;
    }
    if (device === 'ios') {
        const redirectURL = `${protocol}://ch.twint.wallet`;
        window.location.href = redirectURL;
    } else if (device === 'android') {
        const redirectURL = `intent://ch.twint.wallet#Intent;package=${protocol};scheme=twint;end`;

        window.location.href = redirectURL;
    }
};

export const switchToApp = (token: string): string => {
    const device = getFromStorage('DEVICE');
    const protocol = getFromStorage('PROTOCOL');
    let redirectURL = '';
    if (device === 'ios') {
        const appSwitchIOS = {
            app_action_type: 'TWINT_PAYMENT',
            extras: { code: token },
            referer_app_link: { app_name: 'TWINT_MORE', target_url: '', url: '' },
            version: '6.0',
        };
        const jsonEncodedObj = JSON.stringify(appSwitchIOS);

        redirectURL = `${protocol}://applinks/?al_applink_data=${jsonEncodedObj}`;
    } else if (device === 'android') {
        redirectURL = `intent://payment#Intent;package=${protocol};action=ch.twint.action.TWINT_PAYMENT;scheme=twint;S.code=${token};S.startingOrigin=TWINT_MORE;end`;
    }

    return redirectURL;
};
