import type { Observable } from 'rxjs';
import type { IStartSessionRequest } from '../models/start-session-request.interface';
import type { ITokenResponse } from '../models/token-response.interface';

export const SESSION_SERVICE = Symbol('SESSION_SERVICE');

export interface ISessionService {
    getNewAccessToken: () => Observable<ITokenResponse>;

    startSession: (data: IStartSessionRequest) => Observable<ITokenResponse>;
}
