import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs';
import { IProductService, PRODUCTS_SERVICE } from '../../outbound/product.service';
import { NavigationService } from '../../services/navigate.service';
import { HomeActions } from '../actions/action-types';

@Injectable()
export class HomeEffects {
    constructor(
        private readonly actions$: Actions,
        @Inject(PRODUCTS_SERVICE) private readonly productService: IProductService,
        private readonly navigationService: NavigationService,
    ) {}

    loadUpcomingDeal$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HomeActions.loadUpcomingDeal),
            switchMap(() => this.productService.getUpcomingDeal()),
            map((upcomingDeal) => HomeActions.upcomingDealLoaded(upcomingDeal)),
        ),
    );

    loadSuperCashBack$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HomeActions.loadSuperCashBack),
            switchMap(() => this.productService.getSuperCashback()),
            map((superCashBack) => HomeActions.superCashBackLoaded(superCashBack)),
        ),
    );

    loadNewsTicker$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HomeActions.loadNewsTicker),
            switchMap(() => this.productService.getPublishedNewsTicker()),
            map((newsTicker) => HomeActions.newsTickerLoaded(newsTicker)),
        ),
    );

    loadDealsOfTheDay$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HomeActions.loadDealsOfTheDay),
            switchMap(() => this.productService.getDealsOfTheDay()),
            map((dealsOfTheDay) => HomeActions.dealsOfTheDayLoaded(dealsOfTheDay)),
        ),
    );

    loadDiscountOffers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HomeActions.loadDiscountOffers),
            switchMap(() =>
                this.productService.getAffiliates().pipe(
                    map((response) => HomeActions.discountOffersLoaded(response.affiliates)),
                    catchError(() => this.navigationService.navigateToErrorPage()),
                ),
            ),
        ),
    );

    loadDiscountOfferById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HomeActions.loadDiscountOfferById),
            switchMap((action) =>
                this.productService.getAffiliateByAffiliateId(action.id).pipe(
                    map((discountOffer) => HomeActions.discountOfferLoaded(discountOffer)),
                    catchError(() => this.navigationService.navigateToErrorPage()),
                ),
            ),
        ),
    );

    loadShopSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(HomeActions.loadShopSettings),
            switchMap(() => this.productService.getShopSettings()),
            map((shopSettings) => HomeActions.shopSettingsLoaded(shopSettings)),
        ),
    );
}
