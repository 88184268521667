import { createFeature, createReducer, on } from '@ngrx/store';
import { SessionActions } from '../actions/action-types';

export interface SessionState {
    checkInDate: Date | undefined;
    email: string | undefined;
    isTwintTermsAndConditionsChecked: boolean | undefined;
    isP2P: boolean | undefined;
    productLineId: string | undefined;
    terminalId: string | undefined;
}

const initialSessionState: SessionState = {
    checkInDate: undefined,
    email: undefined,
    isTwintTermsAndConditionsChecked: undefined,
    isP2P: undefined,
    productLineId: undefined,
    terminalId: undefined,
};

const sessionReducer = createReducer(
    initialSessionState,

    on(
        SessionActions.sessionStarted,
        (state, action) =>
            ({
                ...state,
                checkInDate: action.payload.checkInDate,
                email: action.payload.user.email,
                isTwintTermsAndConditionsChecked: action.payload.user.twintTC,
                isP2P: action.payload.isP2P,
                productLineId: action.payload.productLineId,
                terminalId: action.payload.terminalId,
            }) satisfies SessionState,
    ),

    on(
        SessionActions.newRefreshTokenLoaded,
        (state, action) =>
            ({
                ...state,
                checkInDate: action.payload?.checkInDate,
                email: action.payload?.user?.email,
                isTwintTermsAndConditionsChecked: action.payload?.user?.twintTC,
                isP2P: action.payload?.isP2P,
                productLineId: action.payload?.productLineId,
                terminalId: action.payload?.terminalId,
            }) satisfies SessionState,
    ),
);

const sessionFeature = createFeature({
    name: 'session',
    reducer: sessionReducer,
});

export const {
    name,
    reducer,
    selectCheckInDate,
    selectEmail,
    selectIsTwintTermsAndConditionsChecked,
    selectIsP2P,
    selectProductLineId,
    selectTerminalId,
} = sessionFeature;
