import { Component, Input, Renderer2 } from '@angular/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
    @Input() isSuperCashbackVisible: boolean = true;
    @Input() isUpcomingDealVisible: boolean = true;
    menuIsOpen: boolean = false;

    constructor(private readonly renderer: Renderer2) {}

    menuIsOpenAction(event: boolean): void {
        this.menuIsOpen = event;
        if (event) {
            this.renderer.setStyle(document.documentElement, 'overflow', 'hidden');
        } else {
            this.renderer.setStyle(document.documentElement, 'overflow', 'scroll');
        }
    }
}
