import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import type { IConfigStock } from '@modeso/types__tsd-lib-products-be';
import { IDynamicConfig, VisualTypes } from '@modeso/types__tsd-lib-products-be';
import { findIndex, isMatch } from 'lodash-es';
import { ZERO_LENGTH } from '../../../../domain/constants/constants';
import { VariationType } from '../../../../domain/models/enums/variation-type.enum';
import type { VariationConfiguration } from '../../../../domain/models/variation-configuration.interface';
import { getLanguage } from '../../../../domain/utils/language';

export interface ITsdVariationSelection {
    property: string;
    value: string;
}

export interface ISpecification {
    key: string;
    label: string;
    disabled: boolean;
    selected: boolean;
    imageType?: VisualTypes.IMAGE | VisualTypes.NO_BG_IMAGE;
}

export interface IVariation {
    title: string;
    key: string;
    type: VariationType;
    data: ISpecification[];
}

@Component({
    selector: 'app-campaign-variation-list',
    templateUrl: './campaign-variation-list.component.html',
    styleUrls: ['./campaign-variation-list.component.scss'],
})
export class CampaignVariationListComponent implements OnInit {
    @Input({ required: true }) configurations: VariationConfiguration[] = [];
    @Input({ required: true }) dealStock: IConfigStock[] = [];
    @Input() orderLineVariation?: IDynamicConfig;
    @Output() selectVariation = new EventEmitter<Record<string, ITsdVariationSelection>>();

    currentSelectedVariationNameToVariation = new Map<string, ITsdVariationSelection>();
    outOfStockMap = new Map<string, boolean>();
    variationList: IVariation[] = [];

    variationType = VariationType;
    protected readonly imageTypes = VisualTypes;
    private readonly language = getLanguage();

    selectSpecification(variantName: string, variantValue: string): void {
        if (this.currentSelectedVariationNameToVariation.get(variantName)?.value === variantValue) return;
        this.currentSelectedVariationNameToVariation.set(variantName, { property: variantName, value: variantValue });

        this.prepareVariationsLists();

        const selectedVariationsAsObject = this.getSelectedVariationsAsObject();

        const selectedVariationConfiguration = this.dealStock.find((deal) =>
            isMatch(deal.config, selectedVariationsAsObject),
        );

        if (selectedVariationConfiguration == null) {
            throw new Error('The selected variations is not exist in the deal stock');
        }

        const isSelectedVariationOutOfStock = selectedVariationConfiguration.remainingStock === ZERO_LENGTH;

        if (isSelectedVariationOutOfStock) {
            this.replaceSelectedOutOfTheStockVariants(selectedVariationConfiguration.config);
        }
        this.disableOutOfTheStockVariants();

        this.emitVariationSelections();
    }

    private getSelectedVariationsAsObject(): Record<string, string> {
        const selectedVariationValues = this.currentSelectedVariationNameToVariation.values();

        const selectedVariations = Array.from(selectedVariationValues);

        const selectedVariationsAsObject: Record<string, string> = {};

        for (const selectedVariation of selectedVariations) {
            selectedVariationsAsObject[`${selectedVariation.property}`] = selectedVariation.value;
        }

        return selectedVariationsAsObject;
    }

    private prepareVariationsLists(): void {
        if (this.configurations.length === ZERO_LENGTH) return;
        this.variationList = [];
        for (const configuration of this.configurations) {
            const variation: IVariation = {
                title: configuration.propertyName[this.language] ?? '',
                key: configuration.property,
                type: configuration.type,
                data: this.getVariationDataFromConfigurationAndConfigurationValues(configuration),
            };

            this.variationList.push(variation);
        }
    }

    private getVariationDataFromConfigurationAndConfigurationValues(
        configuration: VariationConfiguration,
    ): ISpecification[] {
        const variationData: ISpecification[] = [];

        for (const configurationValue of configuration.values) {
            const variation: ISpecification = {
                key: configurationValue.key,
                label: configurationValue[this.language] ?? '',
                disabled: false,
                selected:
                    this.currentSelectedVariationNameToVariation.get(configuration.property)?.value ===
                    configurationValue.key,
            };

            if (configurationValue.imageType != null) {
                variation.imageType = configurationValue.imageType;
            }

            variationData.push(variation);
        }

        return variationData;
    }

    ngOnInit(): void {
        this.prepareVariationsLists();
        let variants: IDynamicConfig;

        if (this.orderLineVariation != null) {
            variants = this.orderLineVariation;
        } else {
            const defaultSelection = this.dealStock.find((deal) => deal.remainingStock > ZERO_LENGTH);
            if (defaultSelection == null) {
                this.disableAllVariantItems();
                return;
            }

            variants = defaultSelection.config;
        }

        for (const variantKey in variants) {
            const variantValue = variants[variantKey];

            this.currentSelectedVariationNameToVariation.set(variantKey, {
                property: variantKey,
                value: variantValue,
            });
            this.changeVariantItemsSelection(variantKey, variantValue, true);
        }

        this.disableOutOfTheStockVariants();

        this.emitVariationSelections();
    }

    private disableOutOfTheStockVariants(): void {
        if (
            this.currentSelectedVariationNameToVariation.size === ZERO_LENGTH ||
            this.currentSelectedVariationNameToVariation.size !== this.configurations.length
        ) {
            return;
        }

        const selectedVariationValues = this.currentSelectedVariationNameToVariation.values();

        const selectedVariations = Array.from(selectedVariationValues);

        // Get all variants combination with empty stock
        let variantsWithEmptyStock = this.dealStock
            .filter((deal) => deal.remainingStock <= ZERO_LENGTH)
            .map((deal) => deal.config);

        const variantsExistenceCount = new Map<string, number>();

        // Count the existence of each variant value that exist in any out of the stock variant combination
        for (const variant of variantsWithEmptyStock) {
            for (const key in variant) {
                const variantValue = variant[key];

                let newCount = 1;
                const oldCount = variantsExistenceCount.get(`${key}_${variantValue}`);
                if (oldCount != null) {
                    newCount += oldCount;
                }

                variantsExistenceCount.set(`${key}_${variantValue}`, newCount);
            }
        }

        // Variants combination of the selected ones except for the last variant group selection
        const partialSelectedVariantsAsObject: Record<string, string> = {};
        for (
            let selectedVariantIndex = 0;
            selectedVariantIndex < selectedVariations.length - 1;
            selectedVariantIndex++
        ) {
            const selectedVariation = selectedVariations[selectedVariantIndex];

            const variationName = selectedVariation.property;
            const variationValue = selectedVariation.value;

            // Get the next variation group combinations count
            let nextVariationCombinationsCount = 1;
            for (
                let configurationIndex = selectedVariantIndex + 1;
                configurationIndex < this.configurations.length;
                configurationIndex++
            ) {
                const configuration = this.configurations[configurationIndex];
                nextVariationCombinationsCount *= configuration.values.length;
            }

            const variantValuesToBeDisabled = new Set<string>();

            // Check if there is a variant value existence count equal to the next variation group combination
            // Then it should be disabled, as this means this variant is totally out of the stock in all combinations
            // eslint-disable-next-line no-loop-func, @typescript-eslint/no-loop-func
            variantsExistenceCount.forEach((value, key) => {
                const [countableVariantName, countableVariantValue] = key.split('_');

                if (countableVariantName !== variationName) {
                    return;
                }

                const isPartialSelectedVariationsEmpty =
                    Object.keys(partialSelectedVariantsAsObject).length === ZERO_LENGTH;

                // First variation list items
                if (isPartialSelectedVariationsEmpty && value === nextVariationCombinationsCount) {
                    variantValuesToBeDisabled.add(key);
                    this.disableVariantItem(variationName, countableVariantValue);
                } else if (!isPartialSelectedVariationsEmpty) {
                    // Rest of variation list items except for the last one
                    const clonedPartialSelectedVariants = { ...partialSelectedVariantsAsObject };
                    clonedPartialSelectedVariants[`${variationName}`] = countableVariantValue;

                    const partialSelectionEmptyStockExistenceCount = variantsWithEmptyStock.filter((variant) =>
                        isMatch(variant, clonedPartialSelectedVariants),
                    ).length;
                    if (partialSelectionEmptyStockExistenceCount === nextVariationCombinationsCount) {
                        variantValuesToBeDisabled.add(key);
                        this.disableVariantItem(variationName, countableVariantValue);
                    }
                }
            });

            partialSelectedVariantsAsObject[`${variationName}`] = variationValue;

            // Remove the current variation list items
            for (const key of variantsExistenceCount.keys()) {
                if (key.startsWith(`${variationName}_`)) {
                    variantsExistenceCount.delete(key);
                }
            }

            // Delete disabled variant values
            for (const variantValueToBeDisabled of variantValuesToBeDisabled) {
                variantsExistenceCount.delete(variantValueToBeDisabled);
            }

            // Remove the existence of the variation which is totally out of the stock in all combination
            if (variantValuesToBeDisabled.size !== ZERO_LENGTH) {
                variantsWithEmptyStock = variantsWithEmptyStock.filter((variant) => {
                    const variantValue = variant[variationName];

                    const variantIdentifier = `${variationName}_${variantValue}`;
                    return !variantValuesToBeDisabled.has(variantIdentifier);
                });
            }
        }

        // Disable the last variant group values which exist in the out of the stock variants
        const lastSelectedVariation = selectedVariations.pop();

        if (lastSelectedVariation == null) {
            throw new Error('The last selected variant is empty or undefined');
        }

        const lastVariationName = lastSelectedVariation.property;

        // Match the partial selected combination of variants with the out of the stock variants
        variantsWithEmptyStock = variantsWithEmptyStock.filter((variation) =>
            isMatch(variation, partialSelectedVariantsAsObject),
        );

        // Disable The out of the stock variant of last group that has partial match of the selected variant combination
        for (const variantWithEmptyStock of variantsWithEmptyStock) {
            this.disableVariantItem(lastVariationName, variantWithEmptyStock[lastVariationName]);
        }
    }

    private changeVariantItemsSelection(variantKey: string, variantValue: string, selection: boolean): void {
        const indexInVariantList = findIndex<IVariation>(this.variationList, (variant) => {
            return variant.key === variantKey;
        });

        const variantToUpdate = this.variationList[indexInVariantList];

        const updatedVariantData: ISpecification[] = [];

        for (const data of variantToUpdate.data) {
            if (data.key !== variantValue) {
                updatedVariantData.push(data);
                continue;
            }

            const updatedData: ISpecification = { ...data, selected: selection };
            updatedVariantData.push(updatedData);
        }

        variantToUpdate.data = updatedVariantData;
        this.variationList[indexInVariantList] = variantToUpdate;
    }

    private disableVariantItem(variantKey: string, variantValue: string): void {
        const indexInVariantList = findIndex<IVariation>(this.variationList, (variant) => {
            return variant.key === variantKey;
        });

        const variantToUpdate = this.variationList[indexInVariantList];

        const updatedVariantData: ISpecification[] = [];

        for (const data of variantToUpdate.data) {
            if (data.key !== variantValue) {
                const updatedData: ISpecification = { ...data };
                updatedVariantData.push(updatedData);
                continue;
            }

            const updatedData: ISpecification = { ...data, disabled: true };

            updatedVariantData.push(updatedData);
        }

        variantToUpdate.data = updatedVariantData;
        this.variationList[indexInVariantList] = variantToUpdate;
    }

    private emitVariationSelections(): void {
        this.selectVariation.emit(Object.fromEntries(this.currentSelectedVariationNameToVariation));
    }

    private replaceSelectedOutOfTheStockVariants(variationSelectionAsObject: Record<string, string>): void {
        this.unselectAllVariantItems();

        const variationsNames = Object.keys(variationSelectionAsObject);
        const variationSelectionAsObjectCloned = { ...variationSelectionAsObject };

        let variationConfigurationWithNonEmptyStock: IConfigStock | undefined;
        for (let index = variationsNames.length - 1; index > ZERO_LENGTH; index--) {
            const variationName = variationsNames[index];

            delete variationSelectionAsObjectCloned[variationName];

            variationConfigurationWithNonEmptyStock = this.dealStock.find(
                (deal) => deal.remainingStock > ZERO_LENGTH && isMatch(deal.config, variationSelectionAsObjectCloned),
            );

            if (variationConfigurationWithNonEmptyStock != null) {
                break;
            }
        }

        if (variationConfigurationWithNonEmptyStock == null) {
            variationConfigurationWithNonEmptyStock = this.dealStock.find((deal) => deal.remainingStock > ZERO_LENGTH);
        }

        if (variationConfigurationWithNonEmptyStock == null) {
            throw new Error("Can't find a replacer for selected out of the stock variations");
        }

        for (const key in variationConfigurationWithNonEmptyStock.config) {
            const variantValue = variationConfigurationWithNonEmptyStock.config[key];
            this.currentSelectedVariationNameToVariation.set(key, {
                property: key,
                value: variantValue,
            });
            this.changeVariantItemsSelection(key, variantValue, true);
        }

        this.emitVariationSelections();
    }

    private disableAllVariantItems(): void {
        for (let index = 0; index < this.variationList.length; index++) {
            const variantToDisable = this.variationList[index];

            const disabledVariantData: ISpecification[] = [];

            for (const data of variantToDisable.data) {
                const disabledData: ISpecification = { ...data, disabled: true };
                disabledVariantData.push(disabledData);
            }

            variantToDisable.data = disabledVariantData;
            this.variationList[index] = variantToDisable;
        }
    }

    private unselectAllVariantItems(): void {
        for (let index = 0; index < this.variationList.length; index++) {
            const variantToUnselect = this.variationList[index];

            const unselectedVariantData: ISpecification[] = [];

            for (const data of variantToUnselect.data) {
                const unselectedData: ISpecification = { ...data, selected: false };
                unselectedVariantData.push(unselectedData);
            }

            variantToUnselect.data = unselectedVariantData;
            this.variationList[index] = variantToUnselect;
        }
    }
}
