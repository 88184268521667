import type { SpecialDealDTO } from '@modeso/types__tsd-lib-products-be';
import { Purpose, VisualTypes } from '@modeso/types__tsd-lib-products-be';
import type { CampaignImageSlider } from '../../../../domain/models/campaign-image-slider.interface';
import isCorrectResolution from '../../../../domain/utils/visuals.utils';

const YOUTUBE_HOST_NAME_LINK = 'youtu.be';
const BACK_SLASH = '/';

function isValidUrl(str: string) {
    try {
        new URL(str);
        return true;
    } catch (_) {
        return false;
    }
}

function getYoutubeVideoIdFromUrl(url: string): string | undefined {
    if (!isValidUrl(url)) {
        return url;
    }
    const videoUrl = new URL(url);
    const videoId = videoUrl.searchParams.get('v');

    if (videoId != null) {
        return videoId;
    }

    if (videoUrl.hostname === YOUTUBE_HOST_NAME_LINK) {
        return videoUrl.pathname.replace(BACK_SLASH, '');
    }

    return undefined;
}

export function getCampaignImageSliderDateFromCampaign(campaign: SpecialDealDTO): CampaignImageSlider[] {
    // const campaignImageSlider: CampaignImageSlider[] = [];

    const { productData } = campaign;

    const PRODUCT_INDEX = 0;
    const product = productData[PRODUCT_INDEX];

    const campaignImageSlider = product.visuals
        .filter(
            (visual) =>
                (visual.purpose === Purpose.GALLERY && isCorrectResolution(visual)) ||
                (visual.purpose === Purpose.DETAILS && visual.type === VisualTypes.VIDEO),
        )
        .sort((firstElement, secondElement) => {
            const typeOrder: Record<string, number> = {
                [Purpose.GALLERY]: 1,
                [Purpose.DETAILS]: 2,
            };
            return typeOrder[firstElement.purpose] - typeOrder[secondElement.purpose];
        })
        .map((media) => {
            const slider: CampaignImageSlider = {
                type: media.type,
                mediaUrl: media.url,
            };

            if (media.type === VisualTypes.VIDEO) {
                let videoId: string | undefined;
                if (isValidUrl(media.url)) {
                    videoId = getYoutubeVideoIdFromUrl(media.url);
                } else {
                    videoId = media.url;
                }
                slider.videoId = videoId;
            }
            return slider;
        });

    return campaignImageSlider;
}
