import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { IPersonDto, ISubscriptionRequest } from '@modeso/types__tsd-lib-user-be';
import { environment } from 'projects/tsd-project-shop/src/environments/environment';
import type { Observable } from 'rxjs';
import type { IUserService } from '../../domain/outbound/user.service';

@Injectable()
export class UserService implements IUserService {
    private readonly USER_ENDPOINT_BASE_URL = environment.backendUrl + `users`;
    constructor(private readonly http: HttpClient) {}

    createOrUpdateUserProfile(body: IPersonDto): Observable<void> {
        const url = `${this.USER_ENDPOINT_BASE_URL}/updateOrCreate`;
        return this.http.post<void>(url, body);
    }

    deleteCurrentUserProfile(): Observable<string> {
        const url = `${this.USER_ENDPOINT_BASE_URL}/person`;
        return this.http.delete<string>(url, { responseType: 'text' as 'json' });
    }

    getCurrentUserProfile(): Observable<IPersonDto> {
        const url = `${this.USER_ENDPOINT_BASE_URL}/person`;
        return this.http.get<IPersonDto>(url);
    }

    updateNewsletterStatus(subscription: ISubscriptionRequest): Observable<void> {
        const url = `${this.USER_ENDPOINT_BASE_URL}/newsletter`;
        return this.http.post<void>(url, subscription);
    }
}
