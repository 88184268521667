import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';

@Component({
    selector: 'app-variations-manual-selection-warning',
    templateUrl: './variations-manual-selection-warning.component.html',
    styleUrls: ['./variations-manual-selection-warning.component.scss'],
})
export class VariationsManualSelectionWarningComponent {
    constructor(private readonly dialogRef: DialogRef<void, VariationsManualSelectionWarningComponent>) {}

    protected close(): void {
        this.dialogRef.close();
    }
}
