export const replaceSpacesWithDashes = (input: string | undefined): string => {
    if (!input) {
        return '';
    }

    if (!input.includes(' ')) {
        console.log("NoSpace");
        return input;
    }
    let trimmedInput = input.trim();
    let result = trimmedInput.replace(/\s+/g, '-');

    return result;
}

