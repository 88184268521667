/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable no-magic-numbers */
/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { Location } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import type { ICartOperationResponse } from '@modeso/types__tsd-lib-cart-be';
import { Purpose, VisualTypes } from '@modeso/types__tsd-lib-products-be';
import type { ICodeValidityCheckRequest } from '@modeso/types__twint-lib-coupons';
import { CalculateDiscountAmount, CouponEffectTypeEnum } from '@modeso/types__twint-lib-coupons';
import { Store } from '@ngrx/store';
import fastDeepEqual from 'fast-deep-equal/es6';
import { distinctUntilChanged, zip } from 'rxjs';
import { AnalyticsPageName } from '../../../../domain/models/enums/page-name.enum';
import type { SavePageViewRequest } from '../../../../domain/models/save-page-view-request.interface';
import { AnalyticsActions, ErrorActions, OrderActions } from '../../../../domain/store/actions/action-types';
import type { AppState } from '../../../../domain/store/reducers/app.reducer';
import { selectDetailsPlain, selectMessage, selectStatus } from '../../../../domain/store/reducers/error.reducer';
import {
    selectCouponFeatureDisplayStatus,
    selectCurrentPurchaseOrder,
    selectDiscount,
    selectPaymentToken,
} from '../../../../domain/store/reducers/order.reducer';
import { isApplyCouponLoading, isCheckOutOrderLoading } from '../../../../domain/store/reducers/pending.reducer';
import { getLanguage } from '../../../../domain/utils/language';
import { getFromSessionStorage } from '../../../../domain/utils/storage';
import { switchToApp } from '../../../../domain/utils/switchToApp';
import isCorrectResolution from '../../../../domain/utils/visuals.utils';
import type { ProductSummary } from '../../shared/campaign-order-basic-information/campaign-order-basic-information.component';
import type { OrderCosts } from '../../shared/campaign-order-total/campaign-order-total.component';
import { CouponLimitComponent } from '../../shared/coupon-limit/coupon-limit.component';
import { TsdInsufficientQuantityComponent } from '../../shared/tsd-insufficient-quantity/tsd-insufficient-quantity.component';

@Component({
    selector: 'app-campaign-order-summery',
    templateUrl: './campaign-order-summery.component.html',
    styleUrls: ['./campaign-order-summery.component.scss'],
})
export class CampaignOrderSummaryComponent implements OnInit {
    private readonly store = inject<Store<AppState>>(Store<AppState>);
    private readonly router = inject(Router);
    private readonly destroyRef = inject(DestroyRef);
    private readonly location = inject(Location);

    currentPurchaseOrder: ICartOperationResponse | undefined;
    couponCode: string = '';
    isValidCoupon = false;

    thankIsVisible = false;
    redirectURL = '';

    couponDisplayStatus$ = this.store.select(selectCouponFeatureDisplayStatus);
    isApplyCouponLoading$ = this.store.select(isApplyCouponLoading);
    isCheckOutOrderLoading$ = this.store.select(isCheckOutOrderLoading);

    items: ProductSummary[] = [];
    language = getLanguage();

    orderCosts: OrderCosts = {
        regularPrice: 0,
        subtotalAmount: 0,
        totalAmount: 0,
        totalVAT: 0,
        totalShipping: 0,
        vatShipping: 0,
        vatPercentage: 0,
        totalDiscount: 0,
        pctDiscount: 0,
    };

    isInvalidCouponError = false;
    isPriceBelowMinimumCouponError = false;

    deliveryInformation = '';

    constructor(private readonly dialog: MatDialog) {}

    couponApply(): void {
        if (!this.currentPurchaseOrder || !this.couponCode) return;

        const applyCouponReq: ICodeValidityCheckRequest = {
            code: this.couponCode.toLowerCase(),
            product: this.currentPurchaseOrder.campaignId,
            amount: this.orderCosts.subtotalAmount,
        };
        this.isValidCoupon = false;
        this.isInvalidCouponError = false;
        this.isPriceBelowMinimumCouponError = false;
        this.store.dispatch(ErrorActions.clearError());
        this.store.dispatch(OrderActions.applyCoupon(applyCouponReq));
    }

    orderNow(): void {
        if (!this.currentPurchaseOrder) return;
        this.store.dispatch(ErrorActions.clearError());
        this.dispatchPageView(AnalyticsPageName.ORDER_NOW_CLICKED, this.currentPurchaseOrder.campaignId);
        this.store.dispatch(
            OrderActions.checkOutOrder({
                orderUuid: this.currentPurchaseOrder.orderUuid ?? '',
                couponCode: this.isValidCoupon ? this.couponCode.toLowerCase() : undefined,
            }),
        );
    }

    navigateBack(): void {
        void this.router.navigate(['../']);
    }

    goBack(): void {
        this.location.back();
    }

    private loadCurrentPurchaseOrder(): void {
        this.store
            .select(selectCurrentPurchaseOrder)
            .pipe(
                distinctUntilChanged((previousPurchaseOrder, currentPurchaseOrder) =>
                    fastDeepEqual(previousPurchaseOrder, currentPurchaseOrder),
                ),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe({
                next: (currentPurchaseOrder) => {
                    if (!currentPurchaseOrder || !(currentPurchaseOrder?.orderUuid ?? '')) {
                        void this.router.navigateByUrl(`${this.language}/error`);
                        return;
                    }
                    this.currentPurchaseOrder = currentPurchaseOrder;

                    this.dispatchPageView(AnalyticsPageName.ORDER_SUMMARY, currentPurchaseOrder.campaignId);

                    currentPurchaseOrder?.orderText.forEach((ele) => {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
                        if (ele.purpose === Purpose.SHIPPING) {
                            this.deliveryInformation += ele[this.language] ?? '';
                        }
                    });

                    this.items = [];
                    for (const order of this.currentPurchaseOrder.orderLine) {
                        const imageInformation = order.product.visuals.find(
                            (visual) => visual.purpose === Purpose.THUMBNAIL && isCorrectResolution(visual),
                        );

                        if (imageInformation == null) {
                            throw new Error('Image is not found for an accessory');
                        }

                        if (
                            imageInformation.type !== VisualTypes.IMAGE &&
                            imageInformation.type !== VisualTypes.NO_BG_IMAGE
                        ) {
                            throw new Error('The image must either of type Image or type Image with no background');
                        }
                        const orderSummery: ProductSummary = {
                            productName: order.product.title[this.language] ?? '',
                            imageUrl: imageInformation.url,
                            imageType: imageInformation.type,
                            brandName: order.product.brand,
                            price: order.price * order.quantity,
                            quantity: order.quantity,
                            options: order.product.configurations.map(
                                (config) =>
                                    config.values.find((value) => value.key === order.config[config.property])?.[
                                        this.language
                                    ] ?? '',
                            ),
                        };

                        this.items.push(orderSummery);
                    }

                    currentPurchaseOrder?.orderCosts.forEach((item) => {
                        if (item.config['config'] === 'SUBTOTAL') {
                            this.orderCosts.regularPrice = item.price;
                            this.orderCosts.vatPercentage = item.vatInPct;
                        } else if (item.config['config'] === 'TOTAL') {
                            this.orderCosts.totalAmount = item.price;
                        } else if (item.config['config'] === 'VAT') {
                            this.orderCosts.totalVAT = item.price;
                        } else if (item.config['config'] === 'SHIPPING') {
                            this.orderCosts.totalShipping = item.price;
                            this.orderCosts.vatShipping = (item.vatInPct / 100.0) * item.price;
                        } else if (item.config['config'] === 'DISCOUNT') {
                            this.orderCosts.totalDiscount = item.price;
                        }
                    });

                    const subtotalAmount = this.orderCosts.regularPrice + this.orderCosts.totalDiscount;
                    const pctDiscount = Math.floor(
                        -1 * (100.0 - (subtotalAmount / this.orderCosts.regularPrice) * 100.0),
                    );

                    this.orderCosts = { ...this.orderCosts, subtotalAmount, pctDiscount };
                },
            });
    }

    private selectCouponDiscount(): void {
        this.store
            .select(selectDiscount)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (coupon) => {
                    if (!coupon || !coupon.valid) return;
                    this.isValidCoupon = true;
                    this.isInvalidCouponError = false;
                    this.isPriceBelowMinimumCouponError = false;

                    const instance = CalculateDiscountAmount.getInstanceOfDiscountAmount();
                    const { discountAmount } = instance.calculateDiscountAmount(
                        coupon.effectType,
                        this.orderCosts.subtotalAmount,
                        coupon.effectValue,
                    );

                    const totalAmount = this.orderCosts.totalAmount - discountAmount;

                    this.orderCosts = {
                        ...this.orderCosts,
                        couponDiscount: discountAmount,
                        discountPercentage:
                            coupon.effectType === CouponEffectTypeEnum.Percentage ? coupon.effectValue : undefined,
                        totalAmount,
                    };
                },
            });
    }

    protected onCouponFieldChange(): void {
        this.isValidCoupon = false;
        if (this.orderCosts.couponDiscount == null) {
            return;
        }
        // if Coupon is applied and user changed the Coupon field, remove the discount
        this.orderCosts = {
            ...this.orderCosts,
            couponDiscount: 0,
            discountPercentage: undefined,
            totalAmount: this.orderCosts.totalAmount + this.orderCosts.couponDiscount,
        };
    }

    private listenTOPaymentToken(): void {
        this.store
            .select(selectPaymentToken)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (token) => {
                    if (!token) return;
                    this.thankIsVisible = true;
                    this.redirectURL = switchToApp(token ?? '');
                    window.location.href = this.redirectURL;
                },
            });
    }

    private listenToErrorMessage(): void {
        zip([this.store.select(selectDetailsPlain), this.store.select(selectStatus), this.store.select(selectMessage)])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([errorDetails, errorStatus, errorMessage]) => {
                    this.isInvalidCouponError = false;
                    this.isPriceBelowMinimumCouponError = false;
                    this.dialog.closeAll();
                    if (errorDetails && errorStatus === 409) {
                        const errorObj = JSON.parse(errorDetails);
                        let insufficientAccessoryName = '';
                        if (errorObj?.accessoryId) {
                            insufficientAccessoryName =
                                this.currentPurchaseOrder?.orderLine.find(
                                    (order) => order.product.product === errorObj.accessoryId,
                                )?.product.title[this.language] ?? '';
                        }
                        this.dialog.open(TsdInsufficientQuantityComponent, {
                            data: {
                                availableStock: errorObj?.availableStock ?? 0,
                                isProductOutOfStock: errorObj?.isProductOutOfStock ?? false,
                                insufficientAccessoryName,
                                onDismiss: () => {
                                    this.navigateBack();
                                },
                            },
                        });
                    } else if (errorStatus === 423) {
                        this.couponCode = '';

                        this.dialog.open(CouponLimitComponent);
                    } else if (errorStatus && errorMessage === 'tsd_coupon_invalid_msg') {
                        this.couponCode = '';
                        this.isValidCoupon = false;
                        this.isInvalidCouponError = true;
                        this.isPriceBelowMinimumCouponError = false;
                    } else if (errorStatus && errorMessage === 'tsd_coupon_price_below_minimum_msg') {
                        this.couponCode = '';
                        this.isValidCoupon = false;
                        this.isInvalidCouponError = false;
                        this.isPriceBelowMinimumCouponError = true;
                    }
                },
            });
    }

    ngOnInit(): void {
        this.store.dispatch(OrderActions.loadCouponFeature());
        this.loadCurrentPurchaseOrder();
        this.selectCouponDiscount();
        this.listenTOPaymentToken();
        this.listenToErrorMessage();
    }

    private dispatchPageView(pageName: string, campaignId: string): void {
        const pageViewRequest: SavePageViewRequest = {
            productLine: campaignId,
            pageName,
            terminalId: getFromSessionStorage('TERMINAL_ID') ?? '',
        };
        this.store.dispatch(AnalyticsActions.savePageView({ payload: pageViewRequest }));
    }
}
