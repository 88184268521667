<div class="image-slider-container" (swipeRight)="goToPrevious()" (swipeLeft)="goToNext()">
    <div *ngIf="pillText" class="image-pill">{{ pillText }}</div>
    <!-- #region left and right arrows -->
    <div class="arrow" *ngIf="campaignImageSlider.length">
        <div class="action leftArrow">
            <img (click)="goToPrevious()" src="assets/chevron-right.svg" alt="left arrow" />
        </div>
        <div class="action rightArrow">
            <img (click)="goToNext()" src="assets/chevron-right.svg" alt="right arrow" />
        </div>
    </div>
    <!-- #endregion -->
    <!-- #region slides -->
    <div class="slide">
        <ng-container *ngFor="let slider of campaignImageSlider; let i = index">
            <img
                class="slider-image"
                [src]="slider.mediaUrl"
                alt="slide"
                [ngClass]="{
                    active: currentIndex === i,
                    'slider-image-with-no-background': slider.type === visualTypes.NO_BG_IMAGE
                }"
                *ngIf="slider.type === visualTypes.IMAGE || slider.type === visualTypes.NO_BG_IMAGE"
            />
            <app-youtube-player
                [ngClass]="{ active: currentIndex === i }"
                [videoId]="slider.videoId"
                *ngIf="slider.type === visualTypes.VIDEO && slider.videoId != null"
            />
        </ng-container>
    </div>
    <!-- #endregion -->
</div>
