export const SWISS_CURRENCY = 'CHF';
export const PERCENTAGE = '%';
export const ZERO_LENGTH = 0;
export const TERMINAL_ID = 'terminalId';
export const STRING_TRUE = 'true';
export const STRING_FALSE = 'false';
export const BLUE = 'BLUE';
export const MAIN_PRODUCT_INDEX = 0;

export const LOCAL_STORAGE_KEYS = {
    CURRENT_LANGUAGE: 'currentLanguage',
    DEBUG: 'debug',
    EMAIL: 'email',
    TWINT_TC: 'twintTC',
    CHECKIN_DATE: 'checkInDate',
    PROTOCOL: 'protocol',
    DEVICE: 'device',
};

export const SESSION_STORAGE_KEYS = {
    CURRENT_PURCHASE_ORDER: 'currentPurchaseOrder',
    TERMINAL_ID: 'terminalId',
    IS_P2P: 'isP2P',
    IS_NEWS_TICKER_CLOSED: 'IsNewsTickerClosed',
};
