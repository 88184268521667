<app-loading-spinner *ngIf="isDiscountOffersLoading$ | async; else data" />

<ng-template #data>
    <!-- page header -->
    <app-page-header (backButtonClick)="goBack()" title="{{ 'tsd_shop_affiliate_deals' | localize | async }}" />

    <div class="discount-offers-container">
        <!-- description -->
        <div
            class="discount-offers-description"
            [innerHTML]="'tsd_shop_affiliate_deals_description' | localize | async | markdownToSafeHTML"
        ></div>

        <!-- offers list -->
        <app-discount-offer-image-grid [discountOffers]="discountOffersImageSlider" />
    </div>
</ng-template>
