<div class="wrapper" mat-dialog-content>
    <div class="popup">
        <div class="icon-wrapper">
            <span class="icon-unavailable"></span>
        </div>
        <div class="popup-content">
            <span class="popup-title">
                {{ replaceText('tsd_age_check_info_txt' | localize | async) }}
            </span>
            <span class="popup-text">
                {{ replaceText('tsd_age_check_verify_txt' | localize | async) }}
            </span>
        </div>
        <button class="popup-button yes" (click)="dismiss(true)">
            {{ 'tsd_age_check_continue_btn' | localize | async }}
        </button>
        <button class="popup-button no" (click)="dismiss()">
            {{ 'tsd_age_check_exit_btn' | localize | async }}
        </button>
    </div>
</div>
