<!-- page header -->
<app-page-header
    [title]="'tsd_my_account_contact' | localize | async"
    (backButtonClick)="onBackClicked()"
></app-page-header>

<!-- merchant contacts -->
<mat-card class="card">
    <mat-card-header>
        <mat-card-title> {{ 'tsd_contact_deals_header' | localize | async }} </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p>{{ 'tsd_contact_deals_description' | localize | async }}</p>
        <p class="distributors">{{ 'tsd_contact_distributors_message' | localize | async }}</p>
        <div *ngFor="let merchant of merchants$ | async">
            <p>
                {{ merchant.name }}: <a href="{{ merchant.contactLink }}" target="_blank">{{ merchant.contactLink }}</a>
            </p>
        </div>
    </mat-card-content>
</mat-card>

<mat-card class="card">
    <mat-card-header>
        <mat-card-title> {{ 'tsd_contact_order_header' | localize | async }} </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p>{{ 'tsd_contact_order_description' | localize | async }}</p>
        <p class="distributors">{{ 'tsd_contact_distributors_message' | localize | async }}</p>
        <div *ngFor="let merchant of merchants$ | async">
            <p>
                {{ merchant.name }}: <a href="{{ merchant.contactLink }}" target="_blank">{{ merchant.contactLink }}</a>
            </p>
        </div>
    </mat-card-content>
</mat-card>
