<div class="counter-container">
    <div class="counter-row">
        <div class="quantity" *ngIf="!fromAccessoryPage">
            <button
                class="quantity_btn_left"
                (click)="decrease()"
                [disabled]="outputData.quantity === MINIMUM_COUNTER_VALUE"
            >
                -
            </button>
            <span class="quantity_value no-zoom">{{ isOutOfStock ? 0 : outputData.quantity }}</span>
            <button
                class="quantity_btn_right"
                (click)="increase()"
                [disabled]="
                    isOutOfStock ||
                    (maxValueAvailable != null && outputData.quantity >= maxValueAvailable) ||
                    outputData.quantity >= originalData.maximumQuantity ||
                    data?.discountedPrice === 0 ||
                    data?.originalPrice === 0
                "
            >
                +
            </button>
        </div>
        <div class="price-box" *ngIf="outputData">
            <span class="price bold">{{ currency }} {{ outputData.discountedPrice | currencyNumber }}</span>
            <span class="price strike-through" *ngIf="outputData.originalPrice !== 0"
                >{{ currency }} {{ outputData.originalPrice | currencyNumber }}</span
            >
        </div>
    </div>
    <div class="button-row">
        <button (click)="onBuyNowAction()" [disabled]="disabled">{{ label }}</button>
    </div>
</div>
