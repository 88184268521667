import {
    Component,
    EventEmitter,
    HostListener,
    Input,
    Output,
    Renderer2,
    ViewChild,
    type AfterViewInit,
    type ElementRef,
} from '@angular/core';

const DECREASE_STEP = 0.1;
@Component({
    selector: 'app-discount-offer-button',
    templateUrl: './discount-offer-button.component.html',
    styleUrls: ['./discount-offer-button.component.scss'],
})
export class DiscountOfferButtonComponent implements AfterViewInit {
    @Input({ required: true }) promoCode!: string;
    @Output() visitShop = new EventEmitter();
    showToast: boolean = false;
    @ViewChild('promoCodeElement') promoCodeElement: ElementRef | undefined;

    constructor(private readonly renderer: Renderer2) {}

    ngAfterViewInit(): void {
        this.resizeText();
    }

    private resizeText(): void {
        if (!this.promoCodeElement) return;
        const currentElement = this.promoCodeElement.nativeElement as HTMLElement;
        const parentElement = currentElement.parentElement as HTMLElement;
        const grandparentElement = parentElement.parentElement as HTMLElement;

        // 144 = (width of the icon + label) & 7 = (flex gap)
        const maxWidth = grandparentElement.offsetWidth - 144 - 7 * 2;

        let fontSize = parseFloat(window.getComputedStyle(currentElement).fontSize);
        while (parentElement.offsetWidth > maxWidth && fontSize > DECREASE_STEP) {
            // While the current width is greater than the max width, decrease the font size
            fontSize = parseFloat(window.getComputedStyle(currentElement).fontSize) - DECREASE_STEP;
            this.renderer.setStyle(this.promoCodeElement.nativeElement, 'font-size', `${fontSize}px`);
        }
    }

    @HostListener('window:resize')
    onResize(): void {
        this.resizeText();
    }

    onVisitShop(): void {
        this.visitShop.emit();
    }

    async copyCode(): Promise<void> {
        if (!this.promoCode) return;
        await navigator.clipboard.writeText(this.promoCode);
        this.showToast = true;
        setTimeout(() => (this.showToast = false), 2000);
    }
}
