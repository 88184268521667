<div class="custom-select-container" [class.focused]="isFocused || isOpen">
    <label class="custom-select-label"> {{ label }}<span *ngIf="isRequired">*</span> </label>
    <div class="selected-value" (click)="toggleDropdown()" (focus)="onFocus()" (blur)="onBlur()" tabindex="0">
        <span>
            {{ selectedValue.name ? (selectedValue.name | localize | async) : '' }}
        </span>
        <div class="arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="8" viewBox="0 0 14 8" fill="none">
                <path
                    d="M12.5 1.25L7 6.75L1.5 1.25"
                    stroke="#262626"
                    stroke-width="1.3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </div>
    </div>
    <div class="options-container" *ngIf="isOpen">
        <div *ngFor="let option of options" (click)="selectOption(option)" [ngClass]="{ selected: isSelected(option) }">
            <span class="option-text">{{ option.name | localize | async }}</span>
            <span class="checkmark" *ngIf="isSelected(option)">✔</span>
        </div>
    </div>
</div>
