/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-magic-numbers */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import type { OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import type { ICartOperationResponse, ICreateOrUpdateCartRequest } from '@modeso/types__tsd-lib-cart-be';
import type {
    IAccordionCategoryResponse,
    IConfigPrice,
    IConfigStock,
    IDynamicConfig,
    IPropertyValue,
    IVisuals,
    SpecialDealDTO,
} from '@modeso/types__tsd-lib-products-be';
import { Purpose, VisualTypes } from '@modeso/types__tsd-lib-products-be';
import { Store } from '@ngrx/store';
import fastDeepEqual from 'fast-deep-equal/es6';
import { combineLatest, distinctUntilChanged, filter, take } from 'rxjs';
import { MAIN_PRODUCT_INDEX, ZERO_LENGTH, ZERO_PRICE } from '../../../../domain/constants/constants';
import type { CampaignButton } from '../../../../domain/models/campaign-button.interface';
import type { CampaignImageSlider } from '../../../../domain/models/campaign-image-slider.interface';
import { HttpStatusErrorCodes } from '../../../../domain/models/enums/http-status-error-codes.enum';
import { AnalyticsPageName } from '../../../../domain/models/enums/page-name.enum';
import { StockAvailabilityMode } from '../../../../domain/models/enums/stock-availability-mode.enum';
import { VariationType } from '../../../../domain/models/enums/variation-type.enum';
import type { SavePageViewRequest } from '../../../../domain/models/save-page-view-request.interface';
import type { VariationValue } from '../../../../domain/models/types/variation-value.type';
import type { VariationConfiguration } from '../../../../domain/models/variation-configuration.interface';
import { OrderPendingEnum } from '../../../../domain/store/actions/action-names.enum';
import {
    AnalyticsActions,
    CampaignActions,
    ErrorActions,
    OrderActions,
    SuccessActions,
} from '../../../../domain/store/actions/action-types';
import type { AppState } from '../../../../domain/store/reducers/app.reducer';
import { selectCampaignByInternalId } from '../../../../domain/store/reducers/campaign.reducer';
import { selectStatus } from '../../../../domain/store/reducers/error.reducer';
import { selectCurrentPurchaseOrder } from '../../../../domain/store/reducers/order.reducer';
import { isCreateOrderLoading } from '../../../../domain/store/reducers/pending.reducer';
import { selectSuccessKey } from '../../../../domain/store/reducers/success.reducer';
import { getLanguage } from '../../../../domain/utils/language';
import { SpecialDealUtils } from '../../../../domain/utils/specialdeals.utils';
import { getFromSessionStorage } from '../../../../domain/utils/storage';
import { replaceSpacesWithDashes } from '../../../../domain/utils/url-with-name.utils';
import isCorrectResolution from '../../../../domain/utils/visuals.utils';
import type { CampaignBasicInfo } from '../../pure/campaign-information/campaign-information.component';
import { VariationsManualSelectionWarningComponent } from '../../pure/variations-manual-selection-warning/variations-manual-selection-warning.component';
import type { ITsdVariationSelection } from '../../shared/campaign-variation-list/campaign-variation-list.component';
import type { IAccordionData } from '../../shared/information-accordion/information-accordion.component';
import { TsdCampaignLimitExceededPopupComponent } from '../../shared/tsd-campaign-limit-exceeded-popup/tsd-campaign-limit-exceeded-popup';
import { TsdReservationPopupComponent } from '../../shared/tsd-reservation-popup/tsd-reservation-popup.component';
import { TsdUserAgeCheckComponent } from '../../shared/tsd-user-age-check/tsd-user-age-check.component';
import { getCampaignImageSliderDateFromCampaign } from './campaign-detail.util';

const HIGH = 80;
const LOW = 30;
const EMPTY_STOCK = 0;
const TOP_PAGE_INDEX = 0;
const SCROLL_UP_TIME = 200;

@Component({
    selector: 'app-campaign-detail-page',
    templateUrl: './campaign-detail-page.component.html',
    styleUrls: ['./campaign-detail-page.component.scss'],
})
export class CampaignDetailPageComponent implements OnInit {
    private readonly store = inject<Store<AppState>>(Store<AppState>);
    private readonly router = inject(Router);
    private readonly activeRoute = inject(ActivatedRoute);
    private readonly destroyRef = inject(DestroyRef);
    private readonly cd = inject(ChangeDetectorRef);
    protected manualVariationSelection = false;
    private campaign: SpecialDealDTO | undefined;
    private readonly language = getLanguage();

    accordionData: IAccordionData[] = [];
    isCreateOrderLoading$ = this.store.select(isCreateOrderLoading);

    stockAvailabilityMode = StockAvailabilityMode;
    campaignImageSlider: CampaignImageSlider[] = [];
    imagePillText: string = '';

    basicInfo!: CampaignBasicInfo;

    buttonData: CampaignButton | undefined;

    variationConfigurations: VariationConfiguration[] = [];
    dealStock: IConfigStock[] = [];

    selectedConfig!: IDynamicConfig;

    currentPurchaseOrder: ICartOperationResponse | undefined;

    isOutOfStock = false;

    protected displayLoadingSpinner = true;

    constructor(public dialog: MatDialog) {}

    private createOrder(data: CampaignButton): void {
        const terminalId = getFromSessionStorage<'string'>('TERMINAL_ID')?.toString();
        if (!this.campaign || !data.quantity || !terminalId || this.isOutOfStock) return;

        const payload: ICreateOrUpdateCartRequest = {
            orderUuid: this.currentPurchaseOrder?.orderUuid ?? undefined,
            campaignId: this.campaign.dealData?.campaignId ?? '',
            orderLine: [
                {
                    product: this.campaign.dealData.productId,
                    quantity: data?.quantity,
                    config: this.selectedConfig,
                },
            ],
            terminalId: this.currentPurchaseOrder?.terminalId ?? terminalId,
            language: this.language,
            isAgeCheckConfirmed: true,
        };

        this.store.dispatch(OrderActions.createOrder(payload));
    }

    buyBow(data: CampaignButton): void {
        const minUserAge = this.campaign?.productData[0].minUserAge;
        if (minUserAge) {
            this.dialog.open(TsdUserAgeCheckComponent, {
                data: {
                    minUserAge,
                    onDismiss: (isConfirmed?: boolean) => {
                        if (isConfirmed) {
                            this.createOrder(data);
                        } else {
                            void this.goBack();
                        }
                    },
                },
            });
        } else {
            this.createOrder(data);
        }
    }

    goBack(): Promise<boolean> {
        return this.router.navigate(['../']);
    }

    selectVariation(variant: Record<string, ITsdVariationSelection>): void {
        const selectedVariations = {} as IDynamicConfig;
        Object.values(variant).forEach((val: ITsdVariationSelection) => {
            selectedVariations[val.property] = val.value;
        });

        this.selectedConfig = { ...selectedVariations };
        this.isOutOfStock = false;
        this.selectPriceForConfiguration(selectedVariations);
    }

    private getStockProgressAvailabilityMode(percentage: number): StockAvailabilityMode {
        return (
            percentage >= HIGH ? StockAvailabilityMode.HIGH
            : percentage < LOW ? StockAvailabilityMode.LOW
            : StockAvailabilityMode.MEDIUM
        );
    }

    private selectPriceForConfiguration(config: IDynamicConfig): void {
        if (config === undefined) {
            config = {
                config: 'none',
            };
        }

        let prices = this.campaign?.productData[0].prices ?? [];
        Object.keys(config).forEach((property) => {
            prices = prices.filter((price: IConfigPrice) => {
                return price.config[property] === config[property];
            });
        });

        let dealPrices = this.campaign?.dealData.dealPrices ?? [];
        Object.keys(config).forEach((property) => {
            dealPrices = dealPrices.filter((price: IConfigPrice) => {
                return price.config[property] === config[property];
            });
        });

        if (this.buttonData == null) {
            this.buttonData = {
                originalPrice: 0,
                discountedPrice: 0,
                quantity: 1,
                maximumQuantity: Number.MAX_SAFE_INTEGER,
            };
        }

        if (prices.length > ZERO_LENGTH && this.campaign != null) {
            this.buttonData = {
                ...this.buttonData,
                originalPrice: prices[0].price,
                discountedPrice: dealPrices[0].price,
                maximumQuantity: this.campaign.productData[MAIN_PRODUCT_INDEX].quantityLimit ?? Number.MAX_SAFE_INTEGER,
            };
        } else {
            this.buttonData = {
                originalPrice: 0,
                discountedPrice: 0,
                quantity: 0,
                maximumQuantity: 0,
            };
        }
        this.cd.detectChanges();
    }

    private navigateToNextPage(): void {
        this.store.dispatch(SuccessActions.clearSuccessKey());
        const formattedName = replaceSpacesWithDashes(this.campaign?.name);
        if (this.campaign?.dealData.productAccessoryData.length) {
            void this.router.navigate([
                this.language,
                'campaign',
                formattedName,
                this.campaign?.internalCampaignId,
                'accessories',
            ]);
        } else {
            void this.router.navigate([
                this.language,
                'campaign',
                formattedName,
                this.campaign?.internalCampaignId,
                'shipping',
            ]);
        }
    }

    private listenToSuccessKeyAndOrderPurchase(): void {
        combineLatest([this.store.select(selectSuccessKey), this.store.select(selectCurrentPurchaseOrder)])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: ([successKey, currentPurchaseOrder]) => {
                    if (!currentPurchaseOrder) return;
                    this.currentPurchaseOrder = currentPurchaseOrder;

                    // if success key and current purchase order ===> will redirect to next page
                    //  if warning will show popup
                    //  else this means that coming from accessory or shipping page

                    const isCreatedOrderSuccess = successKey === OrderPendingEnum.CREATE_ORDER;
                    this.dialog.closeAll();
                    if (isCreatedOrderSuccess && currentPurchaseOrder?.warning) {
                        this.dialog.open(TsdReservationPopupComponent, {
                            data: {
                                onDismiss: () => {
                                    this.navigateToNextPage();
                                    return;
                                },
                            },
                        });
                    } else if (isCreatedOrderSuccess) {
                        this.navigateToNextPage();
                        return;
                    }

                    const salesOrderLine = currentPurchaseOrder?.orderLine[0];

                    if (this.buttonData != null) {
                        this.buttonData = {
                            ...this.buttonData,
                            quantity: salesOrderLine.quantity,
                        };
                    } else {
                        this.buttonData = {
                            originalPrice: 0,
                            discountedPrice: 0,
                            quantity: salesOrderLine.quantity,
                            maximumQuantity: Number.MAX_SAFE_INTEGER,
                        };
                    }

                    this.selectedConfig = { ...salesOrderLine.config };
                },
            });
    }

    private loadCampaign(id: string): void {
        this.store
            .select(selectCampaignByInternalId(id))
            .pipe(
                filter((campaign) => campaign != null),
                distinctUntilChanged((previousCampaign, currentCampaign) =>
                    fastDeepEqual(previousCampaign, currentCampaign),
                ),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe({
                next: (campaign) => {
                    if (campaign == null) return;
                    this.manualVariationSelection = campaign.manualVariationSelection;
                    const { productData, endDateTime, dealData } = campaign;
                    this.campaign = campaign;
                    this.dealStock = dealData.dealStock ?? [];

                    const remainingStock = SpecialDealUtils.calculateRemainingStock(campaign);
                    this.isOutOfStock = remainingStock === EMPTY_STOCK;

                    const PRODUCT_INDEX = 0;
                    const product = productData[PRODUCT_INDEX];

                    this.dispatchPageView(campaign.internalCampaignId);
                    // #region slider images
                    this.campaignImageSlider = getCampaignImageSliderDateFromCampaign(campaign);

                    if (campaign.countDownPillConfiguration && campaign.countDownPillConfiguration.moreThan2Days) {
                        this.imagePillText = campaign.countDownPillConfiguration.moreThan2Days[this.language] ?? '';
                    }
                    // #endregion

                    // #region basic info data
                    let description = '';
                    product.text
                        .filter((ele) => ele.purpose === Purpose.DETAILS)
                        .forEach((ele) => (description += `${ele.text}</br>`));

                    const stockAvailabilityPercentage = SpecialDealUtils.calculateRemainingStock(campaign);
                    const { dealPriceWithStock, config } = SpecialDealUtils.findDealPrice(campaign);
                    const originalPrice = SpecialDealUtils.findOriginalPrice(campaign, config);
                    let discountValueText: string | undefined;

                    if (dealPriceWithStock < originalPrice || dealPriceWithStock === ZERO_PRICE) {
                        discountValueText = SpecialDealUtils.getDiscountToDisplay(campaign);
                    }

                    this.basicInfo = {
                        brandName: product.brand ?? '',
                        productName: product.title,
                        description,
                        merchantLogo:
                            product.merchant.logo?.find(
                                (ele) => ele.purpose === Purpose.THUMBNAIL && isCorrectResolution(ele),
                            )?.url ?? '',
                        endDate: new Date(endDateTime),
                        discount: discountValueText,
                        stockAvailabilityPercentage,
                        stockProgressAvailabilityMode:
                            this.getStockProgressAvailabilityMode(stockAvailabilityPercentage),
                        hideAvailabilityBar: campaign.hideAvailabilityBar,
                    };
                    // #endregion

                    // #region accordion information
                    this.accordionData = [];
                    if (product.accordionData) {
                        this.accordionData =
                            product.accordionData
                                .filter((item) => {
                                    return Object.values(item.content).every(
                                        (content) => content !== null && content !== '',
                                    );
                                })
                                .map((item) => {
                                    let title =
                                        (item.categoryID as IAccordionCategoryResponse).title[this.language] ?? '';
                                    if ((item.categoryID as IAccordionCategoryResponse).isBrandAppended) {
                                        title = `${title} ${product.brand}`;
                                    }
                                    return {
                                        // Map the accordion data to the desired format
                                        title: title,
                                        details: item.content[this.language] ?? '',
                                        imageURL: (item.categoryID as IAccordionCategoryResponse).imageURL,
                                    };
                                }) ?? [];
                    }

                    const specificationsAccordionData: IAccordionData[] = product.specifications
                        .filter((ele) => ele.data.length > 0)
                        .map((ele) => {
                            let details = '';
                            ele.data.forEach((d) => {
                                details += `<div class='group-kvp'>
                        <span class="kvp-key">${d.key}</span>
                        <span class="kvp-value">${d.value}</span>
                    </div>`;
                            });

                            return {
                                title: ele.title,
                                details,
                            };
                        });

                    // add pdf files
                    if (product.pdfList?.length) {
                        specificationsAccordionData.push({
                            pdfList: product?.pdfList,
                            title: 'tsd_downloads',
                            details: '',
                        });
                    }

                    this.accordionData.push(...specificationsAccordionData);
                    // #endregion

                    // #region variation list
                    if (product.configurations.length) {
                        this.variationConfigurations = [];
                        product.configurations.forEach((config) => {
                            if (config.property === 'color') {
                                this.variationConfigurations.push({ ...config, type: VariationType.COLOR });
                            } else if (config.property === 'image') {
                                const configurationValues = this.addImageTypeToConfigurationValuesBasedOnVisuals(
                                    product.visuals,
                                    config.values,
                                );
                                this.variationConfigurations.push({
                                    ...config,
                                    type: VariationType.IMAGE,
                                    values: configurationValues,
                                });
                            } else {
                                this.variationConfigurations.push({ ...config, type: VariationType.DEFAULT });
                            }
                        });
                        if (this.selectedConfig == null && this.isOutOfStock) {
                            this.selectPriceForConfiguration(dealData.dealStock[MAIN_PRODUCT_INDEX].config);
                        }
                    } else {
                        const stock = dealData.dealStock[0];
                        this.selectedConfig = { ...stock.config };
                        this.selectPriceForConfiguration(stock.config);
                    }
                    // #endregion
                },
            });
    }

    private addImageTypeToConfigurationValuesBasedOnVisuals(
        visuals: IVisuals[],
        configurationValues: IPropertyValue[],
    ): VariationValue[] {
        const configurationValuesWithImageType: VariationValue[] = [];
        for (const configurationValue of configurationValues) {
            // New feature so no legacy resolution handling. Only 1 fixed resolution
            const imageInformation = visuals.find((visual) => visual.url === configurationValue.key);

            if (imageInformation == null) {
                throw new Error(
                    'The image in the configuration value of the variation is not exist in the visuals of the product',
                );
            }

            if (imageInformation.type !== VisualTypes.IMAGE && imageInformation.type !== VisualTypes.NO_BG_IMAGE) {
                throw new Error('The image must either of type Image or type Image with no background');
            }

            const configurationValueWithImageType: VariationValue = {
                ...configurationValue,
                imageType: imageInformation.type,
            };

            configurationValuesWithImageType.push(configurationValueWithImageType);
        }

        return configurationValuesWithImageType;
    }

    ngOnInit(): void {
        setTimeout(() => {
            window.scrollTo({ top: TOP_PAGE_INDEX });
            this.displayLoadingSpinner = false;
        }, SCROLL_UP_TIME);

        const id = this.activeRoute.snapshot.params['id'] as string;
        if (!id) return;
        this.store.dispatch(CampaignActions.loadCampaignById(id));

        this.listenToSuccessKeyAndOrderPurchase();
        this.loadCampaign(id);
        this.listenToErrorMessage();
    }

    private listenToErrorMessage(): void {
        this.store
            .select(selectStatus)
            .pipe(
                filter((status) => status === HttpStatusErrorCodes.CAMPAIGN_TRANSACTIONS_LIMIT_REACHED),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe({
                next: () => {
                    this.dialog.closeAll();
                    this.dialog
                        .open(TsdCampaignLimitExceededPopupComponent)
                        .afterClosed()
                        .pipe(take(1))
                        .subscribe({
                            next: () => {
                                this.store.dispatch(ErrorActions.clearError());
                            },
                        });
                },
            });
    }

    private dispatchPageView(productName: string): void {
        const pageViewRequest: SavePageViewRequest = {
            productLine: productName,
            pageName: AnalyticsPageName.CAMPAIGN_DETAILS,
            terminalId: getFromSessionStorage('TERMINAL_ID') ?? '',
        };
        this.store.dispatch(AnalyticsActions.savePageView({ payload: pageViewRequest }));
    }

    protected onInactiveButtonClicked(): void {
        this.dialog.open<VariationsManualSelectionWarningComponent, void, void>(
            VariationsManualSelectionWarningComponent,
        );
    }
}
