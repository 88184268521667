<mat-card>
    <mat-card-content class="container-fluid" [class.out-of-the-stock]="isOutOfStock">
        <div
            *ngIf="
                imagePill != null &&
                ((imagePill.text != null && imagePill.text.length > 0) || imagePill.counterInSeconds != null)
            "
            class="image-pill"
        >
            <div *ngIf="imagePill.type === imagePillType.TEXT">{{ imagePill.text }}</div>
            <div *ngIf="imagePill.type === imagePillType.COUNT_DOWN">
                <countdown [config]="{ leftTime: imagePill.counterInSeconds }"></countdown>
            </div>
        </div>
        <div class="row mx-0 px-0">
            <div class="col-12 mx-0 px-0" [ngClass]="{ 'image-wrapper': imageType === imageTypes.NO_BG_IMAGE }">
                <img
                    class="campaign-item-image"
                    [ngClass]="{ 'campaign-item-image-with-no-background': imageType === imageTypes.NO_BG_IMAGE }"
                    fetchpriority="high"
                    src="{{ imageUrl }}"
                    alt="campaign-image"
                />
            </div>
        </div>
        <div class="row mx-0 px-0">
            <div class="col-12 mx-0 px-0">
                <div class="row mx-0 px-0" *ngIf="!hideAvailabilityBar">
                    <div class="col-12 mx-0 px-0">
                        <div class="stock-availability-percentage-value">
                            {{ stockAvailabilityPercentageValue }}% {{ 'tsd_stock_remaining' | localize | async }}
                        </div>
                        <mat-progress-bar
                            class="stock-availability-progress"
                            [value]="stockAvailabilityPercentageValue"
                            [ngClass]="{
                                'low-stock-availability': stockProgressAvailabilityMode === stockAvailabilityMode.LOW,
                                'medium-stock-availability':
                                    stockProgressAvailabilityMode === stockAvailabilityMode.MEDIUM,
                                'high-stock-availability': stockProgressAvailabilityMode === stockAvailabilityMode.HIGH,
                            }"
                        >
                        </mat-progress-bar>
                    </div>
                </div>
                <div class="campaign-information">
                    <div class="campaign-name-value">
                        <div class="campaign-name">{{ campaignName }}</div>
                        <div
                            class="discount-pill"
                            *ngIf="priceAfterDiscount < originalPrice || priceAfterDiscount === 0"
                        >
                            {{ discountValueText === 'tsd_free' ? ('tsd_free' | localize | async) : discountValueText }}
                        </div>
                    </div>
                    <div class="campaign-discount">
                        <div class="campaign-product-name">{{ productName }}</div>
                        <div class="campaign-price">
                            <span class="discount-price">{{ currency }} {{ priceAfterDiscount | currencyNumber }}</span>
                            <span
                                class="original-price"
                                *ngIf="originalPrice !== 0 && priceAfterDiscount < originalPrice"
                                >{{ currency }} {{ originalPrice | currencyNumber }}</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-card-content>
</mat-card>
