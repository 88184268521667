<div class="order-total" [style.paddingBottom]="fromOrderDetails ? '52px' : '0'">
    <div class="row1">
        <div class="col1">{{ 'tsd_subtotal' | localize | async }}</div>
        <div class="col2">{{ CURRENCY }} {{ orderCosts.regularPrice | currencyNumber }}</div>
    </div>
    <div *ngIf="orderCosts.couponDiscount">
        <span class="col1">
            <span>{{ 'tsd_coupon_title' | localize | async }}</span>
            <span *ngIf="orderCosts.discountPercentage"> (-{{ orderCosts.discountPercentage.toFixed(0) }}%)</span>
        </span>
        <span class="col2"> {{ CURRENCY }} -{{ orderCosts.couponDiscount | currencyNumber }} </span>
    </div>

    <div class="row2">
        <div class="col1">{{ 'tsd_shipping' | localize | async }}</div>
        <div class="col2">{{ CURRENCY }} {{ orderCosts.totalShipping | currencyNumber }}</div>
    </div>
    <div class="row3">
        <div class="col1">
            {{ 'tsd_vat_word' | localize | async }}
            <span *ngIf="orderCosts.vatPercentage"> ({{ orderCosts.vatPercentage }}%) </span>
        </div>
        <div class="col2">{{ CURRENCY }} {{ orderCosts.totalVAT | currencyNumber }}</div>
    </div>
    <div class="row4">
        <div class="col1">
            <span>
                {{ 'tsd_super_deals_discount' | localize | async }}
                {{ orderCosts.pctDiscount.toString() === 'NaN' ? '' : '(' + orderCosts.pctDiscount + '%)' }}</span
            >
        </div>
        <div class="col2">{{ CURRENCY }} {{ orderCosts.totalDiscount | currencyNumber }}</div>
    </div>
    <div class="row5 row-bold">
        <div class="col1">{{ 'tsd_total' | localize | async }}</div>
        <div class="col2">{{ CURRENCY }} {{ orderCosts.totalAmount | currencyNumber }}</div>
    </div>
</div>
