<app-loading-spinner *ngIf="isUpdateOrderLoading$ | async; else showContentsOfThePage" />
<ng-template #showContentsOfThePage>
    <app-page-header [title]="'tsd_shipping_info' | localize | async" (backButtonClick)="goBack()"></app-page-header>

    <form [formGroup]="myAccountForm" (ngSubmit)="onSubmit()">
        <div class="wrapper">
            <!-- <p>{{ 'tsd_myprofile_accountIntro' | localize | async }}</p> -->
            <h3>{{ 'tsd_campaignShipping_personalInformation' | localize | async }}</h3>

            <!-- #region  Salutation, First Name, and Last Name -->
            <h3>{{ 'tsd_personal_info' | localize | async }}</h3>

            <!-- Salutation, First Name, and Last Name -->
            <app-custom-select
                [label]="'tsd_salutation' | localize | async"
                [options]="salutations"
                [isRequired]="true"
                [(ngModel)]="defaultSalutation"
                formControlName="salutation"
            ></app-custom-select>

            <mat-form-field appearance="outline">
                <mat-label>{{ 'tsd_first_name' | localize | async }}</mat-label>
                <input matInput formControlName="forename" />
            </mat-form-field>
            <div *ngIf="forename?.invalid && (forename?.dirty || forename?.touched)">
                <div *ngIf="forename?.errors?.['required']" class="input_error">
                    {{ 'tsd_first_name_validation' | localize | async }}
                </div>
                <div *ngIf="forename?.errors?.['minlength']" class="input_error">
                    {{ 'tsd_account_minlength_2_validation' | localize | async }}
                </div>
                <div *ngIf="forename?.errors?.['maxlength']" class="input_error">
                    {{ 'tsd_account_maxlength_validation' | localize | async }}
                </div>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>{{ 'tsd_last_name' | localize | async }}</mat-label>
                <input matInput formControlName="lastname" />
            </mat-form-field>
            <div *ngIf="lastname?.invalid && (lastname?.dirty || lastname?.touched)">
                <div *ngIf="lastname?.errors?.['required']" class="input_error">
                    {{ 'tsd_last_name_validation' | localize | async }}
                </div>
                <div *ngIf="lastname?.errors?.['minlength']" class="input_error">
                    {{ 'tsd_account_minlength_2_validation' | localize | async }}
                </div>
                <div *ngIf="lastname?.errors?.['maxlength']" class="input_error">
                    {{ 'tsd_account_maxlength_validation' | localize | async }}
                </div>
            </div>

            <app-custom-select
                [label]="'tsd_languages' | localize | async"
                [options]="languagesArray"
                [isRequired]="true"
                [(ngModel)]="defaultLanguage"
                formControlName="correspondanceLanguage"
            ></app-custom-select>
            <!-- #endregion -->

            <!-- #region Delivery Address Section -->
            <div formGroupName="deliveryAddress">
                <h3>{{ 'tsd_delivery_address' | localize | async }}</h3>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'tsd_street' | localize | async }}</mat-label>
                    <input matInput formControlName="street" />
                </mat-form-field>
                <div
                    *ngIf="
                        deliveryAddress?.get('street')?.invalid &&
                        (deliveryAddress?.get('street')?.dirty || deliveryAddress?.get('street')?.touched)
                    "
                >
                    <div *ngIf="deliveryAddress?.get('street')?.errors?.['required']" class="input_error">
                        {{ 'tsd_street_validation' | localize | async }}
                    </div>
                    <div *ngIf="deliveryAddress?.get('street')?.errors?.['minlength']" class="input_error">
                        {{ 'tsd_account_minlength_2_validation' | localize | async }}
                    </div>
                    <div *ngIf="deliveryAddress?.get('street')?.errors?.['maxlength']" class="input_error">
                        {{ 'tsd_account_maxlength_validation' | localize | async }}
                    </div>
                </div>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'tsd_postal_code' | localize | async }}</mat-label>
                    <input maxlength="4" matInput formControlName="zipcode" />
                </mat-form-field>
                <div
                    *ngIf="
                        deliveryAddress?.get('zipcode')?.invalid &&
                        (deliveryAddress?.get('zipcode')?.dirty || deliveryAddress?.get('zipcode')?.touched)
                    "
                    class="input_error"
                >
                    <div *ngIf="deliveryAddress?.get('zipcode')?.errors?.['required']" class="input_error">
                        {{ 'tsd_postal_code_validation' | localize | async }}
                    </div>
                    <div *ngIf="deliveryAddress?.get('zipcode')?.errors?.['minlength']" class="input_error">
                        {{ 'tsd_account_minlength_4_validation' | localize | async }}
                    </div>
                </div>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'tsd_residence' | localize | async }}</mat-label>
                    <input matInput formControlName="city" />
                </mat-form-field>
                <div
                    *ngIf="
                        deliveryAddress?.get('city')?.invalid &&
                        (deliveryAddress?.get('city')?.dirty || deliveryAddress?.get('city')?.touched)
                    "
                >
                    <div *ngIf="deliveryAddress?.get('city')?.errors?.['required']" class="input_error">
                        {{ 'tsd_residance_validation' | localize | async }}
                    </div>
                    <div *ngIf="deliveryAddress?.get('city')?.errors?.['minlength']" class="input_error">
                        {{ 'tsd_account_minlength_2_validation' | localize | async }}
                    </div>
                    <div *ngIf="deliveryAddress?.get('city')?.errors?.['maxlength']" class="input_error">
                        {{ 'tsd_account_maxlength_validation' | localize | async }}
                    </div>
                </div>
            </div>
            <!-- #endregion -->

            <!-- Billing Address Section -->
            <div class="custom-checkbox">
                <div
                    class="box"
                    [class.checked]="myAccountForm.get('sameAsDelivery')?.value === true"
                    (click)="onCheckboxClick('sameAsDelivery')"
                ></div>
                <label (click)="onCheckboxClick('sameAsDelivery')">{{
                    'tsd_delivery_billing' | localize | async
                }}</label>
            </div>
            <!-- #region Billing Address Section -->
            <div formGroupName="billingAddress" *ngIf="myAccountForm.get('sameAsDelivery')?.value === false">
                <h3>{{ 'tsd_billing_address' | localize | async }}</h3>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'tsd_street' | localize | async }}</mat-label>
                    <input matInput formControlName="street" />
                </mat-form-field>
                <div
                    *ngIf="
                        billingAddress?.get('street')?.invalid &&
                        (billingAddress?.get('street')?.dirty || billingAddress?.get('street')?.touched)
                    "
                >
                    <div *ngIf="billingAddress?.get('street')?.errors?.['required']" class="input_error">
                        {{ 'tsd_street_validation' | localize | async }}
                    </div>
                    <div *ngIf="billingAddress?.get('street')?.errors?.['minlength']" class="input_error">
                        {{ 'tsd_account_minlength_2_validation' | localize | async }}
                    </div>
                    <div *ngIf="billingAddress?.get('street')?.errors?.['maxlength']" class="input_error">
                        {{ 'tsd_account_maxlength_validation' | localize | async }}
                    </div>
                </div>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'tsd_postal_code' | localize | async }}</mat-label>
                    <input maxlength="4" matInput formControlName="zipcode" />
                </mat-form-field>
                <div
                    *ngIf="
                        billingAddress?.get('zipcode')?.invalid &&
                        (billingAddress?.get('zipcode')?.dirty || billingAddress?.get('zipcode')?.touched)
                    "
                    class="input_error"
                >
                    <div *ngIf="billingAddress?.get('zipcode')?.errors?.['required']" class="input_error">
                        {{ 'tsd_postal_code_validation' | localize | async }}
                    </div>
                    <div *ngIf="billingAddress?.get('zipcode')?.errors?.['minlength']" class="input_error">
                        {{ 'tsd_account_minlength_4_validation' | localize | async }}
                    </div>
                </div>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'tsd_residence' | localize | async }}</mat-label>
                    <input matInput formControlName="city" />
                </mat-form-field>
                <div
                    *ngIf="
                        billingAddress?.get('city')?.invalid &&
                        (billingAddress?.get('city')?.dirty || billingAddress?.get('city')?.touched)
                    "
                >
                    <div *ngIf="billingAddress?.get('city')?.errors?.['required']" class="input_error">
                        {{ 'tsd_residance_validation' | localize | async }}
                    </div>
                    <div *ngIf="billingAddress?.get('city')?.errors?.['minlength']" class="input_error">
                        {{ 'tsd_account_minlength_2_validation' | localize | async }}
                    </div>
                    <div *ngIf="billingAddress?.get('city')?.errors?.['maxlength']" class="input_error">
                        {{ 'tsd_account_maxlength_validation' | localize | async }}
                    </div>
                </div>
            </div>
            <!-- #endregion -->

            <!-- #region Contact Information -->
            <div>
                <h3>{{ 'tsd_contact' | localize | async }}</h3>
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'tsd_phone' | localize | async }}</mat-label>
                    <input matInput formControlName="phone" (input)="handlePhoneInputChanged($event)" />
                </mat-form-field>
                <div *ngIf="phoneElement.invalid && (phoneElement.dirty || phoneElement.touched)">
                    <div
                        *ngIf="phoneElement?.errors?.['required'] || phoneElement.hasError('isMsgAppear')"
                        class="input_error"
                    >
                        {{ 'tsd_phone_validation' | localize | async }}
                    </div>
                </div>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'tsd_email' | localize | async }}</mat-label>
                    <input type="email" matInput formControlName="email" />
                </mat-form-field>
                <div *ngIf="email?.invalid && (email?.dirty || email?.touched)" class="input_error">
                    {{ 'tsd_email_validation' | localize | async }}
                </div>
            </div>
            <!-- #endregion -->

            <!-- Further elements... -->
            <div class="custom-checkbox">
                <div
                    class="box"
                    [class.checked]="myAccountForm.get('saveSettings')?.value === true"
                    (click)="onCheckboxClick('saveSettings')"
                ></div>
                <label (click)="onCheckboxClick('saveSettings')">{{ 'tsd_save_my_data' | localize | async }}</label>
            </div>
            <div class="custom-checkbox">
                <div
                    class="box"
                    [class.checked]="myAccountForm.get('receiveEmails')?.value === true"
                    (click)="onCheckboxClick('receiveEmails')"
                ></div>
                <label (click)="onCheckboxClick('receiveEmails')">
                    {{ 'tsd_accept_to_recieve_mails' | localize | async }}
                </label>
            </div>
            <div class="custom-checkbox" *ngIf="currentPurchaseOrder">
                <div
                    class="box"
                    [class.checked]="myAccountForm.get('acceptTerms')?.value === true"
                    (click)="onCheckboxClick('acceptTerms')"
                ></div>
                <label
                    class="acceptTerms"
                    (click)="onCheckboxClick('acceptTerms')"
                    [innerHTML]="getLink('tsd_terms_and_conditions' | localize | async)"
                >
                </label>
            </div>

            <!-- Submit Button -->
            <div class="stickbutton">
                <button
                    type="submit"
                    [disabled]="
                        myAccountForm.invalid || ((isUpdateOrderLoading$ | async) ?? false) || !formValidityMaxLimit
                    "
                >
                    {{ 'tsd_save_btn' | localize | async }}
                </button>
            </div>
        </div>
    </form>
</ng-template>
