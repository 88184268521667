import type {
    GroupedOrders,
    ICartOperationResponse,
    ICheckoutCartRequest,
    ICheckoutResponse,
    ICreateOrUpdateCartRequest,
    ISalesOrder,
} from '@modeso/types__tsd-lib-cart-be';
import type { CouponValidationDTO, ICodeValidityCheckRequest } from '@modeso/types__twint-lib-coupons';
import { createAction } from '@ngrx/store';
import { OrderPendingEnum, PrefixEnum } from './action-names.enum';

export const loadOrders = createAction(
    '[Order] Load Orders',
    (pending: string = `${PrefixEnum.BEGIN}_${OrderPendingEnum.LOAD_ORDERS}`) => ({ pending }),
);

export const ordersLoaded = createAction(
    '[Order] Orders Loaded',
    (orders: ISalesOrder[], pending: string = `${PrefixEnum.END}_${OrderPendingEnum.LOAD_ORDERS}`) => ({
        pending,
        orders,
    }),
);

export const loadOrdersGrouped = createAction(
    '[Order] Load Orders Grouped',
    (pending: string = `${PrefixEnum.BEGIN}_${OrderPendingEnum.LOAD_ORDERS_GROUPED}`) => ({ pending }),
);

export const groupedOrdersLoaded = createAction(
    '[Order] Grouped Orders Loaded',
    (groupedOrders: GroupedOrders, pending: string = `${PrefixEnum.END}_${OrderPendingEnum.LOAD_ORDERS_GROUPED}`) => ({
        pending,
        groupedOrders,
    }),
);

export const loadOrderByUuid = createAction(
    '[Order] Load Order By Uuid',
    (uuid: string, pending: string = `${PrefixEnum.BEGIN}_${OrderPendingEnum.LOAD_ORDER_BY_UUID}`) => ({
        uuid,
        pending,
    }),
);

export const orderByUuidLoaded = createAction(
    '[Order] Order By Uuid Loaded',
    (order: ISalesOrder, pending: string = `${PrefixEnum.END}_${OrderPendingEnum.LOAD_ORDER_BY_UUID}`) => ({
        pending,
        order,
    }),
);

export const createOrder = createAction(
    '[Order] Create Order',
    (order: ICreateOrUpdateCartRequest, pending: string = `${PrefixEnum.BEGIN}_${OrderPendingEnum.CREATE_ORDER}`) => ({
        order,
        pending,
    }),
);

export const orderCreated = createAction(
    '[Order] Order Created',
    (
        order: ICartOperationResponse,
        pending: string = `${PrefixEnum.END}_${OrderPendingEnum.CREATE_ORDER}`,
        success: string = `${OrderPendingEnum.CREATE_ORDER}`,
    ) => ({
        pending,
        success,
        order,
    }),
);

export const updateOrder = createAction(
    '[Order] Update Order',
    (order: ICreateOrUpdateCartRequest, pending: string = `${PrefixEnum.BEGIN}_${OrderPendingEnum.UPDATE_ORDER}`) => ({
        order,
        pending,
    }),
);

export const orderUpdated = createAction(
    '[Order] Order Updated',
    (
        order: ICartOperationResponse,
        pending: string = `${PrefixEnum.END}_${OrderPendingEnum.UPDATE_ORDER}`,
        success: string = `${OrderPendingEnum.UPDATE_ORDER}`,
    ) => ({
        pending,
        success,
        order,
    }),
);

export const checkOutOrder = createAction(
    '[Order] CheckOut Order',
    (order: ICheckoutCartRequest, pending: string = `${PrefixEnum.BEGIN}_${OrderPendingEnum.CHECKOUT_ORDER}`) => ({
        order,
        pending,
    }),
);

export const orderCheckedOut = createAction(
    '[Order] Order orderCheckedOut',
    (checkedOutOrder: ICheckoutResponse, pending: string = `${PrefixEnum.END}_${OrderPendingEnum.CHECKOUT_ORDER}`) => ({
        pending,
        checkedOutOrder,
    }),
);

export const applyCoupon = createAction(
    '[Order] Apply Coupon',
    (payload: ICodeValidityCheckRequest, pending: string = `${PrefixEnum.BEGIN}_${OrderPendingEnum.APPLY_COUPON}`) => ({
        payload,
        pending,
    }),
);

export const couponApplied = createAction(
    '[Order] Coupon Applied',
    (coupon: CouponValidationDTO, pending: string = `${PrefixEnum.END}_${OrderPendingEnum.APPLY_COUPON}`) => ({
        pending,
        coupon,
    }),
);

export const loadCouponFeature = createAction(
    '[Order] Load Coupon Feature',
    (pending: string = `${PrefixEnum.BEGIN}_${OrderPendingEnum.LOAD_COUPON_FEATURE}`) => ({ pending }),
);

export const couponFeatureLoaded = createAction(
    '[Order] Coupon Feature Loaded',
    (
        couponFeatureDisplayStatus: boolean,
        pending: string = `${PrefixEnum.END}_${OrderPendingEnum.LOAD_COUPON_FEATURE}`,
    ) => ({
        pending,
        couponFeatureDisplayStatus,
    }),
);

export const resetPurchaseInformation = createAction('[Order] Reset Purchase Information');
