/* eslint-disable no-magic-numbers */
/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/unbound-method */
import type { OnInit } from '@angular/core';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type { AbstractControl, FormGroup } from '@angular/forms';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import type { IPersonDto } from '@modeso/types__tsd-lib-user-be';
import { SalutationEnum } from '@modeso/types__tsd-lib-user-be';
import { Store } from '@ngrx/store';
import debug from 'debug';
import type { PhoneNumber } from 'libphonenumber-js';
import { isValidPhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import { AnalyticsPageName } from '../../../../domain/models/enums/page-name.enum';
import { AnalyticsActions, UserProfileActions } from '../../../../domain/store/actions/action-types';
import type { AppState } from '../../../../domain/store/reducers/app.reducer';
import { selectUserProfile } from '../../../../domain/store/reducers/user-profile.reducer';
import { getLanguage } from '../../../../domain/utils/language';
import { getFromSessionStorage } from '../../../../domain/utils/storage';
import { emailValidator } from '../../../../domain/validators/email-validator';

export interface DropDownKeyValue {
    value: SalutationEnum | string;
    name: string;
}

export type AddressFormControl = AbstractControl<
    {
        street: string;
        zipcode: string;
        city: string;
    },
    {
        street: string;
        zipcode: string;
        city: string;
    }
> | null;

@Component({
    selector: 'app-my-profile',
    templateUrl: './my-profile.component.html',
    styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit {
    private readonly fb = inject(FormBuilder);
    private readonly store = inject<Store<AppState>>(Store<AppState>);
    private readonly router = inject(Router);
    private readonly destroyRef = inject(DestroyRef);
    private readonly language = getLanguage();
    formValidityMaxLimit = false;
    parsePhone: PhoneNumber | undefined;

    myAccountForm = this.fb.nonNullable.group({
        salutation: [SalutationEnum.MR, Validators.required],
        forename: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
        lastname: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
        correspondanceLanguage: ['', Validators.required],
        deliveryAddress: this.fb.nonNullable.group({
            street: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
            zipcode: [
                '',
                [Validators.required, Validators.maxLength(4), Validators.minLength(4), Validators.pattern('^[0-9]*$')],
            ],
            city: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
        }),
        billingAddress: this.fb.nonNullable.group({
            street: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
            zipcode: [
                '',
                [Validators.required, Validators.maxLength(4), Validators.minLength(4), Validators.pattern('^[0-9]*$')],
            ],
            city: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],
        }),
        phone: ['', Validators.required],
        email: ['', [Validators.required, emailValidator()]],
        receiveEmails: [true],
        sameAsDelivery: [true],
    });

    isCreateNewUser = true;

    salutations: DropDownKeyValue[] = [
        { value: SalutationEnum.MR, name: 'tsd_salutation_male' },
        { value: SalutationEnum.MRS, name: 'tsd_salutation_female' },
    ];

    languagesArray: DropDownKeyValue[] = [
        { value: 'de-ch', name: 'tsd_languages_german' },
        { value: 'en-us', name: 'tsd_languages_english' },
        { value: 'fr-ch', name: 'tsd_languages_french' },
        { value: 'it-ch', name: 'tsd_languages_italian' },
    ];

    protected defaultSalutation = this.salutations[0];
    protected defaultLanguage =
        this.languagesArray.find((language) => language.value === this.language) ?? this.languagesArray[0];

    private handleAddressToggle(): void {
        this.myAccountForm
            .get('sameAsDelivery')
            ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (val) => this.handleBillingAddressChange(val),
            });
    }

    private handleBillingAddressChange(sameAsDelivery: boolean): void {
        const billingAddress = this.myAccountForm.get('billingAddress');
        if (sameAsDelivery === true) {
            billingAddress?.disable();
            this.myAccountForm.controls.billingAddress.controls.city.setValidators(null);
            this.myAccountForm.controls.billingAddress.controls.zipcode.setValidators(null);
            this.myAccountForm.controls.billingAddress.controls.street.setValidators(null);
        } else {
            billingAddress?.enable();
            this.myAccountForm.controls.billingAddress.controls.street.setValidators([
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(50),
            ]);
            this.myAccountForm.controls.billingAddress.controls.zipcode.setValidators([
                Validators.required,
                Validators.maxLength(4),
                Validators.minLength(4),
                Validators.pattern('^[0-9]*$'),
            ]);
            this.myAccountForm.controls.billingAddress.controls.city.setValidators([
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(50),
            ]);
        }
        this.myAccountForm.controls.billingAddress.controls.city.updateValueAndValidity();
        this.myAccountForm.controls.billingAddress.controls.zipcode.updateValueAndValidity();
        this.myAccountForm.controls.billingAddress.controls.street.updateValueAndValidity();
        billingAddress?.updateValueAndValidity();
        this.myAccountForm.updateValueAndValidity();
    }

    checkMaxLengthAndMarkInvalidFields(): void {
        const deliveryAddress = this.myAccountForm.get('deliveryAddress') as FormGroup;
        const billingAddress = this.myAccountForm.get('billingAddress') as FormGroup;
        const forename = this.myAccountForm.get('forename');
        const lastname = this.myAccountForm.get('lastname');
        const deliveryStreet = deliveryAddress.get('street');
        const deliveryCity = deliveryAddress.get('city');
        const billingStreet = billingAddress.get('street');
        const billingCity = billingAddress.get('city');
        const billingForename = billingAddress.get('forename');
        const billingLastname = billingAddress.get('lastname');

        const controls = [
            forename,
            lastname,
            deliveryStreet,
            deliveryCity,
            billingStreet,
            billingCity,
            billingForename,
            billingLastname,
        ];
        for (const control of controls) {
            if (control?.value && control.value.length > 50) {
                // control.setValue(control.value.substring(0, 50));
                // set error
                control.setErrors({ maxlength: true });
                control.markAsTouched();
            }
        }
    }

    checkMaxLengthAndSetFormValidity(): boolean {
        const forename = this.myAccountForm.value.forename;
        const lastname = this.myAccountForm.value.lastname;
        const deliveryStreet = this.myAccountForm.value.deliveryAddress?.street;
        const deliveryCity = this.myAccountForm.value.deliveryAddress?.city;
        const billingStreet = this.myAccountForm.value.billingAddress?.street;
        const billingCity = this.myAccountForm.value.billingAddress?.city;
        const billingForename = forename;
        const billingLastname = lastname;

        const fields: (string | undefined)[] = [
            forename,
            lastname,
            deliveryStreet,
            deliveryCity,
            billingStreet,
            billingCity,
            billingForename,
            billingLastname,
        ];
        for (const field of fields) {
            if (field && field.length > 50) {
                return false;
            }
        }
        return true;
    }

    onCheckboxClick(controlName: string): void {
        const control = this.myAccountForm.get(controlName);
        if (control) {
            control.setValue(!(control.value as boolean));
            const isSameAsDelivery = control.value as boolean;
            if (controlName !== 'sameAsDelivery') return;
            if (isSameAsDelivery) {
                // If same as delivery, copy values from delivery address to billing address
                const deliveryAddress = this.myAccountForm.get('deliveryAddress');
                const billingAddress = this.myAccountForm.get('billingAddress');

                if (deliveryAddress && billingAddress) {
                    billingAddress.setValue({
                        street: deliveryAddress.get('street')?.value || '',
                        zipcode: deliveryAddress.get('zipcode')?.value || '',
                        city: deliveryAddress.get('city')?.value || '',
                    });

                    billingAddress.markAsDirty();
                    billingAddress.markAsTouched();
                }
            } else {
                // If not same as delivery, clear billing address
                const billingAddress = this.myAccountForm.get('billingAddress');
                if (billingAddress) {
                    billingAddress.reset(); // Resetting the entire form group
                }
            }
        }
    }

    handlePhoneInputChanged(event: Event): void {
        const target = event.target as HTMLInputElement;
        const phoneNumber = target.value;
        this.checkPhoneNumber(phoneNumber);
    }

    checkPhoneNumber(phoneNumber: string): void {
        // We should catch error here because there are error thrown in the console
        try {
            this.parsePhone = parsePhoneNumber(phoneNumber, 'CH');
        } catch (error: any) {
            debug(error?.message);
        }
        const isValid = isValidPhoneNumber(phoneNumber, 'CH');
        if (isValid && this.parsePhone?.country === 'CH') {
            this.myAccountForm.get('phone')?.setErrors(null);
            const formatPhoneNumber = this.parsePhone?.formatInternational();
            this.phone?.setValue(formatPhoneNumber);
        } else {
            this.myAccountForm.get('phone')?.setErrors({ isMsgAppear: true });
            this.myAccountForm.get('phone')?.markAsTouched();
        }
    }

    deleteUserProfile(event: Event): void {
        if (this.isCreateNewUser) return;
        event.preventDefault();
        this.store.dispatch(UserProfileActions.deleteUserProfile());
        // reset all fields
        this.myAccountForm.reset();
        this.myAccountForm.markAsUntouched();
        this.myAccountForm.markAsPristine();
        this.isCreateNewUser = true;
    }

    onSubmit(): void {
        if (!this.myAccountForm.valid) return;
        const { sameAsDelivery, ...data } = this.myAccountForm.value as IPersonDto & { sameAsDelivery: boolean };
        const payload = { ...data };
        const sharedInfo = {
            forename: payload.forename,
            lastname: payload.lastname,
            salutation: payload.salutation,
        };
        payload.deliveryAddress = {
            ...payload.deliveryAddress,
            ...sharedInfo,
        };

        if (sameAsDelivery ?? false) {
            payload.billingAddress = { ...payload.deliveryAddress, ...sharedInfo };
        } else {
            payload.billingAddress = { ...payload.billingAddress, ...sharedInfo };
        }

        this.parsePhone = parsePhoneNumber(payload.phone, 'CH');
        payload.phone = this.parsePhone.number as string;

        if (this.isCreateNewUser) {
            this.store.dispatch(UserProfileActions.createUserProfile(payload));
        } else {
            this.store.dispatch(UserProfileActions.updateUserProfile(payload));
        }
        void this.router.navigate([this.language]);
    }

    goBack(): Promise<boolean> {
        return this.router.navigate(['../']);
    }

    private checkEqualAddressDetails(profile: IPersonDto): boolean {
        return (
            profile?.billingAddress?.city === profile?.deliveryAddress?.city &&
            profile?.billingAddress?.zipcode === profile?.deliveryAddress?.zipcode &&
            profile?.billingAddress?.street === profile?.deliveryAddress?.street
        );
    }

    private loadUserProfile(): void {
        this.store
            .select(selectUserProfile)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (profile) => {
                    // console.log('Profile: ', profile);
                    if (profile != null) {
                        this.isCreateNewUser = false;
                        const sameAsDelivery = this.checkEqualAddressDetails(profile);
                        this.myAccountForm.patchValue({
                            ...profile,
                            sameAsDelivery,
                        });
                        this.defaultSalutation =
                            this.salutations.find((salutation) => salutation.value === profile.salutation) ??
                            this.salutations[0];

                        this.defaultLanguage =
                            this.languagesArray.find((language) => language.value === profile.correspondanceLanguage) ??
                            this.languagesArray[0];

                        // parsePhone
                        const phoneNumber = profile.phone;
                        this.checkPhoneNumber(phoneNumber);

                        this.myAccountForm.updateValueAndValidity();
                        this.formValidityMaxLimit = this.checkMaxLengthAndSetFormValidity();
                        this.checkMaxLengthAndMarkInvalidFields();
                    } else {
                        this.handleBillingAddressChange(true);
                        this.myAccountForm.get('correspondanceLanguage')?.setValue(this.language as string);
                    }
                },
            });
    }

    ngOnInit(): void {
        this.store.dispatch(UserProfileActions.loadUserProfile());
        this.store.dispatch(
            AnalyticsActions.savePageView({
                payload: {
                    productLine: null,
                    pageName: AnalyticsPageName.MY_PROFILE,
                    terminalId: getFromSessionStorage('TERMINAL_ID') ?? '',
                },
            }),
        );

        this.handleAddressToggle();
        this.loadUserProfile();

        this.myAccountForm.statusChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
            next: (status) => {
                this.formValidityMaxLimit = status === 'VALID';
            },
        });
    }

    get forename(): AbstractControl<string, string> | null {
        return this.myAccountForm.get('forename');
    }
    get lastname(): AbstractControl<string, string> | null {
        return this.myAccountForm.get('lastname');
    }
    get deliveryAddress(): AddressFormControl {
        return this.myAccountForm.get('deliveryAddress');
    }
    get billingAddress(): AddressFormControl {
        return this.myAccountForm.get('billingAddress');
    }
    get phone(): AbstractControl<string, string> | null {
        return this.myAccountForm.get('phone');
    }
    get email(): AbstractControl<string, string> | null {
        return this.myAccountForm.get('email');
    }
}
