import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MyOrdersItem } from '@modeso/types__tsd-lib-cart-be';
import { VisualTypes } from '@modeso/types__tsd-lib-products-be';
import { SWISS_CURRENCY } from '../../../../domain/constants/constants';

@Component({
    selector: 'app-my-orders-item',
    templateUrl: './my-orders-item.component.html',
    styleUrls: ['./my-orders-item.component.scss'],
})
export class MyOrdersItemComponent {
    @Input({ required: true })
    item!: MyOrdersItem;
    isPlaceHolder = false;
    @Output()
    loadError: EventEmitter<boolean> = new EventEmitter<boolean>();

    CURRENCY = SWISS_CURRENCY;
    protected readonly imageTypes = VisualTypes;

    loadPlaceholderImage(): void {
        this.loadError.emit(true);
        this.isPlaceHolder = true;
    }
}
