/* eslint-disable no-magic-numbers */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import type { AfterViewInit, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    NgZone,
    QueryList,
    ViewChild,
    ViewChildren,
    inject,
} from '@angular/core';
import { Router } from '@angular/router';
import { VisualTypes } from '@modeso/types__tsd-lib-products-be';
import Flickity from 'flickity';
import type { PinchZoomComponent } from 'ngx-pinch-zoom-16';
import { ZERO_LENGTH } from '../../../../domain/constants/constants';
import type { CampaignImageSlider } from '../../../../domain/models/campaign-image-slider.interface';
import { YoutubePlayerComponent } from '../youtube-player/youtube-player.component';

@Component({
    selector: 'app-campaign-image-gallery',
    templateUrl: './campaign-image-gallery.component.html',
    styleUrls: ['./campaign-image-gallery.component.scss'],
})
export class CampaignImageGalleryComponent implements OnChanges, OnDestroy, OnInit, AfterViewInit {
    @Input({ required: true }) campaignImageSlider: CampaignImageSlider[] = [];
    @Input({ required: true }) pillText!: string;
    @Input() normalMode: boolean = true;

    @ViewChild('carouselElement', { static: true }) carouselElement!: ElementRef;
    @ViewChildren(YoutubePlayerComponent) youtubePlayers: QueryList<YoutubePlayerComponent> | undefined;
    @ViewChildren('pinchZoom') pinchZoomComponents!: QueryList<PinchZoomComponent>;

    private zoomCheckInterval: NodeJS.Timeout | undefined;
    private lastSliderDraggableState: boolean = true;

    private readonly router = inject(Router);

    flickity: Flickity | undefined;
    activeIndex: number = 0;
    visualTypes = VisualTypes;
    carouselInitialized = false;
    isAnyZoomedIn: boolean = false;

    constructor(
        private readonly ngZone: NgZone,
        private readonly cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.activeIndex = (this.router.lastSuccessfulNavigation?.extras?.state?.['lastImageIndex'] as number) ?? 0;

        // Start checking the pinch zoom state
        this.startCheckingZoomState();
    }

    ngAfterViewInit(): void {
        this.pinchZoomComponents?.forEach((pinchZoomComponent) => pinchZoomComponent?.initPinchZoom());

        // Recheck zoom state after view initialization
        this.checkZoomState();
    }

    // Implement ngOnChanges method to react to input property changes
    ngOnChanges(changes: SimpleChanges): void {
        // Check if the campaignImageSlider input has changed

        if (
            changes['campaignImageSlider'] != null &&
            changes['campaignImageSlider'].currentValue != null &&
            changes['campaignImageSlider'].currentValue.length > ZERO_LENGTH
        ) {
            //if (this.carouselInitialized) return;
            setTimeout(() => {
                this.initCarousel(this.activeIndex); // Reinitialize the carousel
                this.carouselInitialized = true;
            }, 0);
        }
    }

    ngOnDestroy(): void {
        this.flickity?.destroy();
        this.pinchZoomComponents?.forEach((pinchZoomComponent) => {
            pinchZoomComponent?.destroy();
        });

        // Clear the interval when component is destroyed
        if (this.zoomCheckInterval) {
            clearInterval(this.zoomCheckInterval);
        }
    }

    startCheckingZoomState(): void {
        this.ngZone.runOutsideAngular(() => {
            this.zoomCheckInterval = setInterval(() => {
                this.checkZoomState();
            }, 300); // Check every 300 milliseconds
        });
    }

    checkZoomState(): void {
        this.isAnyZoomedIn = this.pinchZoomComponents?.some((pinchZoom) => pinchZoom.isZoomedIn);
        // slider enabled & zoomed in not active
        if (this.lastSliderDraggableState && !this.isAnyZoomedIn) return;

        // slider disabled & zoomed in active
        if (!this.lastSliderDraggableState && this.isAnyZoomedIn) return;

        // slider disabled & zoomed in not active
        if (!this.lastSliderDraggableState && !this.isAnyZoomedIn) {
            this.initCarousel(this.activeIndex, true);
            return;
        }

        // slider enabled & zoomed in active
        if (this.lastSliderDraggableState && this.isAnyZoomedIn) {
            this.initCarousel(this.activeIndex, false);
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.flickity?.resize(); // Trigger Flickity to update its layout
    }

    navigateToSlide(index: number): void {
        this.activeIndex = index;
    }

    initCarousel(initialIndex: number = 0, draggable: boolean = true): void {
        if (this.flickity) {
            this.flickity.destroy();
        }

        // Trigger change detection to ensure view is updated
        this.cdr.detectChanges();

        // Ensure slides are in the DOM
        if (this.carouselElement == null) {
            return;
        }

        this.lastSliderDraggableState = draggable;
        // Make sure to run Flickity inside the Angular zone
        this.flickity = new Flickity(this.carouselElement.nativeElement as HTMLElement, {
            // Flickity options here
            initialIndex: initialIndex,
            freeScroll: false,
            contain: true,
            pageDots: false,
            percentPosition: false,
            lazyLoad: true,
            setGallerySize: false,
            prevNextButtons: false, // Hide default buttons
            draggable: draggable,
        });

        this.flickity.on('change', (index: number) => {
            if (
                this.campaignImageSlider[this.activeIndex] != null &&
                this.campaignImageSlider[this.activeIndex].videoId != null &&
                this.youtubePlayers != null
            ) {
                this.pauseVideo(this.activeIndex);
            }

            // Ensure the change detection is run since Flickity runs outside Angular
            this.ngZone.run(() => {
                this.activeIndex = index;
            });
        });
    }

    clickImg(url: string): void {
        if (this.normalMode) {
            void this.router.navigate([this.router.url, 'zoom'], {
                state: { sliderImages: this.campaignImageSlider, currentImageUrl: url },
            });
        }
    }

    navigateToZoomPage(type: VisualTypes): void {
        if (!this.normalMode || type === this.visualTypes.VIDEO) return;
        void this.router.navigate([this.router.url, 'zoom'], {
            state: { sliderImages: this.campaignImageSlider },
        });
    }

    private pauseVideo(index: number): void {
        const videoId = this.campaignImageSlider[index].videoId;

        if (videoId == null) {
            throw new Error(`Slider with index: ${index} should be a video to be paused`);
        }

        if (this.youtubePlayers == null) {
            throw new Error('There are no youtube rendered to be paused!');
        }

        const videoToPause = this.youtubePlayers.find((youtubePlayer) => youtubePlayer.videoId === videoId);

        if (videoToPause == null) {
            throw new Error('Suddenly th video to be paused is not found in the dom');
        }

        videoToPause.pauseVideo();
    }

    protected goToPreviousSlide(): void {
        // Go to the previous slide using Flickity's method
        this.flickity?.previous();
    }

    protected goToNextSlide(): void {
        // Go to the next slide using Flickity's method
        this.flickity?.next();
    }
}
