/* eslint-disable no-magic-numbers */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import type { IConfigPrice, IConfigStock, IDynamicConfig, SpecialDealDTO } from '@modeso/types__tsd-lib-products-be';
import { StockReductionType } from '@modeso/types__tsd-lib-products-be';

const EMPTY_STOCK = 0;
const MIN_REMAINING_STOCK = 1;

export class SpecialDealUtils {
    /**
     * Calculate remaining stock of all configurations
     * @param value SpecialDealDTO
     * returns the remaining stock of all configurations
     */
    static calculateRemainingStock(value: SpecialDealDTO): number {
        const fullStock: IConfigStock = {
            originalStock: 0,
            remainingStock: 0,
            fictionalStock: 0,
            config: { config: 'none' },
        };

        value.dealData.dealStock.forEach((stock: IConfigStock) => {
            fullStock.originalStock += stock.originalStock;
            fullStock.remainingStock += stock.remainingStock;
            // Multiply the fictional stock by its weight (original stock) before adding it
            fullStock.fictionalStock += stock.fictionalStock * stock.originalStock;
        });

        // Divide the weighted sum by the total weight (total original stock) to get the weighted average
        if (fullStock.originalStock > 0) {
            fullStock.fictionalStock /= fullStock.originalStock;
        }

        let remainingStock = 0;
        if (fullStock.originalStock != EMPTY_STOCK && fullStock.remainingStock != EMPTY_STOCK) {
            if (value.stockReduction === undefined || value.stockReduction === StockReductionType.LINEAR) {
                remainingStock = this.calculateLinearReduction(fullStock);
            } else {
                remainingStock = this.calculateLogReduction(fullStock, value.stockReduction);
            }
            if (isNaN(remainingStock)) {
                // [originalStock = 1 and remainingStock = 1] or [originalStock = 0 and remainingStock = 0]
                remainingStock = fullStock.fictionalStock;
            }
            // if remaining stock is less than 1 after calculation, set it to 1
            else if (remainingStock < MIN_REMAINING_STOCK) {
                remainingStock = MIN_REMAINING_STOCK;
            }
        }
        return remainingStock;
    }

    /**
     * Calculate fictional stock using linear reduction
     * @param stock IConfigStock
     * @param fictionalStockPercentage number
     * returns the fictional stock
     */
    static calculateLinearReduction(stock: IConfigStock): number {
        return Math.floor((stock.remainingStock / stock.originalStock) * stock.fictionalStock);
    }

    /**
     * Calculate fictional stock using logarithmic reduction
     * @param stock IConfigStock
     * @param reductionFactor number
     * @param fictionalStockPercentage number
     * returns the fictional stock
     */
    static calculateLogReduction(stock: IConfigStock, reductionFactor: number): number {
        // equation: (Logbase=total(remaining)^n)*remaining/total * (100 | fictionalStockPercentage)
        const remainingStockLog = this.getBaseLog(stock.originalStock, stock.remainingStock);
        const remainingStockOfN = Math.pow(remainingStockLog, reductionFactor);
        return Math.floor(((remainingStockOfN * stock.remainingStock) / stock.originalStock) * stock.fictionalStock);
    }

    /**
     * get the log of a number with a base
     * @param base number
     * @param logNumber number
     * returns the log of a number with a base
     */
    static getBaseLog(base: number, logNumber: number): number {
        return Math.log(logNumber) / Math.log(base);
    }

    /**
     *
     * @param value SpecialDealDTO
     * returns the best discount of all configurations
     */
    static calculateDiscount(value: SpecialDealDTO): string {
        const dealPriceWithConfig = SpecialDealUtils.findDealPrice(value);
        const price1 = dealPriceWithConfig.dealPriceWithStock;
        try {
            const price2 = SpecialDealUtils.findOriginalPrice(value, dealPriceWithConfig.config);
            const discount = (100 - (price1 / price2) * 100).toFixed(0);
            return discount;
        } catch (error) {
            return '0';
        }
    }

    // TODO: calculations must return number not string
    static findDealPrice(value: SpecialDealDTO): {
        dealPriceWithStock: number;
        config: IDynamicConfig;
    } {
        let dealPriceWithStock = 0;
        let config;
        value.dealData.dealPrices.forEach((dealPrice) => {
            if (dealPriceWithStock !== 0) return;

            const stock = SpecialDealUtils.findStockForConfiguration(dealPrice.config, value.dealData.dealStock);
            if (stock.remainingStock >= 1) {
                dealPriceWithStock = dealPrice.price;
                config = dealPrice.config;
            }
        });

        // if no stock remaining set deal price of the first variation's price
        if (dealPriceWithStock === 0) {
            dealPriceWithStock = value.dealData.dealPrices[0].price;
        }
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!config) config = value.dealData.dealPrices[0].config;

        return { dealPriceWithStock, config };
    }

    static findOriginalPrice(value: SpecialDealDTO, config: IDynamicConfig): number {
        if (config === undefined) return 0;

        const prices = value.productData[0].prices;

        return SpecialDealUtils.findPriceForConfiguration(config, prices).price;
    }

    public static findPriceForConfiguration(config: IDynamicConfig, datasource: IConfigPrice[]): IConfigPrice {
        let prices = datasource;
        Object.keys(config).forEach((property) => {
            prices = prices.filter((price: IConfigPrice) => {
                return price.config[property] === config[property];
            });
        });

        if (prices.length > 0) {
            return prices[0];
        } else {
            throw new Error('No prices defined for config and product');
        }
    }

    public static findStockForConfiguration(config: IDynamicConfig, stocks: IConfigStock[]): IConfigStock {
        Object.keys(config).forEach((property) => {
            stocks = stocks.filter((stock: IConfigStock) => {
                return stock.config[property] === config[property];
            });
        });

        if (stocks.length > 0) {
            return stocks[0];
        } else {
            throw new Error('No stock defined for config and stockconfig');
        }
    }
}
