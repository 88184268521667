<img
    class="news-deal-image"
    [ngClass]="{ 'news-deal-image-with-no-background': imageType === imageTypes.NO_BG_IMAGE }"
    fetchpriority="high"
    src="{{ imageUrl }}"
    alt="news-image"
/>
<div class="news-deal-text-section">
    <div class="news-deal-text" [innerHtml]="text | markdownToSafeHTML"></div>
</div>
