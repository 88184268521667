import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/tsd-project-shop/src/environments/environment';
import type { Observable } from 'rxjs';
import type { SavePageViewRequest } from '../../domain/models/save-page-view-request.interface';
import type { SavePageViewResponse } from '../../domain/models/save-page-view-response';
import type { IAnalyticsService } from '../../domain/outbound/analytics.service';
@Injectable()
export class AnalyticsService implements IAnalyticsService {
    private readonly ANALYTICS_ENDPOINT_BASE_URL = environment.backendUrl + `analytics/pageview`;

    constructor(private readonly http: HttpClient) {}

    savePageView(body: SavePageViewRequest): Observable<SavePageViewResponse> {
        return this.http.post<SavePageViewResponse>(this.ANALYTICS_ENDPOINT_BASE_URL, body);
    }
}
