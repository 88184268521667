import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'projects/tsd-project-shop/src/environments/environment';
import type { Observable } from 'rxjs';
import type { IStartSessionRequest } from '../../domain/models/start-session-request.interface';
import type { ITokenResponse } from '../../domain/models/token-response.interface';
import type { ISessionService } from '../../domain/outbound/session.service';
@Injectable()
export class SessionService implements ISessionService {
    private readonly SESSION_ENDPOINT_BASE_URL = environment.backendUrl + `sessions`;

    constructor(private readonly http: HttpClient) {}

    getNewAccessToken(): Observable<ITokenResponse> {
        const url = `${this.SESSION_ENDPOINT_BASE_URL}/token`;
        return this.http.get<ITokenResponse>(url);
    }

    startSession(data: IStartSessionRequest): Observable<ITokenResponse> {
        const url = `${this.SESSION_ENDPOINT_BASE_URL}/start`;
        return this.http.post<ITokenResponse>(url, data);
    }
}
