import { NgModule } from '@angular/core';
import type { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';
import { CampaignAccessoryListComponent } from './adapters/view/pages/campaign-accessory-list/campaign-accessory-list.component';
import { CampaignDetailPageComponent } from './adapters/view/pages/campaign-detail-page/campaign-detail-page.component';
import { CampaignOrderSummaryComponent } from './adapters/view/pages/campaign-order-summery/campaign-order-summery.component';
import { CampaignShippingComponent } from './adapters/view/pages/campaign-shipping/campaign-shipping.component';
import { ContactComponent } from './adapters/view/pages/contact/contact.component';
import { DiscountOfferDetailPageComponent } from './adapters/view/pages/discount-offer-detail-page/discount-offer-detail-page.component';
import { DiscountOfferListComponent } from './adapters/view/pages/discount-offer-list/discount-offer-list.component';
import { GeneralErrorComponent } from './adapters/view/pages/general-error/general-error.component';
import { HomeComponent } from './adapters/view/pages/home/home.component';
import { LoginComponent } from './adapters/view/pages/login/login.component';
import { MyOrdersComponent } from './adapters/view/pages/my-orders/my-orders.component';
import { MyProfileComponent } from './adapters/view/pages/my-profile/my-profile.component';
import { NewsletterComponent } from './adapters/view/pages/newsletter/newsletter.component';
import { OneTimeUrlComponent } from './adapters/view/pages/one-time-url/one-time-url.component';
import { OrderDetailsComponent } from './adapters/view/pages/order-details/order-details.component';
import { SuperCashbackComponent } from './adapters/view/pages/super-cashback/super-cashback.component';
import { UpcomingDealsComponent } from './adapters/view/pages/upcoming-deals/upcoming-deals.component';
import { FrequentlyAskedQuestionsComponent } from './adapters/view/pure/frequently-asked-questions/frequently-asked-questions.component';
import { ZoomComponent } from './adapters/view/pure/zoom/zoom.component';

import { getLanguage } from './domain/utils/language';

const routes: Routes = [
    {
        path: ':language',
        component: HomeComponent,
    },
    {
        path: ':language/myprofile',
        component: MyProfileComponent,
    },
    {
        path: ':language/myorders',
        component: MyOrdersComponent,
    },
    {
        path: ':language/myorders/:id',
        component: OrderDetailsComponent,
    },
    {
        path: ':language/newsletter',
        component: NewsletterComponent,
    },
    {
        path: ':language/contact',
        component: ContactComponent,
    },
    {
        path: ':language/faq',
        component: FrequentlyAskedQuestionsComponent,
    },
    {
        path: ':language/supercashback',
        component: SuperCashbackComponent,
    },
    {
        path: ':language/upcomingdeals',
        component: UpcomingDealsComponent,
    },
    {
        path: ':language/discountoffers/:discountOffer/:id',
        component: DiscountOfferDetailPageComponent,
    },
    {
        path: ':language/discountoffers',
        component: DiscountOfferListComponent,
    },
    {
        path: ':language/campaign/:campaignName/:id/accessories',
        component: CampaignAccessoryListComponent,
    },
    {
        path: ':language/campaign/:campaignName/:id/ordersummary',
        component: CampaignOrderSummaryComponent,
    },
    {
        path: ':language/campaign/:campaignName/:id/shipping',
        component: CampaignShippingComponent,
    },
    {
        path: ':language/campaign/:campaignName/:id/zoom',
        component: ZoomComponent,
    },
    {
        path: ':language/campaign/:campaignName/:id',
        component: CampaignDetailPageComponent,
    },
    {
        path: ':language/login',
        component: LoginComponent,
    },
    {
        path: ':language/error',
        component: GeneralErrorComponent,
    },
    {
        path: ':language/:urltoken',
        component: OneTimeUrlComponent,
    },
    { path: '', redirectTo: `/${getLanguage()}`, pathMatch: 'full' },
    {
        path: '**',
        component: LoginComponent,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
