<app-loading-spinner *ngIf="isUpdateOrderLoading$ | async; else showContentsOfThePage" />
<ng-template #showContentsOfThePage>
    <!-- page header -->
    <app-page-header
        [title]="'tsd_campaignAccessoryList_title' | localize | async"
        (backButtonClick)="goBack()"
    ></app-page-header>

    <!-- list of accessories -->
    <div class="list-container">
        <app-campaign-accessory-item
            *ngFor="let item of items"
            [item]="item"
            (addToCart)="onAddToCart($event)"
            (removeFromCart)="onRemoveFromCart($event)"
        />
    </div>

    <!-- checkout button -->
    <app-campaign-button
        [label]="'tsd_campaignButton_BuyNow' | localize | async"
        [data]="buttonData"
        (buyNowEvent)="buyNow($event)"
        [disabled]="(isUpdateOrderLoading$ | async) ?? false"
        [fromAccessoryPage]="true"
    ></app-campaign-button>
</ng-template>
