<app-loading-spinner
    *ngIf="
        (!shopSettings?.campaignDealSectionDisabled && (isCampaignsLoading$ | async)) ||
            (isSuperCashBackLoading$ | async) ||
            (isUpcomingDealLoading$ | async) ||
            (isDiscountOffersLoading$ | async);
        else data
    "
/>

<ng-template #data>
    <!-- page header -->
    <app-header
        [isSuperCashbackVisible]="superCashBackDisplayStatus"
        [isUpcomingDealVisible]="upcomingDealDisplayStatus"
    />

    <app-news-ticker
        *ngIf="displayNewsTicker"
        [text]="newsTickerText"
        [type]="newsTickerType"
        (closeButtonClicked)="closeNewsTicker()"
    />

    <div class="home" *ngIf="shopSettings !== null && shopSettings !== undefined">
        <!-- deal of the day -->
        <div class="deals-of-the-day" *ngIf="dealsOfTheDay.length > zeroLength">
            <app-deal-of-the-day [dealsOfTheDay]="dealsOfTheDay" [icon]="shopSettings.dealOfTheDayIcon" />
        </div>

        <div class="home-sections">
            <!-- discount offers -->
            <div
                class="discount-offer"
                [style.order]="shopSettings.shopLayoutOrder === shopLayoutOrder.DISCOUNT_OFFERS ? 1 : 2"
                *ngIf="!shopSettings.affiliateSectionDisabled && discountOffersImageSlider.length > zeroLength"
            >
                <div class="discount-offer-title">
                    <img src="assets/coupons.svg" alt="coupons-icon" class="discount-offer-title-icon" />
                    <div>
                        <p>{{ 'tsd_shop_affiliate_deals' | localize | async }}</p>
                    </div>
                </div>

                <app-discount-offer-image-slider
                    *ngIf="shopSettings.affiliateDealsDesign !== affiliateDealsDesign.ROWS"
                    [discountOffers]="discountOffersImageSlider"
                />

                <app-discount-offer-image-grid
                    *ngIf="shopSettings.affiliateDealsDesign === affiliateDealsDesign.ROWS"
                    [discountOffers]="discountOffersImageSlider"
                    [hasShowMoreButton]="true"
                />
            </div>

            <!-- categories -->
            <!-- list of categories campaigns -->
            <div
                class="categories-with-campaigns"
                [style.order]="shopSettings.shopLayoutOrder === shopLayoutOrder.PRODUCT_DEALS ? 1 : 2"
                *ngIf="!shopSettings.campaignDealSectionDisabled && categoriesWithCampaigns.length > zeroLength"
            >
                <app-campaign-list [categoriesWitCampaigns]="categoriesWithCampaigns" />
            </div>
        </div>

        <!-- scroll to top -->
        <div class="home-bottom">
            <div class="note">{{ 'tsd_home_note' | localize | async }}</div>
            <div class="back-to-top" (click)="scrollToTop()">
                <img class="arrow" src="assets/chevron-down.svg" alt="up-arrow" />
                <span>{{ 'tsd_home_back_to_top' | localize | async }}</span>
            </div>
        </div>
    </div>
</ng-template>
