import { Component, Input } from '@angular/core';
import { VisualTypes } from '@modeso/types__tsd-lib-products-be';
import { SWISS_CURRENCY } from '../../../../domain/constants/constants';

export interface ProductSummary {
    imageUrl: string;
    imageType: VisualTypes.IMAGE | VisualTypes.NO_BG_IMAGE;
    brandName: string;
    productName: string;
    quantity: number;
    price: number;
    options: string[];
}
@Component({
    selector: 'app-campaign-order-basic-information',
    templateUrl: './campaign-order-basic-information.component.html',
    styleUrls: ['./campaign-order-basic-information.component.scss'],
})
export class CampaignOrderBasicInformationComponent {
    @Input({ required: true }) items!: ProductSummary[];
    CURRENCY = SWISS_CURRENCY;
    protected readonly imageTypes = VisualTypes;
    readonly placeHolderImage = 'assets/product-placeholder.png';

    loadPlaceholderImage(item: ProductSummary): void {
        item.imageUrl = this.placeHolderImage;
    }
}
