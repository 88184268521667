import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs';
import { IProductService, PRODUCTS_SERVICE } from '../../outbound/product.service';
import { MerchantActions } from '../actions/action-types';

@Injectable()
export class MerchantEffects {
    constructor(
        private readonly actions$: Actions,
        @Inject(PRODUCTS_SERVICE) private readonly productService: IProductService,
    ) {}

    loadMerchants$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MerchantActions.LoadMerchants),
            switchMap(() => this.productService.getMerchants()),
            map((merchants) => MerchantActions.merchantsLoaded(merchants)),
        ),
    );
}
