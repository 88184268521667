import type { IPersonDto } from '@modeso/types__tsd-lib-user-be';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { UserProfileActions } from '../actions/action-types';

export interface UserProfileState {
    userProfile: IPersonDto | undefined;
}

const initialUserProfileState: UserProfileState = { userProfile: undefined };

const userProfileReducer = createReducer(
    initialUserProfileState,

    on(
        UserProfileActions.userProfileCreated,
        (state, action) => ({ ...state, userProfile: action.userProfile }) satisfies UserProfileState,
    ),

    on(
        UserProfileActions.userProfileUpdated,
        (state, action) => ({ ...state, userProfile: action.userProfile }) satisfies UserProfileState,
    ),

    on(
        UserProfileActions.userProfileDeleted,
        (state) => ({ ...state, userProfile: undefined }) satisfies UserProfileState,
    ),

    on(
        UserProfileActions.userProfileLoaded,
        (state, action) => ({ ...state, userProfile: action.userProfile }) satisfies UserProfileState,
    ),

    on(UserProfileActions.newsletterStatusUpdated, (state, action) => {
        let userProfile: IPersonDto | undefined;

        if (state.userProfile != null) {
            userProfile = { ...state.userProfile };
            userProfile.email = action.payload.email;
            userProfile.receiveEmails = action.payload.receiveEmails;
        }

        return { ...state, userProfile } satisfies UserProfileState;
    }),
);

const userProfileFeature = createFeature({
    name: 'userProfile',
    reducer: userProfileReducer,
    extraSelectors: ({ selectUserProfile }) => ({
        selectUserEmail: createSelector(selectUserProfile, (userProfile: IPersonDto | undefined) => userProfile?.email),
        selectNewsletterStatus: createSelector(
            selectUserProfile,
            (userProfile: IPersonDto | undefined) => userProfile?.receiveEmails,
        ),
    }),
});

export const { name, reducer, selectUserProfile, selectUserEmail, selectNewsletterStatus } = userProfileFeature;
