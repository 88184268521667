import type { SpecialDealDTO } from '@modeso/types__tsd-lib-products-be';
import { createAction } from '@ngrx/store';
import { CampaignPendingEnum, PrefixEnum } from './action-names.enum';

export const loadCampaigns = createAction(
    '[Campaign] Load Campaigns',
    (pending: string = `${PrefixEnum.BEGIN}_${CampaignPendingEnum.LOAD_CAMPAIGNS}`) => ({ pending }),
);

export const campaignsLoaded = createAction(
    '[Campaign] Campaign Loaded',
    (campaigns: SpecialDealDTO[], pending: string = `${PrefixEnum.END}_${CampaignPendingEnum.LOAD_CAMPAIGNS}`) => ({
        pending,
        campaigns,
    }),
);

export const loadCampaignById = createAction(
    '[Campaign] Load Campaign by id',
    (id: string, pending: string = `${PrefixEnum.BEGIN}_${CampaignPendingEnum.LOAD_CAMPAIGN}`) => ({ id, pending }),
);

export const campaignByIdLoaded = createAction(
    '[Campaign] Campaign by id Loaded',
    (campaign: SpecialDealDTO, pending: string = `${PrefixEnum.END}_${CampaignPendingEnum.LOAD_CAMPAIGN}`) => ({
        pending,
        campaign,
    }),
);
