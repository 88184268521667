<mat-toolbar>
    <div [ngStyle]="{ color: menuIsOpen ? 'white' : 'black' }" class="header">
        {{ 'tsd_nav_superdeal_title' | localize | async }}
    </div>

    <app-menu
        (menuIsOpen)="menuIsOpenAction($event)"
        [isSuperCashbackVisible]="isSuperCashbackVisible"
        [isUpcomingDealVisible]="isUpcomingDealVisible"
    />
</mat-toolbar>
