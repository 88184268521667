import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs';
import { IProductService, PRODUCTS_SERVICE } from '../../outbound/product.service';
import { NavigationService } from '../../services/navigate.service';
import { CampaignActions } from '../actions/action-types';

@Injectable()
export class CampaignEffects {
    constructor(
        private readonly actions$: Actions,
        @Inject(PRODUCTS_SERVICE) private readonly productService: IProductService,
        private readonly navigationService: NavigationService,
    ) {}

    loadCampaigns$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CampaignActions.loadCampaigns),
            switchMap(() =>
                this.productService.getCampaigns().pipe(
                    map((response) => CampaignActions.campaignsLoaded(response.campaigns)),
                    catchError(() => this.navigationService.navigateToErrorPage()),
                ),
            ),
        ),
    );

    loadCampaignById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CampaignActions.loadCampaignById),
            switchMap((action) =>
                this.productService.getCampaignByCampaignId(action.id).pipe(
                    map((campaign) => CampaignActions.campaignByIdLoaded(campaign)),
                    catchError(() => this.navigationService.navigateToErrorPage()),
                ),
            ),
        ),
    );
}
