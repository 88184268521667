import { Component, Input } from '@angular/core';
import { VisualTypes } from '@modeso/types__tsd-lib-products-be';

@Component({
    selector: 'app-discount-offer-image',
    templateUrl: './discount-offer-image.component.html',
    styleUrls: ['./discount-offer-image.component.scss'],
})
export class DiscountOfferImageComponent {
    @Input({ required: true }) discount!: number;
    @Input({ required: true }) imageUrl!: string;
    @Input({ required: true })
    imageType!: VisualTypes.IMAGE | VisualTypes.NO_BG_IMAGE;
    @Input({ required: true }) logoUrl!: string;
    @Input({ required: true }) discountUnit!: string;
    @Input() circularBubbleColorHexCode: string = 'unset';

    protected readonly imageTypes = VisualTypes;
}
