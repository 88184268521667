<div class="discount-offer-list">
    <div
        class="discount-offer-deal"
        *ngFor="let discountOffer of discountOffersToView"
        (click)="onSlideClicked(discountOffer.id)"
    >
        <div class="image-chip">-{{ discountOffer.discount }}{{ discountOffer.discountUnit }}</div>
        <div class="bubble" [ngStyle]="{ 'background-color': discountOffer.circularBubbleRgbaColor }">
            <img class="discount-offer-logo" fetchpriority="high" src="{{ discountOffer.logoUrl }}" alt="logo-url" />
        </div>
        <img
            class="discount-offer-deal-image"
            fetchpriority="high"
            src="{{ discountOffer.imageUrl }}"
            alt="discount-offer-image"
        />
    </div>
    <div *ngIf="hasShowMoreButton && discountOffers.length > MAX_OFFERS_TO_SHOW">
        <div class="discount-offer-deal show-more-box" (click)="onShowMoreClicked()">
            <div class="bubble">
                <span class="show-more-amount"
                    >+{{ discountOffers.length - MAX_OFFERS_TO_SHOW_WITH_SHOW_MORE_BUTTON }}</span
                >
            </div>
        </div>
    </div>
</div>
