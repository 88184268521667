<app-loading-spinner
    *ngIf="isCheckOutOrderLoading$ | async; else showContentsOfThePage"
    style="margin: auto"
></app-loading-spinner>

<ng-template #showContentsOfThePage>
    <!-- page header -->
    <app-page-header
        [title]="'tsd_checkout_title' | localize | async"
        (backButtonClick)="goBack()"
        *ngIf="!thankIsVisible"
    ></app-page-header>

    <div class="order-summary" [hidden]="thankIsVisible">
        <!-- order summary -->
        <app-campaign-order-basic-information [items]="items" />

        <div class="divider"></div>

        <!-- shipping address -->
        <div class="delivery">
            <div class="row1">
                <div class="deliver-to">{{ 'tsd_deliver_to' | localize | async }}</div>
                <div class="edit" (click)="goBack()">{{ 'tsd_edit' | localize | async }}</div>
            </div>
            <div class="row2">
                <div class="address-name">
                    {{ currentPurchaseOrder?.shippingAddress?.forename }}
                    {{ currentPurchaseOrder?.shippingAddress?.lastname }}
                </div>
                <div class="address-street">{{ currentPurchaseOrder?.shippingAddress?.street }}</div>
                <div class="address-zipcode-city">
                    {{ currentPurchaseOrder?.shippingAddress?.zipcode }}
                    {{ currentPurchaseOrder?.shippingAddress?.city }}
                </div>
                <div class="address-email">{{ currentPurchaseOrder?.email }}</div>
            </div>
        </div>

        <!-- billing address -->
        <div class="delivery invoice-address">
            <div class="row1">
                <div class="deliver-to">{{ 'tsd_billing_address' | localize | async }}</div>
            </div>
            <div class="row2">
                <div class="address-name">
                    {{ currentPurchaseOrder?.billingAddress?.forename }}
                    {{ currentPurchaseOrder?.billingAddress?.lastname }}
                </div>
                <div class="address-street">{{ currentPurchaseOrder?.billingAddress?.street }}</div>
                <div class="address-zipcode-city">
                    {{ currentPurchaseOrder?.billingAddress?.zipcode }}
                    {{ currentPurchaseOrder?.billingAddress?.city }}
                </div>
                <div class="address-email">{{ currentPurchaseOrder?.email }}</div>
            </div>
        </div>

        <div class="delivery-info" *ngIf="deliveryInformation">
            <div class="delivery-info-title">{{ 'tsd_delivery_information' | localize | async }}</div>
            <div class="delivery-info-text" [innerHTML]="deliveryInformation | markdownToSafeHTML"></div>
        </div>

        <div class="coupon" *ngIf="(couponDisplayStatus$ | async) && orderCosts.subtotalAmount !== 0">
            <div class="divider" *ngIf="orderCosts.subtotalAmount !== 0"></div>
            <div class="coupon-container">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'tsd_coupon_title' | localize | async }}</mat-label>
                    <input
                        matInput
                        type="text"
                        id="discountCode"
                        [(ngModel)]="couponCode"
                        (ngModelChange)="onCouponFieldChange()"
                    />
                </mat-form-field>
                <button
                    class="coupon-apply"
                    (click)="couponApply()"
                    [disabled]="isValidCoupon || couponCode.length < 6 || (isApplyCouponLoading$ | async)"
                >
                    {{ 'tsd_coupon_use_button' | localize | async }}
                </button>
            </div>
            <mat-error class="error-text" *ngIf="isInvalidCouponError">
                {{ 'tsd_coupon_invalid_msg' | localize | async }}
            </mat-error>
            <mat-error class="error-text" *ngIf="isPriceBelowMinimumCouponError">
                {{ 'tsd_coupon_price_below_minimum_msg' | localize | async }}
            </mat-error>

            <div *ngIf="isValidCoupon" class="valid-text-div">
                <span class="valid-text">
                    {{ 'tsd_coupon_success_msg' | localize | async }}
                </span>
            </div>
        </div>

        <div class="divider"></div>

        <app-campaign-order-total [orderCosts]="orderCosts" />

        <div class="button-row">
            <button (click)="orderNow()">{{ 'tsd_buy_now' | localize | async }}</button>
        </div>
    </div>

    <app-campaign-redirect-to-payment
        *ngIf="thankIsVisible"
        [redirectURL]="redirectURL"
    ></app-campaign-redirect-to-payment>
</ng-template>
