import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import type { CategoriesWithCampaigns } from '../../../../domain/models/categories-with-campaigns.interface';
import { getLanguage } from '../../../../domain/utils/language';
import { replaceSpacesWithDashes } from '../../../../domain/utils/url-with-name.utils';
import { setLastScrollPosition } from '../../pages/home/home.component';

@Component({
    selector: 'app-campaign-list',
    templateUrl: './campaign-list.component.html',
    styleUrls: ['./campaign-list.component.scss'],
})
export class CampaignListComponent {
    @Input({ required: true }) categoriesWitCampaigns: CategoriesWithCampaigns[] = [];

    constructor(private readonly router: Router) {}

    protected onCampaignClicked(id: string): void {
        const language = getLanguage();
        let campaignName = '';
        for (const category of this.categoriesWitCampaigns) {
            for (const campaign of category.campaigns) {
                if (campaign.id === id) {
                    campaignName = campaign.campaignName;
                    break;
                }
            }
        }
        const formattedName = replaceSpacesWithDashes(campaignName);

        void this.router.navigate([`${language}`, 'campaign', formattedName, id]);
        setLastScrollPosition(window.scrollY);
    }
}
