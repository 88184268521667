import type { OnDestroy, OnInit } from '@angular/core';
import { Component, Input, ViewChild } from '@angular/core';
import { YouTubePlayer } from '@angular/youtube-player';

@Component({
    selector: 'app-youtube-player',
    templateUrl: './youtube-player.component.html',
    styleUrls: ['./youtube-player.component.scss'],
})
export class YoutubePlayerComponent implements OnInit, OnDestroy {
    @Input() videoId!: string;

    private tag!: HTMLScriptElement;
    isPlaying = false; // Track if the video is playing

    @ViewChild(YouTubePlayer) protected player: YouTubePlayer | undefined;

    ngOnInit(): void {
        this.tag = document.createElement('script');

        this.tag.src = 'https://www.youtube.com/iframe_api';
        document.body.appendChild(this.tag);
    }

    public pauseVideo(): void {
        this.isPlaying = false;
        this.player?.pauseVideo();
    }

    ngOnDestroy(): void {
        document.body.removeChild(this.tag);
    }

    // Method to toggle video play/pause
    togglePlay(event: Event): void {
        event.stopPropagation();
        if (!this.isPlaying) {
            this.player?.playVideo();
        } else {
            this.player?.pauseVideo();
        }
        this.isPlaying = !this.isPlaying;
    }

    onStateChange(event: YT.OnStateChangeEvent): void {
        /*if (event.data === YT.PlayerState.PAUSED) {
            this.isPlaying = false;
        } else if (event.data === YT.PlayerState.PLAYING) {
            this.isPlaying = true;
        }*/
    }
}
