import type { OnInit } from '@angular/core';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AnalyticsPageName } from '../../../../domain/models/enums/page-name.enum';
import { AnalyticsActions, MerchantActions } from '../../../../domain/store/actions/action-types';
import type { AppState } from '../../../../domain/store/reducers/app.reducer';
import { selectMerchants } from '../../../../domain/store/reducers/merchant.reducer';
import { getFromSessionStorage } from '../../../../domain/utils/storage';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
    private readonly store = inject<Store<AppState>>(Store<AppState>);
    private readonly router = inject(Router);
    private readonly destroyRef = inject(DestroyRef);

    merchants$ = this.store.select(selectMerchants).pipe(takeUntilDestroyed(this.destroyRef));

    ngOnInit(): void {
        // this.merchantsObject = [...this.contactState.merchants];
        this.store.dispatch(MerchantActions.LoadMerchants());
        this.store.dispatch(
            AnalyticsActions.savePageView({
                payload: {
                    productLine: null,
                    pageName: AnalyticsPageName.CONTACT,
                    terminalId: getFromSessionStorage('TERMINAL_ID') ?? '',
                },
            }),
        );
    }

    onBackClicked(): void {
        void this.router.navigate(['../']);
    }
}
