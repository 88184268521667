import { Component, Input, type OnInit } from '@angular/core';
import { Router } from '@angular/router';
import type { DiscountOfferImageSlider } from '../../../../domain/models/discount-offer-image-slider.interface';
import { getLanguage } from '../../../../domain/utils/language';
import { replaceSpacesWithDashes } from '../../../../domain/utils/url-with-name.utils';

const MAX_OFFERS_TO_SHOW = 6;
const MAX_OFFERS_TO_SHOW_WITH_SHOW_MORE_BUTTON = 5;

@Component({
    selector: 'app-discount-offer-image-grid',
    templateUrl: './discount-offer-image-grid.component.html',
    styleUrls: ['./discount-offer-image-grid.component.scss'],
})
export class DiscountOfferImageGridComponent implements OnInit {
    @Input({ required: true }) discountOffers: DiscountOfferImageSlider[] = [];
    protected discountOffersToView: DiscountOfferImageSlider[] = [];
    @Input() hasShowMoreButton = false;

    readonly MAX_OFFERS_TO_SHOW = MAX_OFFERS_TO_SHOW;
    readonly MAX_OFFERS_TO_SHOW_WITH_SHOW_MORE_BUTTON = MAX_OFFERS_TO_SHOW_WITH_SHOW_MORE_BUTTON;

    constructor(private readonly router: Router) {}

    protected onSlideClicked(id: string): void {
        const language = getLanguage();
        const discountOfferBrandName = this.discountOffers.find((offer) => offer.id === id)?.brand;
        const formattedName = replaceSpacesWithDashes(discountOfferBrandName);
        void this.router.navigate([`${language}`, 'discountoffers', formattedName, id]);
    }
    protected onShowMoreClicked(): void {
        const language = getLanguage();
        void this.router.navigate([`${language}`, 'discountoffers']);
    }

    ngOnInit(): void {
        if (this.hasShowMoreButton && this.discountOffers.length > MAX_OFFERS_TO_SHOW) {
            this.discountOffersToView = this.discountOffers.slice(0, MAX_OFFERS_TO_SHOW_WITH_SHOW_MORE_BUTTON);
        } else {
            this.discountOffersToView = this.discountOffers;
        }
    }
}
