import type { OnInit } from '@angular/core';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import type {
    INewstickerDTO,
    IShopSettingsModel,
    PublishedDealOfTheDayDto,
    SpecialDealDTO,
} from '@modeso/types__tsd-lib-products-be';
import {
    AffiliateDealsDesign,
    DealOfTheDayType,
    NewstickerTypes,
    ShopLayoutOrder,
} from '@modeso/types__tsd-lib-products-be';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import fastDeepEqual from 'fast-deep-equal/es6';
import { combineLatest, combineLatestWith, distinctUntilChanged, filter, first } from 'rxjs';
import { BLUE, STRING_FALSE, STRING_TRUE, ZERO_LENGTH } from '../../../../domain/constants/constants';
import type { CategoriesWithCampaigns } from '../../../../domain/models/categories-with-campaigns.interface';
import type { DealOfTheDay } from '../../../../domain/models/deal-of-the-day.interface';
import type { DiscountOfferImageSlider } from '../../../../domain/models/discount-offer-image-slider.interface';
import { ErrorActions, OrderActions } from '../../../../domain/store/actions/action-types';
import { savePageView } from '../../../../domain/store/actions/analytics.actions';
import { loadCampaigns } from '../../../../domain/store/actions/campaign.actions';
import {
    loadDealsOfTheDay,
    loadDiscountOffers,
    loadNewsTicker,
    loadShopSettings,
    loadSuperCashBack,
    loadUpcomingDeal,
} from '../../../../domain/store/actions/home.actions';
import type { AppState } from '../../../../domain/store/reducers/app.reducer';
import { selectCampaigns } from '../../../../domain/store/reducers/campaign.reducer';
import {
    selectDealsOfTheDay,
    selectDiscountOffers,
    selectNewsTicker,
    selectShopSettings,
    selectSuperCashBackDisplayStatus,
    selectUpcomingDealDisplayStatus,
} from '../../../../domain/store/reducers/home.reducer';
import {
    isCampaignsLoading,
    isDiscountOffersLoading,
    isSuperCashBackLoading,
    isUpcomingDealLoading,
} from '../../../../domain/store/reducers/pending.reducer';
import { DiscountOffersUtils } from '../../../../domain/utils/discount-offers.utils';
import { getLanguage } from '../../../../domain/utils/language';
import {
    getFromSessionStorage,
    removeFromSessionStorage,
    saveToSessionStorage,
} from '../../../../domain/utils/storage';
import { CampaignMapper } from './campaigns.mapper';
import { DealOfTheDayMapper } from './deal-of-the-day.mapper';

const CAMPAIGN_INDEX = 0;
const DISCOUNT_OFFERS_INDEX = 0;
let LAST_SCROLL_POSITION = 0;
export function setLastScrollPosition(scrollPosition: number): void {
    LAST_SCROLL_POSITION = scrollPosition;
}

@UntilDestroy()
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
    private readonly destroyRef = inject(DestroyRef);
    private readonly language = getLanguage();

    protected newsTickerText: string | undefined;
    protected newsTickerType: NewstickerTypes | undefined;
    protected discountOffersImageSlider: DiscountOfferImageSlider[] = [];
    protected upcomingDealDisplayStatus = false;
    protected superCashBackDisplayStatus = false;
    protected categoriesWithCampaigns: CategoriesWithCampaigns[] = [];
    protected displayNewsTicker = false;
    protected dealsOfTheDay: DealOfTheDay[] = [];
    protected shopSettings: IShopSettingsModel | undefined;
    protected shopLayoutOrder = ShopLayoutOrder;
    protected affiliateDealsDesign = AffiliateDealsDesign;

    protected isCampaignsLoading$ = this.store.select(isCampaignsLoading).pipe(takeUntilDestroyed(this.destroyRef));
    protected isSuperCashBackLoading$ = this.store
        .select(isSuperCashBackLoading)
        .pipe(takeUntilDestroyed(this.destroyRef));
    protected isUpcomingDealLoading$ = this.store
        .select(isUpcomingDealLoading)
        .pipe(takeUntilDestroyed(this.destroyRef));
    protected isDiscountOffersLoading$ = this.store
        .select(isDiscountOffersLoading)
        .pipe(takeUntilDestroyed(this.destroyRef));
    protected zeroLength = ZERO_LENGTH;

    constructor(
        private readonly store: Store<AppState>,
        private readonly campaignMapper: CampaignMapper,
        private readonly dealOfTheDayMapper: DealOfTheDayMapper,
    ) {}

    ngOnInit(): void {
        this.store.dispatch(ErrorActions.clearError());
        this.store.dispatch(OrderActions.resetPurchaseInformation());
        removeFromSessionStorage('CURRENT_PURCHASE_ORDER');

        const isNewsStickerNotDisplayedBefore = this.isNewsTickerNotDisplayedBefore();

        if (isNewsStickerNotDisplayedBefore) {
            this.dispatchNewsTicker();
        }
        this.dispatchRequests();
        this.selectDataFromStore();
    }

    private dispatchRequests(): void {
        this.store.dispatch(loadSuperCashBack());
        this.store.dispatch(loadUpcomingDeal());
        this.store.dispatch(loadDealsOfTheDay());
        this.store.dispatch(loadShopSettings());

        this.dispatchViewRequest();
    }

    private selectDataFromStore(): void {
        this.store
            .select(selectUpcomingDealDisplayStatus)
            .pipe(
                first((upcomingDealDisplayStatus) => upcomingDealDisplayStatus != null),
                untilDestroyed(this),
            )
            .subscribe((upcomingDealDisplayStatus) => this.setUpcomingDealDisplayStatus(upcomingDealDisplayStatus));

        this.store
            .select(selectSuperCashBackDisplayStatus)
            .pipe(
                first((superCashBackDisplayStatus) => superCashBackDisplayStatus != null),
                untilDestroyed(this),
            )
            .subscribe((superCashBackDisplayStatus) => this.setSuperCashBackDisplayStatus(superCashBackDisplayStatus));

        const campaigns$ = this.store.select(selectCampaigns).pipe(
            filter((campaigns) => campaigns != null && campaigns.length > ZERO_LENGTH),
            distinctUntilChanged((previousCampaigns, currentCampaigns) =>
                fastDeepEqual(previousCampaigns, currentCampaigns),
            ),
            untilDestroyed(this),
        );

        const discountOffers$ = this.store.select(selectDiscountOffers).pipe(
            filter((discountOffers) => discountOffers != null && discountOffers.length > ZERO_LENGTH),
            distinctUntilChanged((previousDiscountOffers, currentDiscountOffers) =>
                fastDeepEqual(previousDiscountOffers, currentDiscountOffers),
            ),
            untilDestroyed(this),
        );

        const dealsOfTheDays$ = this.store.select(selectDealsOfTheDay).pipe(
            filter((dealsOfTheDay) => dealsOfTheDay != null),
            distinctUntilChanged((previousDealOfTheDay, currentDealOfTheDay) =>
                fastDeepEqual(previousDealOfTheDay, currentDealOfTheDay),
            ),
            untilDestroyed(this),
        );

        campaigns$
            .pipe(
                combineLatestWith(dealsOfTheDays$),
                filter((combination) => combination[CAMPAIGN_INDEX].length > ZERO_LENGTH),
                distinctUntilChanged((previousCombination, currentCombination) =>
                    fastDeepEqual(previousCombination, currentCombination),
                ),
                untilDestroyed(this),
            )
            .subscribe(([campaigns, dealsOfTheDay]) => this.setCampaignsBasedOnDealsOfTheDay(campaigns, dealsOfTheDay));

        discountOffers$
            .pipe(
                combineLatestWith(dealsOfTheDays$),
                filter((combination) => combination[DISCOUNT_OFFERS_INDEX].length > ZERO_LENGTH),
                distinctUntilChanged((previousCombination, currentCombination) =>
                    fastDeepEqual(previousCombination, currentCombination),
                ),
                untilDestroyed(this),
            )
            .subscribe(
                ([discountOffers, dealsOfTheDay]) =>
                    (this.discountOffersImageSlider =
                        DiscountOffersUtils.getDiscountOffersImageSliderBasedOnDealsOfTheDay(
                            discountOffers,
                            dealsOfTheDay,
                        )),
            );

        dealsOfTheDays$.subscribe((dealsOfTheDay) => this.setDealsOfTheDay(dealsOfTheDay));

        this.store
            .select(selectShopSettings)
            .pipe(
                first((shopSettings) => shopSettings != null),
                untilDestroyed(this),
            )
            .subscribe((shopSettings) => this.setShopSettings(shopSettings));
    }

    private isNewsTickerNotDisplayedBefore(): boolean {
        const newsTickerDisplayConditionInSessionStorage = getFromSessionStorage('IS_NEWS_TICKER_CLOSED');
        if (newsTickerDisplayConditionInSessionStorage == null) {
            return false;
        }
        const newsTickerDisplayConditionInSessionStorageLowerCase =
            newsTickerDisplayConditionInSessionStorage.toLocaleLowerCase();
        return newsTickerDisplayConditionInSessionStorageLowerCase === STRING_FALSE;
    }

    private dispatchDiscountOffers(): void {
        this.store.dispatch(loadDiscountOffers());
    }

    private dispatchCampaigns(): void {
        this.store.dispatch(loadCampaigns());
    }

    private dispatchNewsTicker(): void {
        this.store.dispatch(loadNewsTicker());

        this.store
            .select(selectNewsTicker)
            .pipe(
                first((newsTicker) => newsTicker != null),
                untilDestroyed(this),
            )
            .subscribe((newsTicker) => this.setNewsTicker(newsTicker));
    }

    private dispatchViewRequest(): void {
        const terminalId = getFromSessionStorage('TERMINAL_ID');

        if (terminalId == null) {
            throw new Error('Terminal id is null');
        }
        const viewRequest = savePageView({
            payload: {
                productLine: null,
                pageName: 'HOME',
                terminalId: terminalId,
            },
        });
        this.store.dispatch(viewRequest);
    }

    private setNewsTicker(newsTicker: INewstickerDTO | undefined): void {
        if (newsTicker == null) {
            throw new Error('NewsTicker is undefined');
        }

        this.newsTickerText = newsTicker.text;
        this.newsTickerType = newsTicker.type.toString() === BLUE ? NewstickerTypes.BLUE : NewstickerTypes.BLACK;
        this.displayNewsTicker = true;
    }

    private setUpcomingDealDisplayStatus(upcomingDealDisplayStatus: boolean | undefined): void {
        if (upcomingDealDisplayStatus == null) {
            throw new Error('Upcoming Deal Display Status is undefined');
        }

        this.upcomingDealDisplayStatus = upcomingDealDisplayStatus;
    }

    private setSuperCashBackDisplayStatus(superCashBackDisplayStatus: boolean | undefined): void {
        if (superCashBackDisplayStatus == null) {
            throw new Error('Super Cash Back Display Status is undefined');
        }

        this.superCashBackDisplayStatus = superCashBackDisplayStatus;
    }

    private setCampaignsBasedOnDealsOfTheDay(
        campaigns: SpecialDealDTO[],
        dealsOfTheDay: PublishedDealOfTheDayDto[],
    ): void {
        const dealsWithCampaignsToHideFromCampaignList = dealsOfTheDay.filter(
            (dealOfTheDay) => !dealOfTheDay.showInList && dealOfTheDay.type === DealOfTheDayType.CAMPAIGN,
        );

        const internalCampaignIdOfCampaignsToHidFromCampaignList = dealsWithCampaignsToHideFromCampaignList.map(
            (dealOfTheDay) => (dealOfTheDay.deal as SpecialDealDTO).internalCampaignId,
        );

        let campaignsToDisplay = campaigns;

        if (internalCampaignIdOfCampaignsToHidFromCampaignList.length > ZERO_LENGTH) {
            campaignsToDisplay = campaigns.filter(
                (campaign) => !internalCampaignIdOfCampaignsToHidFromCampaignList.includes(campaign.internalCampaignId),
            );
        }

        this.categoriesWithCampaigns = this.campaignMapper.mapSpecialDealDTOToCategoriesWithCampaign(
            campaignsToDisplay,
            this.language,
        );
    }

    private setShopSettings(shopSettings: IShopSettingsModel | undefined): void {
        if (shopSettings == null) {
            throw new Error('Shop settings is null or undefined');
        }

        if (!shopSettings.affiliateSectionDisabled) {
            this.dispatchDiscountOffers();
        }

        if (!shopSettings.campaignDealSectionDisabled) {
            this.dispatchCampaigns();
        }

        this.shopSettings = shopSettings;
        this.scrollToLastPosition();
    }

    private setDealsOfTheDay(dealsOfTheDay: PublishedDealOfTheDayDto[]): void {
        this.dealsOfTheDay = this.dealOfTheDayMapper.mapPublishedDealsOfTheDayDtoToDealsOfTheDay(
            dealsOfTheDay,
            this.language,
        );
    }

    protected closeNewsTicker(): void {
        saveToSessionStorage('IS_NEWS_TICKER_CLOSED', STRING_TRUE);
        this.displayNewsTicker = false;
    }

    protected scrollToTop(): void {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    private scrollToLastPosition(): void {
        // scroll after finish all loading states
        combineLatest([
            this.isCampaignsLoading$,
            this.isSuperCashBackLoading$,
            this.isUpcomingDealLoading$,
            this.isDiscountOffersLoading$,
        ])
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe(
                ([
                    isCampaignsLoadingValue,
                    isSuperCashBackLoadingValue,
                    isUpcomingDealLoadingValue,
                    isDiscountOffersLoadingValue,
                ]) => {
                    if (
                        ((this.shopSettings?.campaignDealSectionDisabled && isCampaignsLoadingValue) ||
                            !isCampaignsLoadingValue) &&
                        !isSuperCashBackLoadingValue &&
                        !isUpcomingDealLoadingValue &&
                        !isDiscountOffersLoadingValue
                    ) {
                        setTimeout(() => {
                            window.scrollTo({ top: LAST_SCROLL_POSITION, behavior: 'instant' });
                            LAST_SCROLL_POSITION = 0;
                        }, 0);
                    }
                },
            );
    }
}
