import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NewstickerTypes } from '@modeso/types__tsd-lib-products-be';

@Component({
    selector: 'app-news-ticker',
    templateUrl: './news-ticker.component.html',
    styleUrls: ['./news-ticker.component.scss'],
})
export class NewsTickerComponent {
    @Input({ required: true }) type: NewstickerTypes | undefined;
    @Input({ required: true }) text: string | undefined;
    @Output() closeButtonClicked = new EventEmitter<void>();

    protected types = NewstickerTypes;

    protected onClose(): void {
        this.closeButtonClicked.emit();
    }
}
