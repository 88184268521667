import { Injectable } from '@angular/core';

import type { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import type { Observable } from 'rxjs';

@Injectable()
export class LanguageHandler implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        // getting the language of the browser
        const browserLanguage = navigator.language;
        let headerLanguage = '';
        if (browserLanguage.includes('de')) {
            headerLanguage = 'de-ch';
        } else if (browserLanguage.includes('fr')) {
            headerLanguage = 'fr-ch';
        } else if (browserLanguage.includes('it')) {
            headerLanguage = 'it-ch';
        } else {
            headerLanguage = 'en-us';
        }

        const languageReq = request.clone({
            headers: request.headers.set('content-language', headerLanguage),
        });

        return next.handle(languageReq);
    }
}
