import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-discount-offer-information',
    templateUrl: './discount-offer-information.component.html',
    styleUrls: ['./discount-offer-information.component.scss'],
})
export class DiscountOfferInformationComponent {
    @Input({ required: true }) title!: string;
    @Input({ required: true }) description!: string;
}
