<app-loading-spinner *ngIf="displayLoadingSpinner; else showContentsOfThePage" />

<ng-template #showContentsOfThePage>
    <!-- page header -->
    <app-page-header (backButtonClick)="goBack()" />

    <!-- discount image  -->
    <app-discount-offer-image
        *ngIf="imageType != null"
        [imageUrl]="imageUrl"
        [imageType]="imageType"
        [logoUrl]="logoUrl"
        [discount]="discount"
        [discountUnit]="discountUnit"
        [circularBubbleColorHexCode]="circularBubbleColorHexCode"
    />

    <!-- general information -->
    <app-discount-offer-information [title]="title" [description]="description" />

    <!-- accordion info -->
    <app-information-accordion *ngIf="accordionData.length" [data]="accordionData" />

    <!-- checkout button -->
    <app-discount-offer-button [promoCode]="code" (visitShop)="visitShop()" />
</ng-template>
