import type {
    GroupedOrders,
    ICartOperationResponse,
    ICheckoutCartRequest,
    ICheckoutResponse,
    ICreateOrUpdateCartRequest,
    ISalesOrder,
} from '@modeso/types__tsd-lib-cart-be';
import type { CouponValidationDTO, ICodeValidityCheckRequest } from '@modeso/types__twint-lib-coupons';
import type { Observable } from 'rxjs';

export const CART_SERVICE = Symbol('CART_SERVICE');
export interface ICartService {
    createOrUpdateCart: (request: ICreateOrUpdateCartRequest) => Observable<ICartOperationResponse>;

    checkoutCart: (request: ICheckoutCartRequest) => Observable<ICheckoutResponse>;

    getOrdersGrouped: () => Observable<GroupedOrders>;

    getOrders: () => Observable<ISalesOrder[]>;

    getOrderByOrderUuid: (orderUuid: string) => Observable<ISalesOrder>;

    getCouponFeatureState: () => Observable<boolean>;

    applyCouponCodeDiscount: (body: ICodeValidityCheckRequest) => Observable<CouponValidationDTO>;
}
