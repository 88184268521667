import { Component, DestroyRef, type OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { STRING_FALSE } from '../../../../domain/constants/constants';
import { AnalyticsPageName } from '../../../../domain/models/enums/page-name.enum';
import {
    AnalyticsActions,
    CampaignActions,
    OrderActions,
    SessionActions,
} from '../../../../domain/store/actions/action-types';
import type { AppState } from '../../../../domain/store/reducers/app.reducer';
import { selectCampaigns } from '../../../../domain/store/reducers/campaign.reducer';
import { getLanguage } from '../../../../domain/utils/language';
import { removeFromStorage, saveToSessionStorage, saveToStorage } from '../../../../domain/utils/storage';

@Component({
    selector: 'app-one-time-url',
    templateUrl: './one-time-url.component.html',
    styleUrls: ['./one-time-url.component.scss'],
})
export class OneTimeUrlComponent implements OnInit {
    constructor(
        private readonly store: Store<AppState>,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        private readonly destroyRef: DestroyRef,
    ) {}

    ngOnInit(): void {
        // Initialize the session start request
        this.initSession();

        // flush the cart state (in old: this.salesOrderProvider.flush();)
        this.store.dispatch(OrderActions.resetPurchaseInformation());

        // when the session is started, redirect to the home page
        this.store
            .select((state) => state.session)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((state) => {
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                if (!state?.checkInDate && !state?.terminalId && !state?.isP2P) return;
                saveToSessionStorage('IS_NEWS_TICKER_CLOSED', STRING_FALSE);
                removeFromStorage('EMAIL');
                removeFromStorage('TWINT_TC');
                removeFromStorage('CHECKIN_DATE');

                if (state.checkInDate !== undefined) {
                    saveToStorage('CHECKIN_DATE', state.checkInDate.toString());
                }
                if (state.isP2P != undefined) {
                    saveToSessionStorage('IS_P2P', state.isP2P.toString());
                }
                if (state.email !== undefined) {
                    saveToStorage('EMAIL', state.email);
                }
                if (state.isTwintTermsAndConditionsChecked !== undefined) {
                    saveToStorage('TWINT_TC', state.isTwintTermsAndConditionsChecked.toString());
                }
                if (state.terminalId != undefined) {
                    saveToSessionStorage('TERMINAL_ID', state.terminalId);
                    this.store.dispatch(
                        AnalyticsActions.savePageView({
                            payload: {
                                productLine: null,
                                pageName: AnalyticsPageName.START,
                                terminalId: state.terminalId,
                            },
                        }),
                    );
                }

                // redirect to campaign page if productLineId exists
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                if (!state.productLineId) {
                    void this.router.navigate(['/']);
                } else {
                    this.goToCampaignPage(state.productLineId);
                }
            });
    }

    /**
     * Initialize the session start request
     */
    private initSession(): void {
        // Retrieve query parameters from the route
        let iosProtocol = this.activatedRoute.snapshot.queryParamMap.get('returnAppScheme');
        const androidProtocol = this.activatedRoute.snapshot.queryParamMap.get('returnAppPackage');
        if (iosProtocol === null || iosProtocol === undefined) {
            iosProtocol = 'iosProtocol';
        }
        // Determine the protocol based on query parameters
        let protocol = androidProtocol;
        if (protocol === null || protocol === undefined) {
            protocol = iosProtocol;
        }

        // Determine the device based on query parameters
        let device: '' | 'android' | 'ios';
        if (androidProtocol === null || androidProtocol === '') {
            device = 'ios';
        } else {
            device = 'android';
        }

        // Remove and set values in local storage
        removeFromStorage('PROTOCOL');
        removeFromStorage('DEVICE');
        saveToStorage('PROTOCOL', protocol);
        saveToStorage('DEVICE', device);

        // Extract the URL token from the route
        const checkInUrl = this.activatedRoute.snapshot.url[1].path;

        this.store.dispatch(SessionActions.startSession({ payload: { checkInUrl: checkInUrl, issuer: protocol } }));
    }

    /**
     * Redirect to campaign page if productLineId exists
     * @param campaignId string | undefined
     */
    private goToCampaignPage(campaignId: string): void {
        this.store.dispatch(CampaignActions.loadCampaigns());
        this.store
            .select(selectCampaigns)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe((campaigns) => {
                if (!campaigns.length) return;
                if (campaigns.find((ele) => ele.internalCampaignId === campaignId)) {
                    void this.router.navigate([getLanguage(), 'campaign', campaignId]);
                } else {
                    void this.router.navigate(['/']);
                }
            });
    }
}
