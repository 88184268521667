import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, switchMap } from 'rxjs';
import { ISessionService, SESSION_SERVICE } from '../../outbound/session.service';
import { SessionActions } from '../actions/action-types';

@Injectable()
export class SessionEffects {
    constructor(
        private readonly actions$: Actions,
        @Inject(SESSION_SERVICE) private readonly sessionService: ISessionService,
    ) {}

    startSession$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SessionActions.startSession),
            switchMap((action) => this.sessionService.startSession(action.payload)),
            map((response) => SessionActions.sessionStarted({ payload: response })),
        ),
    );

    loadNewRefreshToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SessionActions.loadNewRefreshToken),
            mergeMap(() => this.sessionService.getNewAccessToken()),
            map((response) => SessionActions.newRefreshTokenLoaded({ payload: response })),
        ),
    );
}
