import type { OnInit } from '@angular/core';
import { Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AnalyticsPageName } from '../../../../domain/models/enums/page-name.enum';
import { AnalyticsActions, HomeActions } from '../../../../domain/store/actions/action-types';
import type { AppState } from '../../../../domain/store/reducers/app.reducer';
import { selectUpcomingDeal } from '../../../../domain/store/reducers/home.reducer';
import { getFromSessionStorage } from '../../../../domain/utils/storage';

@Component({
    selector: 'app-upcoming-deals',
    templateUrl: './upcoming-deals.component.html',
    styleUrls: ['./upcoming-deals.component.scss'],
})
export class UpcomingDealsComponent implements OnInit {
    private readonly store = inject<Store<AppState>>(Store<AppState>);
    private readonly router = inject(Router);

    upcomingDeal$ = this.store.select(selectUpcomingDeal).pipe(takeUntilDestroyed());

    onBackClicked(): Promise<boolean> {
        return this.router.navigate(['../']);
    }

    ngOnInit(): void {
        this.store.dispatch(HomeActions.loadUpcomingDeal());
        this.store.dispatch(
            AnalyticsActions.savePageView({
                payload: {
                    productLine: null,
                    pageName: AnalyticsPageName.UPCOMING_DEALS,
                    terminalId: getFromSessionStorage('TERMINAL_ID') ?? '',
                },
            }),
        );
    }
}
