import type {
    AffiliateDTO,
    INewstickerDTO,
    IShopSettingsModel,
    PublishedDealOfTheDayDto,
    TeaserDataDTO,
} from '@modeso/types__tsd-lib-products-be';
import { createAction } from '@ngrx/store';
import { HomePendingEnum, PrefixEnum } from './action-names.enum';

export const loadSuperCashBack = createAction(
    '[Home] Load Super CashBack',
    (pending: string = `${PrefixEnum.BEGIN}_${HomePendingEnum.LOAD_SUPER_CASHBACK}`) => ({ pending }),
);

export const superCashBackLoaded = createAction(
    '[Home] Super CashBack Loaded',
    (superCashBack: TeaserDataDTO, pending: string = `${PrefixEnum.END}_${HomePendingEnum.LOAD_SUPER_CASHBACK}`) => ({
        superCashBack,
        pending,
    }),
);

export const loadUpcomingDeal = createAction(
    '[Home] Load Upcoming Deal',
    (pending: string = `${PrefixEnum.BEGIN}_${HomePendingEnum.LOAD_UPCOMING_DEAL}`) => ({ pending }),
);

export const upcomingDealLoaded = createAction(
    '[Home] Upcoming Deal Loaded',
    (upcomingDeal: TeaserDataDTO, pending: string = `${PrefixEnum.END}_${HomePendingEnum.LOAD_UPCOMING_DEAL}`) => ({
        upcomingDeal,
        pending,
    }),
);

export const loadNewsTicker = createAction(
    '[Home] Load News Ticker',
    (pending: string = `${PrefixEnum.BEGIN}_${HomePendingEnum.LOAD_NEWS_TICKER}`) => ({ pending }),
);

export const newsTickerLoaded = createAction(
    '[Home] News Ticker Loaded',
    (newsTicker: INewstickerDTO, pending: string = `${PrefixEnum.END}_${HomePendingEnum.LOAD_NEWS_TICKER}`) => ({
        newsTicker,
        pending,
    }),
);

export const loadDealsOfTheDay = createAction(
    '[Home] Load Deals Of The Day',
    (pending: string = `${PrefixEnum.BEGIN}_${HomePendingEnum.LOAD_DEAL_OF_THE_DAY}`) => ({ pending }),
);

export const dealsOfTheDayLoaded = createAction(
    '[Home] Deals Of The Day Loaded',
    (
        dealsOfTheDay: PublishedDealOfTheDayDto[],
        pending: string = `${PrefixEnum.END}_${HomePendingEnum.LOAD_DEAL_OF_THE_DAY}`,
    ) => ({
        dealsOfTheDay,
        pending,
    }),
);

export const loadDiscountOffers = createAction(
    '[Home] Load Discount Offers',
    (pending: string = `${PrefixEnum.BEGIN}_${HomePendingEnum.LOAD_DISCOUNT_OFFERS}`) => ({ pending }),
);

export const discountOffersLoaded = createAction(
    '[Home] Discount Offers Loaded',
    (
        discountOffers: AffiliateDTO[],
        pending: string = `${PrefixEnum.END}_${HomePendingEnum.LOAD_DISCOUNT_OFFERS}`,
    ) => ({
        discountOffers,
        pending,
    }),
);

export const loadDiscountOfferById = createAction(
    '[Home] Load Discount Offer by id',
    (id: string, pending: string = `${PrefixEnum.BEGIN}_${HomePendingEnum.LOAD_DISCOUNT_OFFER}`) => ({ id, pending }),
);

export const discountOfferLoaded = createAction(
    '[Home] Discount Offer by id Loaded',
    (discountOffer: AffiliateDTO, pending: string = `${PrefixEnum.END}_${HomePendingEnum.LOAD_DISCOUNT_OFFER}`) => ({
        pending,
        discountOffer,
    }),
);

export const loadShopSettings = createAction(
    '[Home] Load Shop Settings',
    (pending: string = `${PrefixEnum.BEGIN}_${HomePendingEnum.LOAD_SHOP_SETTINGS}`) => ({ pending }),
);

export const shopSettingsLoaded = createAction(
    '[Home] Shop Settings Loaded',
    (
        shopSettings: IShopSettingsModel,
        pending: string = `${PrefixEnum.END}_${HomePendingEnum.LOAD_SHOP_SETTINGS}`,
    ) => ({
        pending,
        shopSettings,
    }),
);
