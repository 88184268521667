<div class="wrapper" mat-dialog-content>
    <div class="popup">
        <div class="icon-wrapper">
            <span class="icon-unavailable"></span>
        </div>
        <div class="popup-content">
            <ng-container *ngIf="isProductOutOfStock">
                <ng-container *ngIf="availableStock <= 0">
                    <span class="title">
                        {{ 'tsd_reservation_all_products_reserved' | localize | async }}
                    </span>
                    <span class="text">
                        {{ 'tsd_reservation_check_later' | localize | async }}
                    </span>
                </ng-container>

                <ng-container *ngIf="availableStock > 0">
                    <span class="title">
                        {{ replaceAvailableStockText('tsd_reservation_some_products_reserved' | localize | async) }}
                    </span>
                    <span class="text">
                        {{ 'tsd_reservation_products_order_less' | localize | async }}
                    </span>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="!isProductOutOfStock">
                <ng-container *ngIf="availableStock <= 0">
                    <span class="title">
                        {{
                            replaceAvailableAccessoryText('tsd_reservation_all_accessories_reserved' | localize | async)
                        }}
                    </span>
                    <span class="text">
                        {{ 'tsd_reservation_accessory_check_later' | localize | async }}
                    </span>
                </ng-container>

                <ng-container *ngIf="availableStock > 0">
                    <span class="title">
                        {{
                            replaceAvailableAccessoryText(
                                'tsd_reservation_some_accessories_reserved' | localize | async
                            )
                        }}
                    </span>
                    <span class="text">
                        {{ 'tsd_reservation_accessories_order_less' | localize | async }}
                    </span>
                </ng-container>
            </ng-container>
        </div>
        <button class="popup-button" (click)="dismiss()">
            {{ 'tsd_reservation_return_home' | localize | async }}
        </button>
    </div>
</div>
