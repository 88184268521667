<div class="logo-container">
    <img src="assets/twint.svg" alt="TWINT Logo" />
</div>

<div class="error-container">
    <img src="assets/warning.svg" alt="Error icon" />
    <div>
        <p>{{ 'tsd_default_error_msg' | localize | async }}</p>
    </div>
</div>
<div class="button-container">
    <button (click)="goToTwint()">
        {{ 'tsd_back_to_twint' | localize | async }}
    </button>
</div>
