import { Component, Input } from '@angular/core';
import { SWISS_CURRENCY } from '../../../../domain/constants/constants';

export interface OrderCosts {
    regularPrice: number;
    subtotalAmount: number;
    totalAmount: number;
    totalVAT: number;
    totalShipping: number;
    vatShipping: number;
    vatPercentage: number;
    totalDiscount: number;
    couponDiscount?: number;
    pctDiscount: number;
    discountPercentage?: number;
}

@Component({
    selector: 'app-campaign-order-total',
    templateUrl: './campaign-order-total.component.html',
    styleUrls: ['./campaign-order-total.component.scss'],
})
export class CampaignOrderTotalComponent {
    @Input({ required: true }) orderCosts!: OrderCosts;
    @Input() fromOrderDetails = false;

    CURRENCY = SWISS_CURRENCY;
}
