/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { SWISS_CURRENCY } from '../../../../domain/constants/constants';
import { CampaignButton } from '../../../../domain/models/campaign-button.interface';

@Component({
    selector: 'app-campaign-button',
    templateUrl: './campaign-button.component.html',
    styleUrls: ['./campaign-button.component.scss'],
})
export class CampaignButtonComponent implements OnInit, OnChanges {
    protected MINIMUM_COUNTER_VALUE = 1;
    @Output() buyNowEvent = new EventEmitter<CampaignButton>();
    @Input({ required: true }) label!: string | null;
    @Input() minValueAvailable: number = this.MINIMUM_COUNTER_VALUE;
    @Input() maxValueAvailable: number | null = Number.MAX_SAFE_INTEGER;
    @Input() disabled = false;
    @Input() data?: CampaignButton = {
        quantity: 1,
        maximumQuantity: Number.MAX_SAFE_INTEGER,
        originalPrice: 0,
        discountedPrice: 0,
    };

    @Input() fromAccessoryPage = false;
    @Input() isOutOfStock = false;

    protected outputData: CampaignButton = {
        quantity: 1,
        maximumQuantity: Number.MAX_SAFE_INTEGER,

        originalPrice: 0,
        discountedPrice: 0,
    };

    protected originalData: CampaignButton = {
        quantity: 1,
        maximumQuantity: Number.MAX_SAFE_INTEGER,
        originalPrice: 0,
        discountedPrice: 0,
    };

    protected readonly currency = SWISS_CURRENCY;

    ngOnInit(): void {
        if (this.data == null) return;
        this.outputData = { ...this.data };
        this.originalData = cloneDeep(this.data);
        this.updatePrice();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['maxValueAvailable'] != null || changes['data'] != null) {
            if (changes['data'].currentValue != null) {
                this.outputData = { ...changes['data'].currentValue };
                this.originalData = cloneDeep(changes['data'].currentValue);
            }
            this.updatePrice();
        }
    }

    increase(): void {
        if (this.maxValueAvailable === null || this.outputData.quantity < this.maxValueAvailable) {
            this.outputData.quantity++;

            this.updatePrice();
        }
    }

    decrease(): void {
        if (this.outputData.quantity > this.minValueAvailable) {
            this.outputData.quantity--;
            this.updatePrice();
        }
    }

    private updatePrice(): void {
        if (this.data == null || this.fromAccessoryPage) return;

        this.outputData.discountedPrice = this.data.discountedPrice * this.outputData.quantity;
        this.outputData.originalPrice = this.data.originalPrice * this.outputData.quantity;
    }

    onBuyNowAction(): void {
        this.buyNowEvent.emit(this.outputData);
    }
}
