import type { MerchantDTO } from '@modeso/types__tsd-lib-products-be';
import { createFeature, createReducer, on } from '@ngrx/store';
import { MerchantActions } from '../actions/action-types';

export interface MerchantState {
    merchants: MerchantDTO[];
}

const initialCampaignState: MerchantState = {
    merchants: [],
};

const reducer = createReducer(
    initialCampaignState,
    on(MerchantActions.merchantsLoaded, (state, action) => ({ ...state, merchants: action.merchants })),
);

const campaignFeature = createFeature({
    name: 'merchants',
    reducer,
});

export const { name: merchantFeatureKey, reducer: merchantReducer, selectMerchants } = campaignFeature;
