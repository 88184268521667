import type { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isEmail } from 'validator';

export function emailValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value as string;
        if (!value) {
            return null; // Don't validate empty value
        }
        return isEmail(value) ? null : { invalidEmail: true };
    };
}
