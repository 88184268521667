import { Component, HostBinding, Input, QueryList, ViewChildren } from '@angular/core';
import type { MatExpansionPanel } from '@angular/material/expansion';
import type { IPDF } from '@modeso/types__tsd-lib-products-be';

export interface IAccordionData {
    title: string;
    details: string;
    imageURL?: string;
    pdfList?: IPDF[];
}

@Component({
    selector: 'app-information-accordion',
    templateUrl: './information-accordion.component.html',
    styleUrls: ['./information-accordion.component.scss'],
})
export class InformationAccordionComponent {
    @Input() data: IAccordionData[] = [];
    @Input() showIcon: boolean = true;
    @Input() faqStyles: boolean = false;

    // Access all expansion panels and specify the type of the native element as HTMLElement
    @ViewChildren('expansionPanel') expansionPanels!: QueryList<MatExpansionPanel>;

    @HostBinding('class.faqStyles') get applyFaqStyles(): boolean {
        return this.faqStyles;
    }

    onAccordionOpened(index: number): void {
        setTimeout(() => {
            if (this.expansionPanels.length > index) {
                // Access the MatExpansionPanel directive instance
                const matExpansionPanel: MatExpansionPanel = this.expansionPanels.toArray()[index];

                // Access header of the MatExpansionPanel instance
                const accordion: HTMLElement = document.getElementById(matExpansionPanel._headerId) as HTMLElement;
                accordion.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }, 200);
    }

    // If needed, you can handle the closing event similarly
    onAccordionClosed(index: number): void {
        // Implement if you need any specific behavior when an accordion is closed
    }
}
