<div class="discount-offer-deal">
    <div class="bubble" [ngStyle]="{ 'background-color': circularBubbleRgbaColor }">
        <img class="discount-offer-logo" fetchpriority="high" src="{{ logoUrl }}" alt="logo-url" />
    </div>
    <img
        class="discount-offer-deal-image"
        [ngClass]="{ 'discount-offer-deal-image-with-no-background': imageType === imageTypes.NO_BG_IMAGE }"
        fetchpriority="high"
        src="{{ imageUrl }}"
        alt="discount-offer-image"
    />
</div>

<div class="discount-offer-deal-text-section">
    <div class="discount-offer-deal-text text-truncated" [innerHtml]="text | markdownToSafeHTML"></div>
</div>
