/* eslint-disable no-magic-numbers */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import type { IConfigPrice, IDynamicConfig } from '@modeso/types__tsd-lib-products-be';
import { VisualTypes } from '@modeso/types__tsd-lib-products-be';
import { SWISS_CURRENCY } from '../../../../domain/constants/constants';
import { CampaignAccessoryItem } from '../../../../domain/models/campaign-accessory-item.interface';
import type { ITsdVariationSelection } from '../../shared/campaign-variation-list/campaign-variation-list.component';

const INITIAL_TOTAL_PRICE = 0;

@Component({
    selector: 'app-campaign-accessory-item',
    templateUrl: './campaign-accessory-item.component.html',
    styleUrls: ['./campaign-accessory-item.component.scss'],
})
export class CampaignAccessoryItemComponent implements OnChanges, OnInit {
    private readonly cd = inject(ChangeDetectorRef);
    protected MINIMUM_COUNTER_VALUE = 1;

    @Output() addToCart = new EventEmitter<CampaignAccessoryItem>();
    @Output() removeFromCart = new EventEmitter<CampaignAccessoryItem>();

    @Input({ required: true }) item!: CampaignAccessoryItem;

    protected originalItem: CampaignAccessoryItem | undefined;

    protected readonly currency = SWISS_CURRENCY;

    isAddedToCart: boolean = true;
    totalPrice: number = INITIAL_TOTAL_PRICE;
    protected readonly imageTypes = VisualTypes;

    // this is only used if user go back from page and the accessory in order line
    // we will show to user data he choose
    private isAlreadyInOrderLine = false;

    ngOnInit(): void {
        if (this.item.maxValueAvailable === 0) {
            this.item = { ...this.item, quantity: 0 };
        }

        const isOutOfStock = this.item.dealStock.every((stock) => stock.remainingStock === 0);
        if (isOutOfStock) {
            this.item = { ...this.item, quantity: 0, maxValueAvailable: 0 };
        }

        if (this.item.selectedConfig) {
            this.isAddedToCart = false;
            this.isAlreadyInOrderLine = true;
        }

        this.originalItem = { ...this.item };
    }

    increase(): void {
        if (this.item.maxValueAvailable !== null && this.item.quantity >= this.item.maxValueAvailable) return;
        this.item = { ...this.item, quantity: this.item.quantity + 1 };
        this.updatePrice();
        this.removeFromCart.emit(this.item);
        this.isAddedToCart = true;
    }

    decrease(): void {
        if (this.item.quantity > this.MINIMUM_COUNTER_VALUE) {
            this.item = { ...this.item, quantity: this.item.quantity - 1 };
            this.updatePrice();
            this.removeFromCart.emit(this.item);
            this.isAddedToCart = true;
        }
    }

    /**
     * Updates the price of the item
     */
    updatePrice(): void {
        const quantity = this.item?.quantity || 1;
        this.totalPrice = this.item.price * quantity;
    }

    /**
     * Adds or removes the item from the cart
     * @param event event from the button
     */
    onAddOrRemove(): void {
        if (this.isAddedToCart) {
            this.addToCart.emit(this.item);
            this.isAddedToCart = false;
        } else {
            this.item = { ...this.item, quantity: 1 };
            this.updatePrice();
            this.removeFromCart.emit(this.item);
            this.isAddedToCart = true;
        }
    }

    selectVariation(variant: Record<string, ITsdVariationSelection>): void {
        const selectedConfig = {} as IDynamicConfig;
        Object.values(variant).forEach((val: ITsdVariationSelection) => {
            selectedConfig[val.property] = val.value;
        });

        const stock = this.item?.dealStock.find((ele) => JSON.stringify(ele.config) === JSON.stringify(selectedConfig));

        if (this.isAlreadyInOrderLine) {
            this.item = { ...this.item };
        } else if (stock && stock.remainingStock > 0 && stock.remainingStock <= stock.originalStock) {
            this.item = { ...this.item, maxValueAvailable: stock.remainingStock, selectedConfig, quantity: 1 };
        } else {
            this.item = { ...this.item, maxValueAvailable: 0, selectedConfig: { config: 'none' }, quantity: 0 };
        }

        this.selectPriceForConfiguration(selectedConfig);
        if (!this.isAlreadyInOrderLine) this.removeFromCart.emit(this.item);
        this.isAddedToCart = this.isAlreadyInOrderLine ? false : true;
        this.updatePrice();
        this.isAlreadyInOrderLine = false;
        this.cd.detectChanges();
    }

    private selectPriceForConfiguration(config: IDynamicConfig): void {
        if (config === undefined) {
            config = {
                config: 'none',
            };
        }

        let prices = this.item.prices ?? [];
        Object.keys(config).forEach((property) => {
            prices = prices.filter((price: IConfigPrice) => {
                return price.config[property] === config[property];
            });
        });

        this.item = { ...this.item, price: prices[0]?.price || this.item?.prices[0].price };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['item'].currentValue != null) {
            this.originalItem = { ...(changes['item'].currentValue as CampaignAccessoryItem) };
            this.updatePrice();
        }
    }
}
