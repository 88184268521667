/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable no-magic-numbers */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import { DialogRef } from '@angular/cdk/dialog';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface IData {
    availableStock: number;
    insufficientAccessoryName: string;
    isProductOutOfStock: boolean;
    onDismiss: () => void;
}

@Component({
    selector: 'app-tsd-insufficient-quantity',
    templateUrl: './tsd-insufficient-quantity.component.html',
    styleUrls: ['./tsd-insufficient-quantity.component.scss'],
})
export class TsdInsufficientQuantityComponent {
    availableStock = 0;
    insufficientAccessoryName = '';
    isProductOutOfStock = false;

    constructor(
        private readonly dialogRef: DialogRef,
        @Inject(MAT_DIALOG_DATA) public data: IData,
    ) {
        this.availableStock = data?.availableStock ?? this.availableStock;
        this.insufficientAccessoryName = data?.insufficientAccessoryName ?? this.insufficientAccessoryName;
        this.isProductOutOfStock = data?.isProductOutOfStock ?? this.isProductOutOfStock;
    }

    public dismiss(): void {
        if (this.data?.onDismiss) {
            this.data.onDismiss();
        }
        this.dialogRef.close();
    }

    public replaceAvailableStockText(text: string | null): string {
        return (text ?? '').replace(
            '{availableStock}',
            (this.data?.availableStock || this.availableStock || 0).toString(),
        );
    }
    public replaceAvailableAccessoryText(text: string | null): string {
        return (text ?? '')
            .replace('{accessoryName}', this.data?.insufficientAccessoryName || this.insufficientAccessoryName || '')
            .replace('{availableStock}', (this.data?.availableStock || this.availableStock || 0).toString());
    }
}
