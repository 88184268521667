/* eslint-disable no-magic-numbers */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import type { OnDestroy } from '@angular/core';
import { Component, Input } from '@angular/core';
import { VisualTypes } from '@modeso/types__tsd-lib-products-be';
import type { CampaignImageSlider } from '../../../../domain/models/campaign-image-slider.interface';

@Component({
    selector: 'app-campaign-image-slider',
    templateUrl: './campaign-image-slider.component.html',
    styleUrls: ['./campaign-image-slider.component.scss'],
})
export class CampaignImageSliderComponent implements OnDestroy {
    @Input({ required: true }) campaignImageSlider: CampaignImageSlider[] = [];
    @Input({ required: true }) pillText!: string;

    slideDuration = 3000;
    currentIndex = 0;
    timeoutInterval?: number;

    visualTypes = VisualTypes;

    goToNext(): void {
        const isLastSlide = this.currentIndex === this.campaignImageSlider.length - 1;
        const newIndex = isLastSlide ? 0 : this.currentIndex + 1;

        this.currentIndex = newIndex;
    }

    goToPrevious(): void {
        const isFirstSlide = this.currentIndex === 0;
        const newIndex = isFirstSlide ? this.campaignImageSlider.length - 1 : this.currentIndex - 1;

        this.currentIndex = newIndex;
    }

    ngOnDestroy(): void {
        window.clearTimeout(this.timeoutInterval);
    }
}
