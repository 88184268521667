import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { ANALYTICS_SERVICE, IAnalyticsService } from '../../outbound/analytics.service';
import { AnalyticsActions } from '../actions/action-types';

@Injectable()
export class AnalyticEffects {
    constructor(
        private readonly actions$: Actions,
        @Inject(ANALYTICS_SERVICE) private readonly analyticsService: IAnalyticsService,
    ) {}

    onSavePageView$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AnalyticsActions.savePageView),
                switchMap((action) => this.analyticsService.savePageView(action.payload).pipe(catchError(() => EMPTY))),
            ),
        { dispatch: false },
    );
}
