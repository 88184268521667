import type { IPersonDto, ISubscriptionRequest } from '@modeso/types__tsd-lib-user-be';
import { createAction } from '@ngrx/store';
import { PrefixEnum, UserProfilePendingEnum } from './action-names.enum';

export const createUserProfile = createAction(
    '[User-Profile] Create User Profile',
    (
        userProfile: IPersonDto,
        pending: string = `${PrefixEnum.BEGIN}_${UserProfilePendingEnum.CREATE_USER_PROFILE}`,
    ) => ({
        userProfile,
        pending,
    }),
);

export const userProfileCreated = createAction(
    '[User-Profile] User Profile Created',
    (userProfile: IPersonDto, pending: string = `${PrefixEnum.END}_${UserProfilePendingEnum.CREATE_USER_PROFILE}`) => ({
        userProfile,
        pending,
    }),
);

export const updateUserProfile = createAction(
    '[User-Profile] Update User Profile',
    (
        userProfile: IPersonDto,
        pending: string = `${PrefixEnum.BEGIN}_${UserProfilePendingEnum.UPDATE_USER_PROFILE}`,
    ) => ({
        userProfile,
        pending,
    }),
);

export const userProfileUpdated = createAction(
    '[User Profile] User Profile Updated',
    (userProfile: IPersonDto, pending: string = `${PrefixEnum.END}_${UserProfilePendingEnum.UPDATE_USER_PROFILE}`) => ({
        userProfile,
        pending,
    }),
);

export const deleteUserProfile = createAction(
    '[User Profile] Delete User Profile',
    (pending: string = `${PrefixEnum.BEGIN}_${UserProfilePendingEnum.DELETE_USER_PROFILE}`) => ({ pending }),
);

export const userProfileDeleted = createAction(
    '[User Profile] User Profile Deleted',
    (pending: string = `${PrefixEnum.END}_${UserProfilePendingEnum.DELETE_USER_PROFILE}`) => ({ pending }),
);

export const loadUserProfile = createAction(
    '[User Profile] Load User Profile',
    (pending: string = `${PrefixEnum.BEGIN}_${UserProfilePendingEnum.LOAD_USER_PROFILE}`) => ({ pending }),
);

export const userProfileLoaded = createAction(
    '[User Profile] User Profile Loaded',
    (userProfile: IPersonDto, pending: string = `${PrefixEnum.END}_${UserProfilePendingEnum.LOAD_USER_PROFILE}`) => ({
        userProfile,
        pending,
    }),
);

export const updateNewsLetterStatus = createAction(
    '[User Profile] Update Newsletter status',
    (
        payload: ISubscriptionRequest,
        pending: string = `${PrefixEnum.BEGIN}_${UserProfilePendingEnum.UPDATE_NEWSLETTER_STATUS}`,
    ) => ({
        payload,
        pending,
    }),
);

export const newsletterStatusUpdated = createAction(
    '[User Profile] Newsletter Status Updated',
    (
        payload: ISubscriptionRequest,
        pending: string = `${PrefixEnum.END}_${UserProfilePendingEnum.UPDATE_NEWSLETTER_STATUS}`,
    ) => ({
        payload,
        pending,
    }),
);
