import { formatNumber } from '@angular/common';
import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

const INTEGER_PART = 0;

@Pipe({
    name: 'swissNumber',
})
export class SwissNumberPipe implements PipeTransform {
    transform(value: number): string {
        if (isNaN(value)) {
            throw new Error('Invalid Number');
        }
        const formattedNumber = formatNumber(value, 'de-CH', '1.2-2');

        if (!Number.isInteger(value)) {
            return formattedNumber;
        }

        const integerPart = formattedNumber.split('.')[INTEGER_PART];
        const formattedIntegerNumber = integerPart.replace('.', '-');

        return `${formattedIntegerNumber}.-`;
    }
}
