export enum PrefixEnum {
    BEGIN = 'BEGIN',
    END = 'END',
}

export enum HomePendingEnum {
    LOAD_UPCOMING_DEAL = 'loadUpcomingDeal',
    LOAD_SUPER_CASHBACK = 'loadSuperCashBack',
    LOAD_NEWS_TICKER = 'loadNewsTicker',
    LOAD_DEAL_OF_THE_DAY = 'loadDealOfTheDay',
    LOAD_DISCOUNT_OFFERS = 'loadDiscountOffers',
    LOAD_DISCOUNT_OFFER = 'loadDiscountOffer',
    LOAD_SHOP_SETTINGS = 'loadShopSettings',
}

export enum OrderPendingEnum {
    LOAD_ORDERS = 'loadOrders',
    LOAD_ORDERS_GROUPED = 'loadOrdersGrouped',
    LOAD_ORDER_BY_UUID = 'loadOrderByUuid',
    CREATE_ORDER = 'createOrder',
    UPDATE_ORDER = 'updateOrder',
    CHECKOUT_ORDER = 'checkOutOrder',
    APPLY_COUPON = 'applyCoupon',
    LOAD_COUPON_FEATURE = 'loadCouponFeature',
}

export enum UserProfilePendingEnum {
    CREATE_USER_PROFILE = 'createUserProfile',
    UPDATE_USER_PROFILE = 'updateUserProfile',
    DELETE_USER_PROFILE = 'deleteUserProfile',
    LOAD_USER_PROFILE = 'loadUserProfile',
    UPDATE_NEWSLETTER_STATUS = 'updateNewsLetterStatus',
}

export enum CampaignPendingEnum {
    LOAD_CAMPAIGNS = 'loadCampaigns',
    LOAD_CAMPAIGN = 'loadCampaign',
}

export enum MerchantPendingEnum {
    LOAD_MERCHANTS = 'loadMerchants',
}
