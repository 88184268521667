import type { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Store } from '@ngrx/store';
import type { Observable } from 'rxjs';
import { of, throwError } from 'rxjs';
import { catchError, concatMap, delay, retryWhen, take } from 'rxjs/operators';
import type { ErrorMessage } from '../../domain/models/error-message.model';
import * as SessionActions from '../../domain/store/actions/session.actions';
import type { AppState } from '../../domain/store/reducers/app.reducer';

export interface IAuthHandlerDelegate {
    onSessionExpired: () => void;
}

export const AUTH_INTERCEPTOR = new InjectionToken<IAuthHandlerDelegate>('AUTH_INTERCEPTOR');

@Injectable()
export class AuthHandler implements HttpInterceptor {
    constructor(
        private readonly store: Store<AppState>,
        @Inject(AUTH_INTERCEPTOR) private readonly authHandlerDelegate: IAuthHandlerDelegate,
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        request = request.clone({
            withCredentials: true,
        });

        const NUM_OF_RETRY = 2;
        const DELAY = 1000;
        const SESSION_EXPIRED_STATUS = 440;
        const UNAUTHORIZED_STATUS = 401;

        return next.handle(request).pipe(
            catchError((err: HttpErrorResponse): Observable<HttpEvent<unknown>> => {
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                if (err.status === SESSION_EXPIRED_STATUS && this.authHandlerDelegate) {
                    this.authHandlerDelegate?.onSessionExpired?.();
                }

                return throwError(() => err);
            }),
            retryWhen((errors) =>
                errors.pipe(
                    concatMap((error: HttpErrorResponse) => {
                        if (error.status === UNAUTHORIZED_STATUS) {
                            this.store.dispatch(SessionActions.loadNewRefreshToken());
                            return of(error.status);
                        }
                        return throwError(() => error);
                    }),
                    take(NUM_OF_RETRY),
                    delay(DELAY),
                ),
            ),
        );
    }

    private mapError(error: unknown): ErrorMessage & { fromApi: boolean } {
        let errorDetails: ErrorMessage & { fromApi: boolean } = {
            message: 'Server is starting up, please try again in a few seconds.',
            details: '',
            detailsPlain: undefined,
            status: undefined,
            fromApi: true,
        };

        if (error instanceof HttpErrorResponse) {
            if (error.status) {
                const errorData = error.error as { message?: string; status?: number; error?: string };
                errorDetails.message = errorData?.message?.toString() ?? errorData?.status?.toString() ?? '';
                errorDetails.details = errorData?.error?.toString() ?? '';
                errorDetails.status = error.status;
                if (typeof errorData.error !== 'string') {
                    errorDetails.detailsPlain = JSON.stringify(errorData.error || {});
                }
            }
        } else {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
            errorDetails = error as any;
        }

        return errorDetails;
    }
}
