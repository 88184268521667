import { DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface IData {
    onDismiss: () => void;
}

@Component({
    selector: 'app-tsd-reservation-popup',
    templateUrl: './tsd-reservation-popup.component.html',
    styleUrls: ['./tsd-reservation-popup.component.scss'],
})
export class TsdReservationPopupComponent {
    constructor(
        private readonly dialogRef: DialogRef,
        @Inject(MAT_DIALOG_DATA) public data: IData,
    ) {}
    @Output() onDismiss: EventEmitter<void> = new EventEmitter<void>();
    public dismiss(): void {
        if (this.data?.onDismiss) {
            this.data.onDismiss();
        }
        this.onDismiss.emit();
        this.dialogRef.close();
    }
}
