<!-- newsletter.component.html -->
<app-page-header [title]="'tsd_newsletter_title' | localize | async" (backButtonClick)="goBack()"></app-page-header>

<form [formGroup]="newsletterForm" (ngSubmit)="onSave()">
    <div class="wrapper">
        <!-- description -->
        <p>{{ 'tsd_newsletter_intro' | localize | async }}</p>

        <!-- email address input -->
        <mat-form-field appearance="outline">
            <mat-label>{{ 'tsd_email' | localize | async }}</mat-label>
            <input matInput formControlName="email" type="email" required />
            <mat-error *ngIf="newsletterForm.controls['email'].invalid && newsletterForm.controls['email'].touched">
                {{ 'tsd_newsletter_invalidEmail' | localize | async }}
            </mat-error>
        </mat-form-field>

        <!-- slide toggle -->
        <div class="toggle-container">
            <div class="toggle-label">{{ 'tsd_newsletter_recieve_deals_vie_email' | localize | async }}</div>
            <div class="toggle-switch">
                <div class="custom-toggle" [class.active]="isSubscribingToNewsletters" (click)="toggle()">
                    <div class="toggle-track"></div>
                    <div class="toggle-thumb"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="stickbutton">
        <!-- button -->
        <button
            class="submit-button"
            type="submit"
            [disabled]="newsletterForm.invalid || (isUpdateNewsLetterStatusLoading$ | async)"
        >
            {{ 'tsd_save_btn' | localize | async }}
        </button>
    </div>
</form>
