import * as AnalyticsActions from './analytics.actions';
import * as CampaignActions from './campaign.actions';
import * as ErrorActions from './errors.actions';
import * as HomeActions from './home.actions';
import * as MerchantActions from './merchant.actions';
import * as OrderActions from './order.actions';
import * as SessionActions from './session.actions';
import * as SuccessActions from './success.actions';
import * as UserProfileActions from './user-profile.actions';

export {
    AnalyticsActions,
    CampaignActions,
    ErrorActions,
    HomeActions,
    MerchantActions,
    OrderActions,
    SessionActions,
    SuccessActions,
    UserProfileActions,
};

const actionTypes = {
    ...ErrorActions,
    ...SuccessActions,
    ...AnalyticsActions,
    ...CampaignActions,
    ...HomeActions,
    ...OrderActions,
    ...SessionActions,
    ...UserProfileActions,
    ...MerchantActions,
};

export const allActionTypes = Object.values(actionTypes);
