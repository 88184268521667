<!-- page header -->
<app-page-header
    [title]="'tsd_super_cashback' | localize | async"
    (backButtonClick)="onBackClicked()"
></app-page-header>

<!-- deal details -->
<div class="super_cashback_container" *ngIf="superCashback$ | async as superCashback">
    <img class="super_cashback_image" fetchpriority="high" [src]="superCashback?.imageUrl" alt="Super Cashback image" />

    <div class="super_cashback_description" [innerHtml]="superCashback?.text | markdownToSafeHTML"></div>
</div>
