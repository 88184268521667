import type { OnInit } from '@angular/core';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import type { GroupedOrders, MyOrdersItem } from '@modeso/types__tsd-lib-cart-be';
import { Store } from '@ngrx/store';
import { AnalyticsPageName } from '../../../../domain/models/enums/page-name.enum';
import { AnalyticsActions, OrderActions } from '../../../../domain/store/actions/action-types';
import type { AppState } from '../../../../domain/store/reducers/app.reducer';
import { selectGroupOrdersByMonth } from '../../../../domain/store/reducers/order.reducer';
import { isOrdersLoading } from '../../../../domain/store/reducers/pending.reducer';
import { getLanguage } from '../../../../domain/utils/language';
import { getFromSessionStorage } from '../../../../domain/utils/storage';

@Component({
    selector: 'app-my-orders',
    templateUrl: './my-orders.component.html',
    styleUrls: ['./my-orders.component.scss'],
})
export class MyOrdersComponent implements OnInit {
    private readonly fb = inject(FormBuilder);
    private readonly store = inject<Store<AppState>>(Store<AppState>);
    private readonly router = inject(Router);
    private readonly destroyRef = inject(DestroyRef);

    isOrdersLoading$ = this.store.select(isOrdersLoading).pipe(takeUntilDestroyed());

    groupedOrders: GroupedOrders = {
        months: [],
        orders: {},
    };

    goBack(): Promise<boolean> {
        return this.router.navigate(['../']);
    }

    ngOnInit(): void {
        this.store.dispatch(OrderActions.loadOrdersGrouped());
        this.store.dispatch(
            AnalyticsActions.savePageView({
                payload: {
                    productLine: null,
                    pageName: AnalyticsPageName.MY_ORDERS,
                    terminalId: getFromSessionStorage('TERMINAL_ID') ?? '',
                },
            }),
        );

        this.store
            .select(selectGroupOrdersByMonth)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (orders) => {
                    this.groupedOrders = JSON.parse(JSON.stringify(orders));
                },
            });
    }

    onOrderClicked(id: string): void {
        const language = getLanguage();
        void this.router.navigate([`${language}`, 'myorders', id]);
    }

    usePlaceHolderImage(order: MyOrdersItem): void {
        order.imgUrl = 'assets/product-placeholder.png';
    }
}
