import type { ActionReducerMap, MetaReducer } from '@ngrx/store';
import type { CampaignState } from './campaign.reducer';
import * as CampaignFeature from './campaign.reducer';
import type { ErrorState } from './error.reducer';
import { errorFeatureKey, errorReducer } from './error.reducer';
import type { HomeState } from './home.reducer';
import * as HomeFeature from './home.reducer';
import type { MerchantState } from './merchant.reducer';
import { merchantFeatureKey, merchantReducer } from './merchant.reducer';
import type { OrdersState } from './order.reducer';
import * as OrderFeature from './order.reducer';
import type { PendingState } from './pending.reducer';
import { pendingFeatureKey, pendingReducer } from './pending.reducer';
import type { SessionState } from './session.reducer';
import * as SessionFeature from './session.reducer';
import type { SuccessState } from './success.reducer';
import { successFeatureKey, successReducer } from './success.reducer';
import type { UserProfileState } from './user-profile.reducer';
import * as UserProfileFeature from './user-profile.reducer';

export interface AppState {
    [errorFeatureKey]: ErrorState;
    [successFeatureKey]: SuccessState;
    [pendingFeatureKey]: PendingState;
    [UserProfileFeature.name]: UserProfileState;
    [HomeFeature.name]: HomeState;
    [OrderFeature.name]: OrdersState;
    [CampaignFeature.name]: CampaignState;
    [SessionFeature.name]: SessionState;
    [merchantFeatureKey]: MerchantState;
}

export const reducers: ActionReducerMap<AppState> = {
    [errorFeatureKey]: errorReducer,
    [successFeatureKey]: successReducer,
    [pendingFeatureKey]: pendingReducer,
    [UserProfileFeature.name]: UserProfileFeature.reducer,
    [HomeFeature.name]: HomeFeature.reducer,
    [OrderFeature.name]: OrderFeature.reducer,
    [CampaignFeature.name]: CampaignFeature.reducer,
    [SessionFeature.name]: SessionFeature.reducer,
    [merchantFeatureKey]: merchantReducer,
};

export const metaReducers: MetaReducer<AppState>[] = [];
