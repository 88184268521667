import { Component, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import type { CampaignImageSlider } from '../../../../domain/models/campaign-image-slider.interface';
import { CampaignImageGalleryComponent } from '../campaign-image-gallery/campaign-image-gallery.component';
import { NavigationService } from './../../../../domain/services/navigate.service';

const NOT_FOUND_INDEX = -1;
const FIRST_INDEX = 0;

@Component({
    selector: 'app-zoom',
    templateUrl: './zoom.component.html',
    styleUrls: ['./zoom.component.scss'],
})
export class ZoomComponent {
    private readonly router = inject(Router);
    private readonly navigationService = inject(NavigationService);

    @ViewChild('campaignImageGallery', { static: true }) campaignImageGalleryComponent!: CampaignImageGalleryComponent;

    sliderImages: CampaignImageSlider[] = [];

    activeIndex: number = FIRST_INDEX;

    constructor() {
        this.sliderImages =
            (this.router.getCurrentNavigation()?.extras?.state?.['sliderImages'] as CampaignImageSlider[]) ?? [];
        if (!this.sliderImages || !this.sliderImages.length) this.goBack();

        this.setupSliderImages();
    }

    setupSliderImages(): void {
        // Find the index of the image with the currentImageUrl
        const currentImageUrl = this.router.getCurrentNavigation()?.extras?.state?.['currentImageUrl'] as string;
        if (!currentImageUrl) return;

        this.activeIndex = this.sliderImages.findIndex((slide) => slide.mediaUrl === currentImageUrl);

        // If the index is -1 (image not found), default to the first image
        if (this.activeIndex === NOT_FOUND_INDEX) {
            this.activeIndex = FIRST_INDEX;
        }

        // Navigate to the active index if necessary
        if (this.activeIndex > FIRST_INDEX) {
            setTimeout(() => {
                // Pass the index to the gallery component, which should handle the navigation
                this.campaignImageGalleryComponent.navigateToSlide(this.activeIndex);
            });
        }
    }

    goBack(): void {
        // go back to the previous page with the last image index as state data to navigate to the correct image in the gallery
        this.navigationService.navigateToPreviousPage({
            lastImageIndex: this.campaignImageGalleryComponent?.activeIndex || FIRST_INDEX,
        });
    }

    onSwipeDown(): void {
        if (!this.campaignImageGalleryComponent.isAnyZoomedIn) {
            this.goBack();
        }
    }
}
