<mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel
        *ngFor="let accordionData of data; let i = index"
        #expansionPanel
        (opened)="onAccordionOpened(i)"
        (closed)="onAccordionClosed(i)"
    >
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="title">
                    <img
                        src="{{ accordionData.imageURL ? accordionData.imageURL : 'assets/specification-update.png' }}"
                        alt="icon"
                        *ngIf="showIcon"
                    />
                    {{ accordionData.pdfList?.length ? (accordionData.title | localize | async) : accordionData.title }}
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div
            class="details"
            *ngIf="accordionData.details"
            [innerHTML]="accordionData.details | markdownToSafeHTML"
        ></div>

        <!-- pdf list -->
        <div class="document-list-container" *ngIf="accordionData.pdfList?.length">
            <a class="document-container" [href]="pdf.url" target="_blank" *ngFor="let pdf of accordionData?.pdfList">
                <img class="document-icon" src="assets/pdf.svg" alt="document" width="40px" height="40px" />
                <span class="document-name"> {{ pdf.filename }} </span>
            </a>
        </div>
    </mat-expansion-panel>
</mat-accordion>
