import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type {
    AffiliateDTO,
    INewstickerDTO,
    IShopSettingsModel,
    MerchantDTO,
    PublishedDealOfTheDayDto,
    SpecialDealDTO,
    SpecialDealResponse,
    TeaserDataDTO,
} from '@modeso/types__tsd-lib-products-be';
import { environment } from 'projects/tsd-project-shop/src/environments/environment';
import type { Observable } from 'rxjs';
import type { AffiliateResponse } from '../../domain/models/affiliate.model';
import type { IProductService } from '../../domain/outbound/product.service';
@Injectable()
export class ProductsService implements IProductService {
    constructor(private readonly http: HttpClient) {}
    private readonly PRODUCTS_ENDPOINT_BASE_URL = environment.backendUrl + `products`;

    getAffiliates(): Observable<AffiliateResponse> {
        const url = `${this.PRODUCTS_ENDPOINT_BASE_URL}/affiliates/all`;
        return this.http.get<AffiliateResponse>(url);
    }

    getAffiliateByAffiliateId(id: string): Observable<AffiliateDTO> {
        const url = `${this.PRODUCTS_ENDPOINT_BASE_URL}/affiliates/all/${id}`;
        return this.http.get<AffiliateDTO>(url);
    }

    getPublishedNewsTicker(): Observable<INewstickerDTO> {
        const url = `${this.PRODUCTS_ENDPOINT_BASE_URL}/newsticker`;
        return this.http.get<INewstickerDTO>(url);
    }

    getMerchants(): Observable<MerchantDTO[]> {
        const url = `${this.PRODUCTS_ENDPOINT_BASE_URL}/merchants/info`;
        return this.http.get<MerchantDTO[]>(url);
    }

    getSuperCashback(): Observable<TeaserDataDTO> {
        const url = `${this.PRODUCTS_ENDPOINT_BASE_URL}/superCashback`;
        return this.http.get<TeaserDataDTO>(url);
    }

    getUpcomingDeal(): Observable<TeaserDataDTO> {
        const url = `${this.PRODUCTS_ENDPOINT_BASE_URL}/upcomingDeal`;
        return this.http.get<TeaserDataDTO>(url);
    }

    getCampaigns(): Observable<SpecialDealResponse> {
        const url = `${this.PRODUCTS_ENDPOINT_BASE_URL}/specialdeals/`;
        return this.http.get<SpecialDealResponse>(url);
    }

    getCampaignByCampaignId(id: string): Observable<SpecialDealDTO> {
        const url = `${this.PRODUCTS_ENDPOINT_BASE_URL}/specialdeals/${id}`;
        return this.http.get<SpecialDealDTO>(url);
    }

    getDealsOfTheDay(): Observable<PublishedDealOfTheDayDto[]> {
        const url = `${this.PRODUCTS_ENDPOINT_BASE_URL}/daily-deals/published`;
        return this.http.get<PublishedDealOfTheDayDto[]>(url);
    }

    getShopSettings(): Observable<IShopSettingsModel> {
        const url = `${this.PRODUCTS_ENDPOINT_BASE_URL}/shop-settings`;
        return this.http.get<IShopSettingsModel>(url);
    }
}
