import {
    DealOfTheDayType,
    Purpose,
    VisualTypes,
    type AffiliateDTO,
    type PublishedDealOfTheDayDto,
} from '@modeso/types__tsd-lib-products-be';
import { PERCENTAGE, SWISS_CURRENCY, ZERO_LENGTH } from '../constants/constants';
import type { DiscountOfferImageSlider } from './../models/discount-offer-image-slider.interface';
import { convertHexColorAndOpacityToRgba } from './color.util';
import isCorrectResolution from './visuals.utils';

export class DiscountOffersUtils {
    /**
     *  Set discount offers image slider based on discount offers list
     * @param discountOffers list of discount offers
     */
    static getDiscountOffersImageSlider(discountOffers: AffiliateDTO[]): DiscountOfferImageSlider[] {
        const discountOffersImageSlider: DiscountOfferImageSlider[] = [];
        for (const discountOffer of discountOffers) {
            const discountUnit = discountOffer.discountUnit.toString() === SWISS_CURRENCY ? SWISS_CURRENCY : PERCENTAGE;
            const imageInformation = discountOffer.visuals.find(
                (image) => image.purpose === Purpose.DETAILS && isCorrectResolution(image),
            );

            if (imageInformation == null) {
                throw new Error('Image of discount offer is undefined, could not find a visual of type thumbnail');
            }

            if (imageInformation.type !== VisualTypes.IMAGE && imageInformation.type !== VisualTypes.NO_BG_IMAGE) {
                throw new Error('The image must either of type Image or type Image with no background');
            }
            const logo = discountOffer.visuals.find(
                (visual) => visual.purpose === Purpose.THUMBNAIL && isCorrectResolution(visual),
            );

            if (logo == null) {
                throw new Error('Logo is not exist');
            }

            const discountOfferImageSlider: DiscountOfferImageSlider = {
                discount: discountOffer.discount,
                id: discountOffer.affiliateId,
                discountUnit,
                logoUrl: logo.url,
                circularBubbleRgbaColor: convertHexColorAndOpacityToRgba(discountOffer.color, discountOffer.blur),
                imageUrl: imageInformation.url,
                imageType: imageInformation.type,
                brand: discountOffer.brand,
            };

            discountOffersImageSlider.push(discountOfferImageSlider);
        }
        return discountOffersImageSlider;
    }

    /**
     * Set discount offers image slider based on deals of the day list to hide them from discount offer slider
     * @param discountOffers list of discount offers
     * @param dealsOfTheDay list of deals of the day
     * @returns discount offers image slider
     */
    static getDiscountOffersImageSliderBasedOnDealsOfTheDay(
        discountOffers: AffiliateDTO[],
        dealsOfTheDay: PublishedDealOfTheDayDto[],
    ): DiscountOfferImageSlider[] {
        const dealsWithDiscountOffersToHideFromDiscountOfferSlider = dealsOfTheDay.filter(
            (dealOfTheDay) => !dealOfTheDay.showInList && dealOfTheDay.type === DealOfTheDayType.DISCOUNT_OFFER,
        );

        const affiliateIdOfDiscountOffersToHideFromDiscountOfferSlider =
            dealsWithDiscountOffersToHideFromDiscountOfferSlider.map(
                (dealOfTheDay) => (dealOfTheDay.deal as AffiliateDTO).affiliateId,
            );

        let discountOffersToDisplay = discountOffers;

        if (affiliateIdOfDiscountOffersToHideFromDiscountOfferSlider.length > ZERO_LENGTH) {
            discountOffersToDisplay = discountOffers.filter(
                (discountOffer) =>
                    !affiliateIdOfDiscountOffersToHideFromDiscountOfferSlider.includes(discountOffer.affiliateId),
            );
        }

        return DiscountOffersUtils.getDiscountOffersImageSlider(discountOffersToDisplay);
    }
}
