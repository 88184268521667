import type { IPersonDto, ISubscriptionRequest } from '@modeso/types__tsd-lib-user-be';
import type { Observable } from 'rxjs';

export const USER_SERVICE = Symbol('USER_SERVICE');

export interface IUserService {
    createOrUpdateUserProfile: (body: IPersonDto) => Observable<void>;

    deleteCurrentUserProfile: () => Observable<string>;

    getCurrentUserProfile: () => Observable<IPersonDto>;

    updateNewsletterStatus: (subscription: ISubscriptionRequest) => Observable<void>;
}
