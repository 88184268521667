import { Component, forwardRef, Input, type OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, type ControlValueAccessor } from '@angular/forms';
import type { DropDownKeyValue } from '../../pages/my-profile/my-profile.component';

@Component({
    selector: 'app-custom-select',
    templateUrl: './custom-select.component.html',
    styleUrls: ['./custom-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomSelectComponent),
            multi: true,
        },
    ],
})
export class CustomSelectComponent implements ControlValueAccessor, OnInit {
    @Input() label: string | null = null;
    @Input() options: DropDownKeyValue[] = [];
    @Input() defaultValue: DropDownKeyValue | null = null;
    @Input() isRequired: boolean = false;
    isFocused = false;
    isOpen = false;
    selectedValue!: DropDownKeyValue;

    // ControlValueAccessor interface implementation
    onChange: any = () => {};
    onTouch: any = () => {};

    ngOnInit(): void {
        if (this.defaultValue) {
            this.writeValue(this.defaultValue);
        }
    }

    writeValue(value: DropDownKeyValue): void {
        if (value != null && this.options.includes(value)) {
            this.selectedValue = value;
            this.onChange(value.value); // Ensure the form model is updated
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouch = fn;
    }

    toggleDropdown(): void {
        this.isOpen = !this.isOpen;
    }

    selectOption(option: DropDownKeyValue): void {
        this.selectedValue = option;
        this.onChange(option.value);
        this.toggleDropdown();
    }

    onFocus(): void {
        this.isFocused = true;
    }

    onBlur(): void {
        this.isFocused = false;
    }

    isSelected(option: DropDownKeyValue): boolean {
        return this.selectedValue === option;
    }
}
