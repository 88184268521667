import { Component, Input } from '@angular/core';
import { VisualTypes } from '@modeso/types__tsd-lib-products-be';

@Component({
    selector: 'app-discount-offer-deal-of-the-day',
    templateUrl: './discount-offer-deal-of-the-day.component.html',
    styleUrls: ['./discount-offer-deal-of-the-day.component.scss'],
})
export class DiscountOfferDealOfTheDayComponent {
    @Input({ required: true }) imageUrl: string | undefined;
    @Input({ required: true }) imageType: VisualTypes.IMAGE | VisualTypes.NO_BG_IMAGE | undefined;
    @Input({ required: true }) logoUrl: string | undefined;
    @Input({ required: true }) text: string | undefined;
    @Input({ required: true }) circularBubbleRgbaColor: string | undefined;

    protected readonly imageTypes = VisualTypes;
}
