import type {
    AffiliateDTO,
    INewstickerDTO,
    IShopSettingsModel,
    MerchantDTO,
    PublishedDealOfTheDayDto,
    SpecialDealDTO,
    SpecialDealResponse,
    TeaserDataDTO,
} from '@modeso/types__tsd-lib-products-be';
import type { Observable } from 'rxjs';
import type { AffiliateResponse } from '../models/affiliate.model';

export const PRODUCTS_SERVICE = Symbol('PRODUCTS_SERVICE');
export interface IProductService {
    getAffiliates: () => Observable<AffiliateResponse>;

    getAffiliateByAffiliateId: (id: string) => Observable<AffiliateDTO>;

    getPublishedNewsTicker: () => Observable<INewstickerDTO>;

    getMerchants: () => Observable<MerchantDTO[]>;

    getSuperCashback: () => Observable<TeaserDataDTO>;

    getUpcomingDeal: () => Observable<TeaserDataDTO>;

    getCampaigns: () => Observable<SpecialDealResponse>;

    getCampaignByCampaignId: (id: string) => Observable<SpecialDealDTO>;

    getDealsOfTheDay: () => Observable<PublishedDealOfTheDayDto[]>;

    getShopSettings: () => Observable<IShopSettingsModel>;
}
