import { Component, Input } from '@angular/core';
import { VisualTypes } from '@modeso/types__tsd-lib-products-be';

@Component({
    selector: 'app-news-deal-of-the-day',
    templateUrl: './news-deal-of-the-day.component.html',
    styleUrls: ['./news-deal-of-the-day.component.scss'],
})
export class NewsDealOfTheDayComponent {
    @Input({ required: true }) imageUrl: string | undefined;
    @Input({ required: true }) imageType: VisualTypes.IMAGE | VisualTypes.NO_BG_IMAGE | undefined;
    @Input({ required: true }) text: string | undefined;

    protected readonly imageTypes = VisualTypes;
}
