import { Directive, ElementRef, type AfterViewInit } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
    selector: '[appAdjustFontSize]',
})
export class AdjustFontSizeDirective implements AfterViewInit {
    readonly DEFAULT_FONT_SIZE = 14;
    private readonly resizeSubject = new Subject<void>();
    private lastFontSize: number | undefined;

    constructor(private readonly el: ElementRef) {}

    ngAfterViewInit(): void {
        // Debounce the resize logic
        this.resizeSubject
            .pipe(
                debounceTime(300), // Wait for 300ms pause in events
                untilDestroyed(this),
            )
            .subscribe(() => this.adjustFontSize());

        this.adjustFontSize();
    }

    private adjustFontSize(): void {
        const scrollPosition = window.scrollY;

        const element: HTMLElement = this.el.nativeElement as HTMLElement;
        let fontSize = this.lastFontSize || parseInt(window.getComputedStyle(element).fontSize);
        const decreaseStep = 1; // How much to decrease the font size each step

        // Create a temporary span to measure the width of the longest word
        const wordSpan = document.createElement('span');
        wordSpan.style.position = 'absolute'; // Take it out of the flow
        wordSpan.style.visibility = 'hidden'; // Hide the span so it doesn't affect layout
        wordSpan.style.whiteSpace = 'nowrap'; // Prevent line breaks
        document.body.appendChild(wordSpan); // Append to body to ensure it's in the document

        // Find the longest word
        const words = element.innerText.split(' ');
        const longestWord = words.reduce((a, b) => (a.length > b.length ? a : b), '');

        // Set the text to the longest word we're measuring
        wordSpan.innerText = longestWord;

        // Measure the word
        let wordWidth = wordSpan.offsetWidth;

        // Remove the temporary span from the document
        document.body.removeChild(wordSpan);

        // Compare the longest word's width to the container's width
        while (wordWidth > element.clientWidth && fontSize > 0) {
            fontSize -= decreaseStep;
            element.style.fontSize = `${fontSize}px`;

            // Re-measure the word width after font size change
            wordSpan.style.fontSize = `${fontSize}px`;
            document.body.appendChild(wordSpan); // Re-append to measure
            wordWidth = wordSpan.offsetWidth;
            document.body.removeChild(wordSpan); // Remove again
        }

        // After adjusting font size, store the new size
        this.lastFontSize = fontSize;

        // Restore the scroll position
        requestAnimationFrame(() => {
            if (window.scrollY !== scrollPosition && scrollPosition > 0) {
                window.scrollTo({ top: scrollPosition, behavior: 'instant' });
            }
        });
    }
}
