import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import type { Observable } from 'rxjs';
import { EMPTY, filter } from 'rxjs';
import { getLanguage } from '../utils/language';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class NavigationService {
    private previousUrl: string | undefined;
    private currentUrl: string | undefined;
    private readonly language = getLanguage();

    constructor(private readonly router: Router) {
        this.router.events
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                untilDestroyed(this),
            )
            .subscribe(() => {
                this.previousUrl = this.currentUrl;
                this.currentUrl = this.router.url;
            });
    }

    public navigateToErrorPage(): Observable<never> {
        void this.router.navigateByUrl(`${this.language}/error`);
        return EMPTY;
    }

    public navigateToPage(url: string): void {
        void this.router.navigateByUrl(url);
    }

    public navigateToPreviousPage(state?: { [k: string]: any }): void {
        if (state !== undefined) {
            void this.router.navigateByUrl(this.previousUrl ?? `${this.language}`, { state });
        } else {
            void this.router.navigateByUrl(this.previousUrl ?? `${this.language}`);
        }
    }
}
