/* eslint-disable @typescript-eslint/unbound-method */
import type { OnInit } from '@angular/core';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import type { ISubscriptionRequest } from '@modeso/types__tsd-lib-user-be';
import { Store } from '@ngrx/store';
import { AnalyticsPageName } from '../../../../domain/models/enums/page-name.enum';
import { AnalyticsActions, UserProfileActions } from '../../../../domain/store/actions/action-types';
import type { AppState } from '../../../../domain/store/reducers/app.reducer';
import { isUpdateNewsLetterStatusLoading } from '../../../../domain/store/reducers/pending.reducer';
import { selectUserProfile } from '../../../../domain/store/reducers/user-profile.reducer';
import { getFromSessionStorage } from '../../../../domain/utils/storage';
import { emailValidator } from '../../../../domain/validators/email-validator';

@Component({
    selector: 'app-newsletter',
    templateUrl: './newsletter.component.html',
    styleUrls: ['./newsletter.component.scss'],
})
export class NewsletterComponent implements OnInit {
    private readonly fb = inject(FormBuilder);
    private readonly store = inject<Store<AppState>>(Store<AppState>);
    private readonly router = inject(Router);
    private readonly destroyRef = inject(DestroyRef);

    isUpdateNewsLetterStatusLoading$ = this.store.select(isUpdateNewsLetterStatusLoading).pipe(takeUntilDestroyed());

    newsletterForm = this.fb.nonNullable.group({
        email: ['', [Validators.required, emailValidator()]],
        receiveEmails: [false],
    });

    toggle(): void {
        const control = this.newsletterForm.get('receiveEmails');
        if (control) control.setValue(!control.value);
    }

    get isSubscribingToNewsletters(): boolean {
        const control = this.newsletterForm.get('receiveEmails');
        return control ? control.value : false;
    }

    onSave(): void {
        if (!this.newsletterForm.valid) return;
        this.store.dispatch(
            UserProfileActions.updateNewsLetterStatus({ ...(this.newsletterForm.value as ISubscriptionRequest) }),
        );
    }

    goBack(): Promise<boolean> {
        return this.router.navigate(['../']);
    }

    ngOnInit(): void {
        this.store.dispatch(
            AnalyticsActions.savePageView({
                payload: {
                    productLine: null,
                    pageName: AnalyticsPageName.NEWSLETTER,
                    terminalId: getFromSessionStorage('TERMINAL_ID') ?? '',
                },
            }),
        );

        this.store.dispatch(UserProfileActions.loadUserProfile());

        this.store
            .select(selectUserProfile)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (profile) => {
                    if (profile) {
                        this.newsletterForm.patchValue({
                            email: profile.email,
                            receiveEmails: profile.receiveEmails,
                        });
                        this.newsletterForm.updateValueAndValidity();
                    }
                },
            });
    }
}
