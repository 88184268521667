<div class="campaign-information">
    <!-- #region brand, product title & expires in -->
    <div class="brand-title-expires-in">
        <div class="split-brand-and-exp">
            <!-- brand -->
            <p class="brand-title">
                <strong>{{ basicInfo?.brandName }}</strong>
            </p>
            <!-- title -->
            <p class="product-title">
                <span>{{ basicInfo?.productName }}</span>
            </p>
        </div>
        <!-- expire in -->
        <div class="count-down" *ngIf="countDownTime">
            <p class="expires-in">
                <span> {{ 'tsd_campaign_expires_in' | localize | async }}</span>
                <strong>
                    {{ countDownTime }}
                    <span *ngIf="showDayLeftMsg">{{ 'tsd_campaign_days_left' | localize | async }}</span>
                </strong>
            </p>
        </div>
    </div>
    <!-- #endregion -->

    <!-- #region availability, discount & progress bar -->
    <div class="availability">
        <!-- availability -->
        <div class="discount">
            <p class="stock-availability-percentage-value">
                <strong>{{ basicInfo?.stockAvailabilityPercentage }}% </strong>
                {{ 'tsd_stock_remaining' | localize | async }}
            </p>
            <!-- discount -->
            <p class="discount-chip">
                {{ basicInfo?.discount === 'tsd_free' ? ('tsd_free' | localize | async) : basicInfo?.discount }}
            </p>
        </div>
        <!-- progress bar -->
        <div class="stock-availability-progress">
            <mat-progress-bar
                title="stock-availability-progress"
                [value]="basicInfo?.stockAvailabilityPercentage"
                [ngClass]="{
                    low: basicInfo?.stockProgressAvailabilityMode === 'LOW',
                    medium: basicInfo?.stockProgressAvailabilityMode === 'MEDIUM',
                    high: basicInfo?.stockProgressAvailabilityMode === 'HIGH'
                }"
            >
            </mat-progress-bar>
        </div>
    </div>
    <!-- #endregion -->

    <!-- #region delivery by (merchant logo) -->
    <div class="merchant-logo">
        <p>{{ 'tsd_campaign_delivery_by' | localize | async }}</p>
        <img [src]="basicInfo?.merchantLogo" alt="merchant logo" />
    </div>
    <!-- #endregion -->

    <!-- #region description -->
    <div class="description" *ngIf="basicInfo" [innerHTML]="basicInfo.description | markdownToSafeHTML"></div>
    <!-- #endregion -->
</div>
