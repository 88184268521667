<div *ngFor="let category of categoriesWitCampaigns">
    <div class="category">
        <img class="icon" src="{{ category.iconUrl }}" alt="icon-url" />
        <div class="category-title">
            <p>{{ category.title }}</p>
        </div>
    </div>

    <div *ngFor="let campaign of category.campaigns">
        <div class="campaign-list-item">
            <app-campaign-list-item
                (click)="onCampaignClicked(campaign.id)"
                [campaignName]="campaign.campaignBrandName"
                [imageUrl]="campaign.imageUrl"
                [imageType]="campaign.imageType"
                [imagePill]="campaign.imagePill"
                [discountPercentageValue]="campaign.discountPercentageValue"
                [isOutOfStock]="campaign.isOutOfStock"
                [stockAvailabilityPercentageValue]="campaign.stockAvailabilityPercentageValue"
                [stockProgressAvailabilityMode]="campaign.stockProgressAvailabilityMode"
                [productName]="campaign.productName"
                [originalPrice]="campaign.originalPrice"
                [priceAfterDiscount]="campaign.priceAfterDiscount"
            ></app-campaign-list-item>
        </div>
    </div>
</div>
