/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable no-magic-numbers */

import type { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import type { StockAvailabilityMode } from '../../../../domain/models/enums/stock-availability-mode.enum';

export interface CampaignBasicInfo {
    brandName: string;
    productName: string;
    endDate: Date;
    stockAvailabilityPercentage: number;
    stockProgressAvailabilityMode: StockAvailabilityMode;
    discount: string | undefined;
    merchantLogo: string;
    description: string;
    hideAvailabilityBar?: boolean;
}

@Component({
    selector: 'app-campaign-information',
    templateUrl: './campaign-information.component.html',
    styleUrls: ['./campaign-information.component.scss'],
})
export class CampaignInformationComponent implements OnInit, OnDestroy, OnChanges {
    @Input({ required: true }) basicInfo?: CampaignBasicInfo;

    countDownTime = '';
    clearInterval?: ReturnType<typeof setInterval>;
    showDayLeftMsg = false;

    private pad(num: number): string {
        return String(num).padStart(2, '0');
    }

    private clearCountdown(): void {
        window.clearInterval(this.clearInterval);
    }

    private startCountdown(): void {
        if (!this.basicInfo?.endDate) return;
        this.clearInterval = setInterval(() => this.countDownAction(), 1000);
    }

    private countDownAction(): void {
        const now = new Date().getTime();
        const distance = new Date(this.basicInfo?.endDate ?? '').getTime() - now;

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // if timer reach to te end we will reset and stop
        // is days more than or equal 2 days we will show like 2 days left
        // if less than 1 day we will show timer of hours minutes and second
        if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
            this.countDownTime = '00:00:00';
            this.clearCountdown();
            this.showDayLeftMsg = false;
        } else if (days <= 0 && (hours > 0 || minutes > 0 || seconds > 0)) {
            this.countDownTime = `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
            this.showDayLeftMsg = false;
        } else {
            this.countDownTime = (days + 1).toString();
            this.showDayLeftMsg = true;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions, @typescript-eslint/no-unsafe-member-access
        if (changes['basicInfo'].currentValue != null && changes['basicInfo'].currentValue['endDate'] != null) {
            this.clearTimerInterval();
            this.countDownAction();
            this.startCountdown();
        }
    }

    private clearTimerInterval(): void {
        if (this.clearInterval) this.clearCountdown();
    }

    ngOnInit(): void {
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!this.basicInfo?.endDate) return;
        this.clearTimerInterval();
        this.startCountdown();
    }

    ngOnDestroy(): void {
        this.clearTimerInterval();
    }
}
