import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDeCH from '@angular/common/locales/de-CH';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { ModesoCoreModule } from '@modeso/modeso-lib-core-fe';
import { ModesoLocalizationModule } from '@modeso/modeso-lib-localization-fe';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import * as Hammer from 'hammerjs';
import { CountdownModule } from 'ngx-countdown';
import { PinchZoomModule } from 'ngx-pinch-zoom-16';
import { environment } from '../environments/environment';
import { REST_CLIENT_SERVICES } from './adapters/rest-client/rest-client-services';
import { CampaignAccessoryListComponent } from './adapters/view/pages/campaign-accessory-list/campaign-accessory-list.component';
import { CampaignDetailPageComponent } from './adapters/view/pages/campaign-detail-page/campaign-detail-page.component';
import { CampaignOrderSummaryComponent } from './adapters/view/pages/campaign-order-summery/campaign-order-summery.component';
import { CampaignShippingComponent } from './adapters/view/pages/campaign-shipping/campaign-shipping.component';
import { ContactComponent } from './adapters/view/pages/contact/contact.component';
import { DiscountOfferDetailPageComponent } from './adapters/view/pages/discount-offer-detail-page/discount-offer-detail-page.component';
import { DiscountOfferListComponent } from './adapters/view/pages/discount-offer-list/discount-offer-list.component';
import { GeneralErrorComponent } from './adapters/view/pages/general-error/general-error.component';
import { HomeComponent } from './adapters/view/pages/home/home.component';
import { LoginComponent } from './adapters/view/pages/login/login.component';
import { MyOrdersComponent } from './adapters/view/pages/my-orders/my-orders.component';
import { MyProfileComponent } from './adapters/view/pages/my-profile/my-profile.component';
import { NewsletterComponent } from './adapters/view/pages/newsletter/newsletter.component';
import { OneTimeUrlComponent } from './adapters/view/pages/one-time-url/one-time-url.component';
import { OrderDetailsComponent } from './adapters/view/pages/order-details/order-details.component';
import { SuperCashbackComponent } from './adapters/view/pages/super-cashback/super-cashback.component';
import { UpcomingDealsComponent } from './adapters/view/pages/upcoming-deals/upcoming-deals.component';
import { CampaignAccessoryItemComponent } from './adapters/view/pure/campaign-accessory-item/campaign-accessory-item.component';
import { CampaignImageGalleryComponent } from './adapters/view/pure/campaign-image-gallery/campaign-image-gallery.component';
import { CampaignImageSliderComponent } from './adapters/view/pure/campaign-image-slider/campaign-image-slider.component';
import { CampaignInformationComponent } from './adapters/view/pure/campaign-information/campaign-information.component';
import { CampaignListItemComponent } from './adapters/view/pure/campaign-list-item/campaign-list-item.component';
import { CampaignListComponent } from './adapters/view/pure/campaign-list/campaign-list.component';
import { CampaignRedirectToPaymentComponent } from './adapters/view/pure/campaign-redirect-to-payment/campaign-redirect-to-payment.component';
import { CustomSelectComponent } from './adapters/view/pure/custom-select/custom-select.component';
import { DealOfTheDayComponent } from './adapters/view/pure/deal-of-the-day/deal-of-the-day.component';
import { DiscountOfferButtonComponent } from './adapters/view/pure/discount-offer-button/discount-offer-button.component';
import { DiscountOfferDealOfTheDayComponent } from './adapters/view/pure/discount-offer-deal-of-the-day/discount-offer-deal-of-the-day.component';
import { DiscountOfferImageSliderComponent } from './adapters/view/pure/discount-offer-image-slider/discount-offer-image-slider.component';
import { DiscountOfferImageComponent } from './adapters/view/pure/discount-offer-image/discount-offer-image.component';
import { DiscountOfferInformationComponent } from './adapters/view/pure/discount-offer-information/discount-offer-information.component';
import { FrequentlyAskedQuestionsComponent } from './adapters/view/pure/frequently-asked-questions/frequently-asked-questions.component';
import { HeaderComponent } from './adapters/view/pure/header/header.component';
import { MenuComponent } from './adapters/view/pure/menu/menu.component';
import { MyOrdersItemComponent } from './adapters/view/pure/my-orders-item/my-orders-item.component';
import { NewsDealOfTheDayComponent } from './adapters/view/pure/news-deal-of-the-day/news-deal-of-the-day.component';
import { NewsTickerComponent } from './adapters/view/pure/news-ticker/news-ticker.component';
import { PortraitModeComponent } from './adapters/view/pure/portrait-mode/portrait-mode.component';
import { YoutubePlayerComponent } from './adapters/view/pure/youtube-player/youtube-player.component';
import { ZoomComponent } from './adapters/view/pure/zoom/zoom.component';
import { SharedModule } from './adapters/view/shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AnalyticEffects } from './domain/store/effects/analytics.effects';
import { CampaignEffects } from './domain/store/effects/campaign.effects';
import { HomeEffects } from './domain/store/effects/home.effects';
import { MerchantEffects } from './domain/store/effects/merchant.effect';
import { OrderEffects } from './domain/store/effects/order.effects';
import { SessionEffects } from './domain/store/effects/session.effects';
import { UserProfileEffects } from './domain/store/effects/user-profile.effects';
import { metaReducers, reducers } from './domain/store/reducers/app.reducer';
import { LanguageHandler } from './shared/language/browser-language.interceptor';
import { AUTH_INTERCEPTOR, AuthHandler } from './shared/security/authentication.session.interceptor';
import { SessionOrchestrator } from './shared/security/session.orchestrator';
import { VariationsManualSelectionWarningComponent } from './adapters/view/pure/variations-manual-selection-warning/variations-manual-selection-warning.component';

registerLocaleData(localeDeCH);

@Injectable()
export class HammerConfig extends HammerGestureConfig {
    override buildHammer(element: HTMLElement): HammerManager {
        const hammer = new Hammer(element);
        hammer.get('swipe').set({ velocity: 0.4, threshold: 10, direction: Hammer.DIRECTION_ALL });
        return hammer;
    }
}

@NgModule({
    declarations: [
        AppComponent,
        GeneralErrorComponent,
        OneTimeUrlComponent,
        LoginComponent,
        HomeComponent,
        NewsTickerComponent,
        DealOfTheDayComponent,
        DiscountOfferDealOfTheDayComponent,
        NewsDealOfTheDayComponent,
        CampaignListComponent,
        CampaignListItemComponent,
        HeaderComponent,
        MenuComponent,
        MyProfileComponent,
        MyOrdersComponent,
        MyOrdersItemComponent,
        OrderDetailsComponent,
        NewsletterComponent,
        ContactComponent,
        FrequentlyAskedQuestionsComponent,
        SuperCashbackComponent,
        UpcomingDealsComponent,
        DiscountOfferDetailPageComponent,
        DiscountOfferInformationComponent,
        DiscountOfferButtonComponent,
        DiscountOfferImageComponent,
        DiscountOfferListComponent,
        CampaignAccessoryListComponent,
        CampaignAccessoryItemComponent,
        CampaignOrderSummaryComponent,
        CampaignRedirectToPaymentComponent,
        CampaignShippingComponent,
        CampaignDetailPageComponent,
        CampaignImageSliderComponent,
        CampaignImageGalleryComponent,
        CampaignInformationComponent,
        YoutubePlayerComponent,
        PortraitModeComponent,
        ZoomComponent,
        DiscountOfferImageSliderComponent,
        CustomSelectComponent,
        VariationsManualSelectionWarningComponent,
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HammerModule,
        HttpClientModule,
        ModesoLocalizationModule.forRoot({
            apiGateway: environment.backendUrl,
            serviceId: 'localization',
        }),
        // store configuration
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                strictStateImmutability: true,
                strictActionImmutability: true,
                // strictActionSerializability: true,
                // strictStateSerializability: true,
            },
        }),
        EffectsModule.forRoot(
            AnalyticEffects,
            CampaignEffects,
            HomeEffects,
            OrderEffects,
            SessionEffects,
            UserProfileEffects,
            MerchantEffects,
        ),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        SharedModule,
        CountdownModule,
        FormsModule,
        ReactiveFormsModule,
        ModesoCoreModule,
        YouTubePlayerModule,
        PinchZoomModule,
    ],
    providers: [
        { provide: AUTH_INTERCEPTOR, useClass: SessionOrchestrator },
        { provide: HTTP_INTERCEPTORS, useClass: AuthHandler, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LanguageHandler, multi: true },
        ...REST_CLIENT_SERVICES,
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: HammerConfig,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
