<app-loading-spinner *ngIf="isOrdersLoading$ | async; else data" />

<ng-template #data>
    <ng-container>
        <!-- page header -->
        <app-page-header title="{{ 'tsd_my_account_orders' | localize | async }}" (backButtonClick)="goBack()" />

        <!-- my-orders.component.html -->
        <ng-container *ngIf="groupedOrders.months.length; else noTransactions">
            <div *ngFor="let month of groupedOrders.months">
                <h2>{{ month }}</h2>
                <ul>
                    <li *ngFor="let order of groupedOrders.orders[month]" (click)="onOrderClicked(order.orderId)">
                        <app-my-orders-item
                            [item]="order"
                            (loadError)="usePlaceHolderImage(order)"
                        ></app-my-orders-item>
                    </li>
                </ul>
            </div>
        </ng-container>

        <ng-template #noTransactions>
            <span class="no-history"> {{ 'tsd_no_transaction_history' | localize | async }}</span>
        </ng-template>
    </ng-container>
</ng-template>
