import type { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalizationService } from '@modeso/modeso-lib-localization-fe';
import { forkJoin, take } from 'rxjs';
import type { IAccordionData } from '../../shared/information-accordion/information-accordion.component';

const KEY_INDEX_SIZE = 3;
// Number of questions that are displayed on the page
const NUMBER_OF_QUESTIONS = 7;
@Component({
    selector: 'app-frequently-asked-questions',
    templateUrl: './frequently-asked-questions.component.html',
    styleUrls: ['./frequently-asked-questions.component.scss'],
})
export class FrequentlyAskedQuestionsComponent implements OnInit {
    questionsLocalizationKeys: IAccordionData[] = [];
    keyIndexSize = KEY_INDEX_SIZE;
    numberOfQuestions = NUMBER_OF_QUESTIONS;

    constructor(
        private readonly router: Router,
        private readonly localizationService: LocalizationService,
    ) {}

    private addLeadingZeros(num: number, size: number): string {
        let str = num + '';
        while (str.length < size) str = '0' + str;
        return str;
    }

    ngOnInit(): void {
        for (let index = 1; index <= this.numberOfQuestions; index++) {
            const keyIndex = this.addLeadingZeros(index, this.keyIndexSize);
            const titleKey = 'tsd_faq_q_' + keyIndex + '_title';
            const detailsKey = 'tsd_faq_q_' + keyIndex + '_answer';

            forkJoin({
                titleLocalization: this.localizationService.getLocalizationByKey(titleKey).pipe(take(1)),
                detailsLocalization: this.localizationService.getLocalizationByKey(detailsKey).pipe(take(1)),
            }).subscribe(({ titleLocalization, detailsLocalization }) => {
                this.questionsLocalizationKeys.push({
                    title: titleLocalization.localization.text,
                    details: detailsLocalization.localization.text,
                });
            });
        }
    }

    navigateBack(): void {
        this.router.navigate(['../']);
    }
}
