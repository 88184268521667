import { AnalyticsPageName } from './../../../../domain/models/enums/page-name.enum';
/* eslint-disable no-magic-numbers */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import type { OnInit } from '@angular/core';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import type { IAccordionCategoryResponse } from '@modeso/types__tsd-lib-products-be';
import { DiscountUnit, Purpose, VisualTypes } from '@modeso/types__tsd-lib-products-be';
import { Store } from '@ngrx/store';
import fastDeepEqual from 'fast-deep-equal/es6';
import { distinctUntilChanged } from 'rxjs';
import { PERCENTAGE, SWISS_CURRENCY } from '../../../../domain/constants/constants';
import type { SavePageViewRequest } from '../../../../domain/models/save-page-view-request.interface';
import { NavigationService } from '../../../../domain/services/navigate.service';
import { AnalyticsActions, HomeActions } from '../../../../domain/store/actions/action-types';
import type { AppState } from '../../../../domain/store/reducers/app.reducer';
import { selectDiscountOfferByDiscountOfferId } from '../../../../domain/store/reducers/home.reducer';
import { convertHexColorAndOpacityToRgba } from '../../../../domain/utils/color.util';
import { getLanguage } from '../../../../domain/utils/language';
import { getFromSessionStorage } from '../../../../domain/utils/storage';
import isCorrectResolution from '../../../../domain/utils/visuals.utils';
import type { IAccordionData } from '../../shared/information-accordion/information-accordion.component';

const TOP_PAGE_INDEX = 0;
const SCROLL_UP_TIME = 200;
@Component({
    selector: 'app-discount-offer-detail-page',
    templateUrl: './discount-offer-detail-page.component.html',
    styleUrls: ['./discount-offer-detail-page.component.scss'],
})
export class DiscountOfferDetailPageComponent implements OnInit {
    private readonly store = inject<Store<AppState>>(Store<AppState>);
    private readonly activeRoute = inject(ActivatedRoute);
    private readonly destroyRef = inject(DestroyRef);
    private readonly navigationService = inject(NavigationService);
    private readonly language = getLanguage();

    accordionData: IAccordionData[] = [];

    discount = 0;
    discountUnit = '';
    imageUrl = '';
    imageType: VisualTypes.IMAGE | VisualTypes.NO_BG_IMAGE | undefined;
    logoUrl = '';

    title = '';
    description = '';

    code = '';
    redeemLink = '';

    circularBubbleColorHexCode = '';

    private discountOfferUuid: string | undefined;
    protected displayLoadingSpinner = true;

    visitShop(): void {
        if (!this.redeemLink) return;

        if (this.discountOfferUuid != null) {
            this.dispatchPageView(AnalyticsPageName.AFFILIATE_REDEEM, this.discountOfferUuid);
        }

        window.open(this.redeemLink, '_blank');
    }

    goBack(): void {
        this.navigationService.navigateToPreviousPage();
    }

    private getDiscountOffer(id: string): void {
        this.store
            .select(selectDiscountOfferByDiscountOfferId(id))
            .pipe(
                distinctUntilChanged((previousDiscountOffer, currentDiscountOffer) =>
                    fastDeepEqual(previousDiscountOffer, currentDiscountOffer),
                ),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe({
                next: (offer) => {
                    if (!offer) return;

                    this.discountOfferUuid = offer.affiliateId;
                    this.dispatchPageView(AnalyticsPageName.AFFILIATE_SHOW, this.discountOfferUuid);

                    const imageInformation =
                        offer.visuals.find(
                            (visual) => visual.purpose === Purpose.GALLERY && isCorrectResolution(visual),
                        ) ??
                        offer.visuals.find(
                            (visual) => visual.purpose === Purpose.DETAILS && isCorrectResolution(visual),
                        );

                    if (imageInformation == null) {
                        throw new Error(
                            'Image of discount offer is undefined, could not find a visual of type details',
                        );
                    }

                    if (
                        imageInformation.type !== VisualTypes.IMAGE &&
                        imageInformation.type !== VisualTypes.NO_BG_IMAGE
                    ) {
                        throw new Error('The image must either of type Image or type Image with no background');
                    }

                    this.imageUrl = imageInformation.url;

                    this.imageType = imageInformation.type;

                    this.logoUrl = offer.visuals.find((ele) => ele.purpose === Purpose.THUMBNAIL)?.url ?? '';
                    this.discount = offer.discount;
                    this.title =
                        (offer.text.find((ele) => ele.purpose === Purpose.TITLE)?.[this.language] as string) ?? '';
                    this.description =
                        (offer.text.find((ele) => ele.purpose === Purpose.DETAILS)?.[this.language] as string) ?? '';
                    this.code = offer.promoCode ?? '';
                    this.redeemLink = (offer.redeemlink[this.language] as string) ?? '';
                    this.discountUnit = offer.discountUnit === DiscountUnit.CHF ? SWISS_CURRENCY : PERCENTAGE;
                    if (
                        offer.colorForDetails !== undefined &&
                        offer.colorForDetails !== null &&
                        offer.blurForDetails !== undefined &&
                        offer.blurForDetails !== null
                    ) {
                        this.circularBubbleColorHexCode = convertHexColorAndOpacityToRgba(
                            offer.colorForDetails,
                            offer.blurForDetails,
                        );
                    } else {
                        this.circularBubbleColorHexCode = convertHexColorAndOpacityToRgba(offer.color, offer.blur);
                    }
                    const brand = offer.brand ?? '';
                    if (offer.accordionData) {
                        this.accordionData = [...offer.accordionData]
                            .filter((item) => {
                                return Object.values(item.content).every(
                                    (content) => content !== null && content != '',
                                );
                            })
                            .sort((a, b) => {
                                // Sort the accordion data by sorting order
                                const sortingOrderA = (a.categoryID as IAccordionCategoryResponse).sortingOrder;
                                const sortingOrderB = (b.categoryID as IAccordionCategoryResponse).sortingOrder;
                                if (sortingOrderA < sortingOrderB) return -1;
                                if (sortingOrderA > sortingOrderB) return 1;
                                return 0;
                            })
                            .map((item) => {
                                let title = (item.categoryID as IAccordionCategoryResponse).title[this.language] ?? '';
                                if ((item.categoryID as IAccordionCategoryResponse).isBrandAppended) {
                                    title = `${title} ${brand}`;
                                }
                                return {
                                    // Map the accordion data to the desired format
                                    title: title,
                                    details: item.content[this.language] ?? '',
                                    imageURL: (item.categoryID as IAccordionCategoryResponse).imageURL,
                                };
                            });
                    }
                },
            });
    }

    ngOnInit(): void {
        setTimeout(() => {
            window.scrollTo({ top: TOP_PAGE_INDEX });
            this.displayLoadingSpinner = false;
        }, SCROLL_UP_TIME);

        const id = this.activeRoute.snapshot.params['id'] as string;

        if (!id) return;

        this.store.dispatch(HomeActions.loadDiscountOfferById(id));
        this.getDiscountOffer(id);
    }

    private dispatchPageView(pageName: string, discountOfferId: string): void {
        const pageViewRequest: SavePageViewRequest = {
            productLine: discountOfferId,
            pageName,
            terminalId: getFromSessionStorage('TERMINAL_ID') ?? '',
        };

        this.store.dispatch(AnalyticsActions.savePageView({ payload: pageViewRequest }));
    }
}
