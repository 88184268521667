/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import type { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import type { Observable } from 'rxjs';
import { EMPTY, catchError, map, of, switchMap } from 'rxjs';
import { CART_SERVICE, ICartService } from '../../outbound/cart.service';
import { NavigationService } from '../../services/navigate.service';
import { saveToSessionStorage } from '../../utils/storage';
import { ErrorActions, OrderActions } from '../actions/action-types';

@Injectable()
export class OrderEffects {
    constructor(
        private readonly actions$: Actions,
        @Inject(CART_SERVICE) private readonly orderService: ICartService,
        private readonly navigationService: NavigationService,
    ) {}

    private mapCartError(error?: HttpErrorResponse, fromCoupon = false): Observable<any> {
        if (!error || !error.status) return EMPTY;

        const errorObj = error.error?.error ?? {};
        const status = error.status;

        const OUT_OF_STOCK_STATUS_NUMBER = 409;
        const LOCKED_STATUS_NUMBER = 423;

        if (status === OUT_OF_STOCK_STATUS_NUMBER) {
            return of(
                ErrorActions.setError({
                    detailsPlain: JSON.stringify(errorObj),
                    status,
                    message: error.error?.message ?? 'error',
                }),
            );
        } else if (fromCoupon) {
            const detailsPlain =
                status === LOCKED_STATUS_NUMBER ? JSON.stringify(error.error) : JSON.stringify(errorObj);
            let message = '';
            if (status === LOCKED_STATUS_NUMBER) {
                message = 'exceedInvalidThreshold';
            } else if (detailsPlain.includes('Price below minimum')) {
                message = 'tsd_coupon_price_below_minimum_msg';
            } else {
                message = 'tsd_coupon_invalid_msg';
            }
            return of(
                ErrorActions.setError({
                    detailsPlain: detailsPlain,
                    status: status ?? LOCKED_STATUS_NUMBER,
                    message: message,
                }),
            );
        } else {
            setTimeout(() => {
                this.navigationService.navigateToErrorPage();
                // eslint-disable-next-line no-magic-numbers, @typescript-eslint/no-magic-numbers
            }, 1000);
            return EMPTY;
        }
    }

    loadOrders$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.loadOrders),
            switchMap(() =>
                this.orderService.getOrders().pipe(
                    map((orders) => OrderActions.ordersLoaded(orders)),
                    catchError(() => this.navigationService.navigateToErrorPage()),
                ),
            ),
        ),
    );

    loadOrdersGrouped$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.loadOrdersGrouped),
            switchMap(() =>
                this.orderService.getOrdersGrouped().pipe(
                    map((orders) => OrderActions.groupedOrdersLoaded(orders)),
                    catchError(() => this.navigationService.navigateToErrorPage()),
                ),
            ),
        ),
    );

    loadOrderByUuid$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.loadOrderByUuid),
            switchMap((action) =>
                this.orderService.getOrderByOrderUuid(action.uuid).pipe(
                    map((order) => OrderActions.orderByUuidLoaded(order)),
                    catchError(() => this.navigationService.navigateToErrorPage()),
                ),
            ),
        ),
    );

    createOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.createOrder),
            switchMap((action) =>
                this.orderService.createOrUpdateCart(action.order).pipe(
                    map((order) => {
                        saveToSessionStorage('CURRENT_PURCHASE_ORDER', order);
                        return OrderActions.orderCreated(order);
                    }),
                    catchError((error) => this.mapCartError(error)),
                ),
            ),
        ),
    );

    updateOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.updateOrder),
            switchMap((action) =>
                this.orderService.createOrUpdateCart(action.order).pipe(
                    map((order) => {
                        saveToSessionStorage('CURRENT_PURCHASE_ORDER', order);
                        return OrderActions.orderUpdated(order);
                    }),
                    catchError((error) => this.mapCartError(error)),
                ),
            ),
        ),
    );

    checkoutOrder$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.checkOutOrder),
            switchMap((action) =>
                this.orderService.checkoutCart(action.order).pipe(
                    map((checkedOutOrder) => {
                        saveToSessionStorage('CURRENT_PURCHASE_ORDER', checkedOutOrder.cart);
                        return OrderActions.orderCheckedOut(checkedOutOrder);
                    }),
                    catchError((error) => this.mapCartError(error)),
                ),
            ),
        ),
    );

    applyCoupon$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.applyCoupon),
            switchMap((action) =>
                this.orderService.applyCouponCodeDiscount(action.payload).pipe(
                    map((coupon) => OrderActions.couponApplied(coupon)),
                    catchError((error) => this.mapCartError(error, true)),
                ),
            ),
        ),
    );

    loadCouponFeature$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.loadCouponFeature),
            switchMap(() => this.orderService.getCouponFeatureState()),
            map((couponFeatureDisplayStatus) => OrderActions.couponFeatureLoaded(couponFeatureDisplayStatus)),
        ),
    );
}
