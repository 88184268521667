<div class="discount-button">
    <!-- discount code -->
    <div class="discount-text" *ngIf="promoCode">
        <img src="assets/ebene.svg" alt="code" />
        <span class="text">{{ 'tsd_discount_offer_discount_code' | localize | async }}:</span>
        <div class="code" (click)="copyCode()">
            <span class="promo-code-value" #promoCodeElement>{{ promoCode }} </span>
            <img src="assets/copy.svg" alt="" />
            <!-- toast message -->
            <div *ngIf="showToast" class="toast-message">{{ 'tsd_copied' | localize | async }}</div>
        </div>
    </div>

    <!-- button -->
    <div class="button-row">
        <button (click)="onVisitShop()">
            {{ 'tsd_discount_offer_visit_shop' | localize | async }}
            <img src="assets/visit.svg" alt="visit shop" />
        </button>
    </div>
</div>
