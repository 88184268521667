<div class="zoom-container" (swipeDown)="onSwipeDown()">
    <div class="close-icon" (click)="goBack()">
        <img src="assets/close-gray.svg" alt="close" />
    </div>
    <div class="gallery">
        <app-campaign-image-gallery
            #campaignImageGallery
            [campaignImageSlider]="sliderImages"
            [pillText]="''"
            [normalMode]="false"
        />
    </div>
</div>
