/* eslint-disable no-magic-numbers */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Location } from '@angular/common';
import type { OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import type { AppState } from '@modeso/modeso-lib-core-fe';
import type {
    ICartOperationResponse,
    ICartOrderLine,
    ICreateOrUpdateCartRequest,
} from '@modeso/types__tsd-lib-cart-be';
import type {
    IConfigPrice,
    IConfigStock,
    IDynamicConfig,
    ProductDTO,
    SpecialDealDTO,
} from '@modeso/types__tsd-lib-products-be';
import { Purpose, VisualTypes } from '@modeso/types__tsd-lib-products-be';
import { Store } from '@ngrx/store';
import fastDeepEqual from 'fast-deep-equal/es6';
import type { Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs';
import { MAIN_PRODUCT_INDEX } from '../../../../domain/constants/constants';
import type { CampaignAccessoryItem } from '../../../../domain/models/campaign-accessory-item.interface';
import type { CampaignButton } from '../../../../domain/models/campaign-button.interface';
import { AnalyticsPageName } from '../../../../domain/models/enums/page-name.enum';
import { VariationType } from '../../../../domain/models/enums/variation-type.enum';
import type { SavePageViewRequest } from '../../../../domain/models/save-page-view-request.interface';
import type { VariationConfiguration } from '../../../../domain/models/variation-configuration.interface';
import { OrderPendingEnum } from '../../../../domain/store/actions/action-names.enum';
import {
    AnalyticsActions,
    CampaignActions,
    OrderActions,
    SuccessActions,
} from '../../../../domain/store/actions/action-types';
import { selectCampaignByInternalId } from '../../../../domain/store/reducers/campaign.reducer';
import { selectCurrentPurchaseOrder } from '../../../../domain/store/reducers/order.reducer';
import { isUpdateOrderLoading } from '../../../../domain/store/reducers/pending.reducer';
import { selectSuccessKey } from '../../../../domain/store/reducers/success.reducer';
import { getLanguage } from '../../../../domain/utils/language';
import { getFromSessionStorage } from '../../../../domain/utils/storage';
import { replaceSpacesWithDashes } from '../../../../domain/utils/url-with-name.utils';
import { isCorrectResolution } from '../../../../domain/utils/visuals.utils';

@Component({
    selector: 'app-campaign-accessory-list',
    templateUrl: './campaign-accessory-list.component.html',
    styleUrls: ['./campaign-accessory-list.component.scss'],
})
export class CampaignAccessoryListComponent implements OnInit {
    private readonly store = inject<Store<AppState>>(Store<AppState>);
    private readonly router = inject(Router);
    private readonly activeRoute = inject(ActivatedRoute);
    private readonly destroyRef = inject(DestroyRef);
    private readonly cd = inject(ChangeDetectorRef);
    private readonly location = inject(Location);

    isUpdateOrderLoading$ = this.store.select(isUpdateOrderLoading);

    public items: CampaignAccessoryItem[] = [];

    private readonly addedAccessories: Map<string, CampaignAccessoryItem> = new Map();
    private currentPurchaseOrderSubscription: Subscription | undefined;

    currentPurchaseOrder: ICartOperationResponse | undefined;
    campaign: SpecialDealDTO | undefined;

    private readonly language = getLanguage();

    buttonData: CampaignButton = {
        quantity: 1,
        maximumQuantity: Number.MAX_SAFE_INTEGER,
        originalPrice: 0,
        discountedPrice: 0,
    };

    // NOTE we use this button here to just calculation purpose
    private productDiscountPrice = 0;
    private productOriginalPrice = 0;

    private calcTotalPrice(): void {
        let discountPrice = this.productDiscountPrice;
        let originalPrice = this.productOriginalPrice;
        this.addedAccessories.forEach((ele) => {
            discountPrice = discountPrice + ele.price * ele.quantity;
            originalPrice = originalPrice + ele.price * ele.quantity;
        });
        this.buttonData = {
            ...this.buttonData,
            discountedPrice: discountPrice,
            originalPrice: originalPrice,
        };
    }

    onAddToCart(campaignAccessoryItem: CampaignAccessoryItem): void {
        this.addedAccessories.set(campaignAccessoryItem.product, campaignAccessoryItem);
        this.calcTotalPrice();
    }

    onRemoveFromCart(campaignAccessoryItem: CampaignAccessoryItem): void {
        if (!this.addedAccessories.get(campaignAccessoryItem.product)) return;
        this.addedAccessories.delete(campaignAccessoryItem.product);
        this.calcTotalPrice();
    }

    /**
     * checkout button click handler
     * @param event CampaignButton object
     */
    buyNow(_event: CampaignButton): void {
        if (!this.currentPurchaseOrder) return;
        const accessoriesOrderLine: ICartOrderLine[] = Object.values(Object.fromEntries(this.addedAccessories)).map(
            (ele) => ({
                config: ele?.selectedConfig ?? { config: 'none' },
                product: ele.product,
                quantity: ele.quantity,
            }),
        );

        const orderLine = this.currentPurchaseOrder.orderLine[0];

        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (!orderLine) return;

        const { config, product, quantity } = orderLine;

        const orderPayload: ICreateOrUpdateCartRequest = {
            orderUuid: this.currentPurchaseOrder?.orderUuid,
            campaignId: this.currentPurchaseOrder?.campaignId,
            terminalId: this.currentPurchaseOrder.terminalId,
            isAgeCheckConfirmed: true,
            orderLine: [{ product: product.product, quantity, config }, ...accessoriesOrderLine],
        };

        if (this.currentPurchaseOrderSubscription != null) {
            this.currentPurchaseOrderSubscription.unsubscribe();
        }

        this.store.dispatch(OrderActions.updateOrder(orderPayload));
    }

    goBack(): void {
        this.location.back();
    }

    private getAccessoriesDealStock(product: string): IConfigStock[] {
        return this.campaign?.dealData?.productAccessory?.find((ele) => ele.productId === product)?.dealStock ?? [];
    }

    private loadAccessories(accessories: ProductDTO[] | undefined): void {
        if (!accessories) return;
        this.items = [];

        for (const accessory of accessories) {
            let description = '';
            accessory.text
                .filter((ele) => ele.purpose === Purpose.DETAILS)
                .forEach((ele) => (description += `${ele.text}</br>`));

            // #region variation list
            const variationConfigurations: VariationConfiguration[] = [];
            if (accessory.configurations.length) {
                accessory.configurations.forEach((config) => {
                    if (config.property === 'color') {
                        variationConfigurations.push({ ...config, type: VariationType.COLOR });
                    } else {
                        variationConfigurations.push({ ...config, type: VariationType.DEFAULT });
                    }
                });
            }
            // #endregion

            const dealStock = this.getAccessoriesDealStock(accessory.product);
            const currentOrderAccessoryData = this.currentPurchaseOrder?.orderLine.find(
                (ele, index) => ele.product.product === accessory.product && index > 0,
            );

            const imageInformation = accessory.visuals.find(
                (visual) => visual.purpose === Purpose.THUMBNAIL && isCorrectResolution(visual),
            );

            if (imageInformation == null) {
                throw new Error('Image is not found for an accessory');
            }

            if (imageInformation.type !== VisualTypes.IMAGE && imageInformation.type !== VisualTypes.NO_BG_IMAGE) {
                throw new Error('The image must either of type Image or type Image with no background');
            }

            const itemData: CampaignAccessoryItem = {
                product: accessory.product,
                thumbnail: imageInformation.url,
                imageType: imageInformation.type,
                brand: accessory.brand ?? '',
                productName: accessory.title,
                description,
                quantity: currentOrderAccessoryData?.quantity ?? 1,
                maximumQuantity: accessory.quantityLimit ?? Number.MAX_SAFE_INTEGER,
                price: currentOrderAccessoryData?.price ?? accessory.prices[0].price,
                maxValueAvailable: dealStock.length === 1 ? dealStock[0].remainingStock : null,
                dealStock: dealStock,
                variationConfigurations,
                prices: accessory.prices,
                selectedConfig: currentOrderAccessoryData?.config,
            };

            if (currentOrderAccessoryData) this.onAddToCart(itemData);

            this.items.push(itemData);
        }
    }

    private selectPriceForConfiguration(
        config: IDynamicConfig,
        productPrices: IConfigPrice[],
        campDealPrices: IConfigPrice[],
    ): void {
        if (config === undefined) {
            config = {
                config: 'none',
            };
        }

        let prices = productPrices ?? [];
        Object.keys(config).forEach((property) => {
            prices = prices.filter((price: IConfigPrice) => {
                return price.config[property] === config[property];
            });
        });

        let dealPrices = campDealPrices ?? [];
        Object.keys(config).forEach((property) => {
            dealPrices = dealPrices.filter((price: IConfigPrice) => {
                return price.config[property] === config[property];
            });
        });

        if (prices.length > 0) {
            this.productDiscountPrice = dealPrices[0].price * this.buttonData?.quantity;
            this.productOriginalPrice = prices[0].price * this.buttonData?.quantity;
            this.buttonData = {
                ...this.buttonData,
                originalPrice: this.productOriginalPrice,
                discountedPrice: this.productDiscountPrice,
                maximumQuantity:
                    this.campaign?.productData[MAIN_PRODUCT_INDEX].quantityLimit ?? Number.MAX_SAFE_INTEGER,
            };
        } else {
            this.buttonData = {
                originalPrice: 0,
                discountedPrice: 0,
                quantity: 0,
                maximumQuantity: 0,
            };
            this.productDiscountPrice = 0;
            this.productOriginalPrice = 0;
        }

        this.cd.detectChanges();
    }

    private loadCurrentPurchaseOrder(): void {
        this.currentPurchaseOrderSubscription = this.store
            .select(selectCurrentPurchaseOrder)
            .pipe(
                distinctUntilChanged((previousPurchaseOrder, currentPurchaseOrder) =>
                    fastDeepEqual(previousPurchaseOrder, currentPurchaseOrder),
                ),
                takeUntilDestroyed(this.destroyRef),
            )
            .subscribe({
                next: (currentPurchaseOrder) => {
                    if (!currentPurchaseOrder) {
                        void this.router.navigateByUrl(`${this.language}/error`);
                        return;
                    }
                    this.currentPurchaseOrder = currentPurchaseOrder;

                    this.dispatchPageView(currentPurchaseOrder.campaignId);

                    const productData = currentPurchaseOrder?.orderLine[0];

                    this.buttonData = { ...this.buttonData, quantity: productData.quantity };

                    this.selectPriceForConfiguration(
                        productData?.config,
                        productData?.product?.prices,
                        currentPurchaseOrder?.dealPrices,
                    );
                },
            });
    }

    private loadCampaign(id: string): void {
        this.store
            .select(selectCampaignByInternalId(id))
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (campaign) => {
                    if (!campaign) return;

                    this.campaign = campaign;
                    this.loadAccessories(campaign.dealData.productAccessoryData);
                },
            });
    }

    private listenToSuccessKey(): void {
        this.store
            .select(selectSuccessKey)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (successKey) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison, @typescript-eslint/strict-boolean-expressions
                    if (!successKey || successKey !== OrderPendingEnum.UPDATE_ORDER) return;

                    this.store.dispatch(SuccessActions.clearSuccessKey());
                    const formattedName = replaceSpacesWithDashes(this.campaign?.name);

                    void this.router.navigate([
                        this.language,
                        'campaign',
                        formattedName,
                        this.currentPurchaseOrder?.campaignId,
                        'shipping',
                    ]);
                },
            });
    }

    ngOnInit(): void {
        const id = this.activeRoute.snapshot.params['id'] as string;
        if (!id) return;
        this.store.dispatch(CampaignActions.loadCampaignById(id));

        this.loadCurrentPurchaseOrder();
        this.listenToSuccessKey();
        this.loadCampaign(id);
    }

    private dispatchPageView(campaignId: string): void {
        const pageViewRequest: SavePageViewRequest = {
            productLine: campaignId,
            pageName: AnalyticsPageName.CAMPAIGN_ACCESSORY_LIST,
            terminalId: getFromSessionStorage('TERMINAL_ID') ?? '',
        };
        this.store.dispatch(AnalyticsActions.savePageView({ payload: pageViewRequest }));
    }
}
