import type { ProductDTO, SpecialDealDTO } from '@modeso/types__tsd-lib-products-be';
import type { EntityAdapter, EntityState } from '@ngrx/entity';
import { createEntityAdapter } from '@ngrx/entity';
import type { Selector } from '@ngrx/store';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { CampaignActions } from '../actions/action-types';

export interface CampaignState extends EntityState<SpecialDealDTO> {}

function selectId(campaign: SpecialDealDTO): string {
    return campaign.internalCampaignId;
}

const adapter: EntityAdapter<SpecialDealDTO> = createEntityAdapter<SpecialDealDTO>({
    selectId,
});

const initialCampaignState: CampaignState = adapter.getInitialState({
    discount: undefined,
    couponFeatureDisplayStatus: true,
    paymentToken: undefined,
    order: undefined,
});

const campaignReducer = createReducer(
    initialCampaignState,

    on(CampaignActions.campaignsLoaded, (state, action) => adapter.setAll(action.campaigns, state)),

    on(CampaignActions.campaignByIdLoaded, (state, action) => adapter.upsertOne(action.campaign, state)),
);

const { selectAll } = adapter.getSelectors();

const campaignFeature = createFeature({
    name: 'campaign',
    reducer: campaignReducer,
    extraSelectors: ({ selectEntities, selectCampaignState }) => ({
        selectCampaignByInternalId: (internalId: string): Selector<object, SpecialDealDTO | undefined> =>
            createSelector(selectEntities, (entities) => entities[internalId]),
        selectAccessoriesByCampaignInternalId: (
            campaignInternalId: string,
        ): Selector<object, ProductDTO[] | undefined> =>
            createSelector(selectEntities, (entities) => entities[campaignInternalId]?.dealData.productAccessoryData),
        selectCampaigns: createSelector(selectCampaignState, selectAll),
    }),
});

export const { name, reducer, selectAccessoriesByCampaignInternalId, selectCampaignByInternalId, selectCampaigns } =
    campaignFeature;
