import type { OnInit } from '@angular/core';
import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { ErrorActions } from '../../../../domain/store/actions/action-types';
import type { AppState } from '../../../../domain/store/reducers/app.reducer';
import { switchToAppAndCloseWindow } from '../../../../domain/utils/switchToApp';

@Component({
    selector: 'app-general-error',
    templateUrl: './general-error.component.html',
    styleUrls: ['./general-error.component.scss'],
})
export class GeneralErrorComponent implements OnInit {
    store = inject<Store<AppState>>(Store<AppState>);

    goToTwint(): void {
        switchToAppAndCloseWindow();
    }

    ngOnInit(): void {
        this.store.dispatch(ErrorActions.clearError());
    }
}
