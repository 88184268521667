/* eslint-disable no-magic-numbers */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Location } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import type { AppState } from '@modeso/modeso-lib-core-fe';
import type { ISalesOrder } from '@modeso/types__tsd-lib-cart-be';
import { OrderStatusEnum } from '@modeso/types__tsd-lib-cart-be';
import { Purpose, VisualTypes } from '@modeso/types__tsd-lib-products-be';
import { Store } from '@ngrx/store';
import { OrderActions } from '../../../../domain/store/actions/action-types';
import { selectOrderByUuid } from '../../../../domain/store/reducers/order.reducer';
import { isOrderByUuidLoading } from '../../../../domain/store/reducers/pending.reducer';
import { getLanguage } from '../../../../domain/utils/language';
import isCorrectResolution from '../../../../domain/utils/visuals.utils';
import type { ProductSummary } from '../../shared/campaign-order-basic-information/campaign-order-basic-information.component';
import type { OrderCosts } from '../../shared/campaign-order-total/campaign-order-total.component';

@Component({
    selector: 'app-order-details',
    templateUrl: './order-details.component.html',
    styleUrls: ['./order-details.component.scss'],
})
export class OrderDetailsComponent implements OnInit {
    private readonly store = inject<Store<AppState>>(Store<AppState>);
    private readonly router = inject(Router);
    private readonly destroyRef = inject(DestroyRef);
    private readonly activateRoute = inject(ActivatedRoute);
    private readonly location = inject(Location);

    ngOnInit(): void {
        const id = (this.activateRoute.snapshot.params['id'] as string) ?? '';
        if (!id) return;
        this.store.dispatch(OrderActions.loadOrderByUuid(id));

        this.loadCurrentPurchaseOrder(id);
    }

    isOrderLoading$ = this.store.select(isOrderByUuidLoading);

    items: ProductSummary[] = [];
    language = getLanguage();
    currentOrder: ISalesOrder | undefined;

    orderCosts: OrderCosts = {
        regularPrice: 0,
        subtotalAmount: 0,
        totalAmount: 0,
        totalVAT: 0,
        totalShipping: 0,
        vatPercentage: 0,
        vatShipping: 0,
        totalDiscount: 0,
        pctDiscount: 0,
    };

    status = '';
    orderStatusEnum = OrderStatusEnum;

    showToast = false;

    navigateBack(): void {
        this.location.back();
    }

    async copyCode(code?: string): Promise<void> {
        if (!code) return;
        await navigator.clipboard.writeText(code);
        this.showToast = true;
        setTimeout(() => (this.showToast = false), 2000);
    }

    private loadCurrentPurchaseOrder(orderUuid: string): void {
        this.store
            .select(selectOrderByUuid(orderUuid))
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
                next: (currentOrder) => {
                    if (!currentOrder || !(currentOrder?.orderUuid ?? '') || currentOrder?.orderUuid != orderUuid) {
                        // void this.router.navigateByUrl(`${this.language}/error`);
                        return;
                    }
                    this.currentOrder = currentOrder;

                    this.items = [];
                    for (const order of this.currentOrder.orderLine) {
                        const imageInformation = order.product.visuals.find(
                            (visual) => visual.purpose === Purpose.THUMBNAIL && isCorrectResolution(visual),
                        );

                        if (imageInformation == null) {
                            throw new Error('Image is not found for an accessory');
                        }

                        if (
                            imageInformation.type !== VisualTypes.IMAGE &&
                            imageInformation.type !== VisualTypes.NO_BG_IMAGE
                        ) {
                            throw new Error('The image must either of type Image or type Image with no background');
                        }
                        const orderSummery: ProductSummary = {
                            productName: order.product.title[this.language] ?? '',
                            imageUrl: imageInformation.url,
                            imageType: imageInformation.type,
                            brandName: order.product.brand,
                            price: order.price * order.quantity,
                            quantity: order.quantity,
                            options: order.product.configurations.map(
                                (config) =>
                                    config.values.find((value) => value.key === order.config[config.property])?.[
                                        this.language
                                    ] ?? '',
                            ),
                        };

                        this.items.push(orderSummery);
                    }

                    this.status = OrderStatusEnum[currentOrder.orderStatus]
                        .split(/ |_/)
                        .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
                        .join(' ');

                    currentOrder?.orderCosts.forEach((item) => {
                        if (item.config['config'] === 'SUBTOTAL') {
                            this.orderCosts.regularPrice = item.price;
                            this.orderCosts.vatPercentage = item.vatInPct;
                        } else if (item.config['config'] === 'TOTAL') {
                            this.orderCosts.totalAmount = item.price;
                        } else if (item.config['config'] === 'VAT') {
                            this.orderCosts.totalVAT = item.price;
                        } else if (item.config['config'] === 'SHIPPING') {
                            this.orderCosts.totalShipping = item.price;
                            this.orderCosts.vatShipping = (item.vatInPct / 100.0) * item.price;
                        } else if (item.config['config'] === 'DISCOUNT') {
                            this.orderCosts.totalDiscount = item.price;
                        }
                    });

                    const subtotalAmount = this.orderCosts.regularPrice + this.orderCosts.totalDiscount;
                    const pctDiscount = Math.floor(
                        -1 * (100.0 - (subtotalAmount / this.orderCosts.regularPrice) * 100.0),
                    );

                    this.orderCosts = { ...this.orderCosts, subtotalAmount, pctDiscount };

                    if (currentOrder.couponData) {
                        this.orderCosts = {
                            ...this.orderCosts,
                            couponDiscount: currentOrder?.couponData?.discountAmount,
                            discountPercentage: currentOrder?.couponData?.discountPercentage,
                        };
                    }
                },
            });
    }
}
