import { DialogRef } from '@angular/cdk/dialog';
import { Component } from '@angular/core';

@Component({
    selector: 'app-tsd-campaign-limit-exceeded-popup',
    templateUrl: './tsd-campaign-limit-exceeded-popup.html',
    styleUrls: ['./tsd-campaign-limit-exceeded-popup.scss'],
})
export class TsdCampaignLimitExceededPopupComponent {
    constructor(private readonly dialogRef: DialogRef) {}
    public dismiss(): void {
        this.dialogRef.close();
    }
}
