import type { IVisuals } from '@modeso/types__tsd-lib-products-be';

const SELECTED_RESOLUTION = 3;

export const isCorrectResolution = (visual: IVisuals): boolean => {
    let result = true;
    // if we have legacy resolutions, we select this image if its resolution is 3
    // if we have no legacy resolutions, we select this image no matter what.
    if (visual.resolution !== undefined && visual.resolution !== null && visual.resolution !== SELECTED_RESOLUTION) {
        result = false;
    }
    return result;
};

export default isCorrectResolution;
