import type { AfterViewInit, OnInit } from '@angular/core';
import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import Flickity from 'flickity';
import type { DiscountOfferImageSlider } from '../../../../domain/models/discount-offer-image-slider.interface';
import { getLanguage } from '../../../../domain/utils/language';
import { replaceSpacesWithDashes } from '../../../../domain/utils/url-with-name.utils';

const MAX_OFFERS_TO_SHOW = 6;
const FIRST_DISCOUNT_OFFER = 0;

@Component({
    selector: 'app-discount-offer-image-slider',
    templateUrl: './discount-offer-image-slider.component.html',
    styleUrls: ['./discount-offer-image-slider.component.scss'],
})
export class DiscountOfferImageSliderComponent implements OnInit, AfterViewInit {
    @Input({ required: true }) discountOffers: DiscountOfferImageSlider[] = [];
    protected discountOffersToView: DiscountOfferImageSlider[] = [];
    @ViewChild('carouselElement', { static: true }) carouselElement!: ElementRef;

    private flickitySlider: Flickity | undefined;

    readonly MAX_OFFERS_TO_SHOW = MAX_OFFERS_TO_SHOW;

    constructor(
        private readonly router: Router,
        private readonly cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        if (this.discountOffers.length > MAX_OFFERS_TO_SHOW) {
            this.discountOffersToView = this.discountOffers.slice(FIRST_DISCOUNT_OFFER, MAX_OFFERS_TO_SHOW);
        } else {
            this.discountOffersToView = this.discountOffers;
        }
    }

    ngAfterViewInit(): void {
        this.initCarousel();
    }

    protected onSlideClicked(id: string): void {
        const language = getLanguage();
        const discountOfferBrandName = this.discountOffers.find((offer) => offer.id === id)?.brand;
        const formattedName = replaceSpacesWithDashes(discountOfferBrandName);
        void this.router.navigate([`${language}`, 'discountoffers', formattedName, id]);
    }

    protected onShowMoreClicked(): void {
        const language = getLanguage();
        void this.router.navigate([`${language}`, 'discountoffers']);
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.flickitySlider?.resize(); // Trigger Flickity to update its layout
    }

    initCarousel(): void {
        // Trigger change detection to ensure view is updated
        this.cdr.detectChanges();

        // Ensure slides are in the DOM
        if (this.carouselElement == null) {
            return;
        }

        this.flickitySlider = new Flickity(this.carouselElement.nativeElement as HTMLElement, {
            // Flickity options here
            freeScroll: false,
            contain: true,
            pageDots: false,
            percentPosition: false,
            lazyLoad: true,
            setGallerySize: false,
            prevNextButtons: false, // Hide default buttons
            friction: 0.23, // Higher friction makes the slider feel stickier and less bouncy
        });
    }
}
