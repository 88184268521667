import type {
    AffiliateDTO,
    INewstickerDTO,
    IShopSettingsModel,
    PublishedDealOfTheDayDto,
    TeaserDataDTO,
} from '@modeso/types__tsd-lib-products-be';
import type { EntityState } from '@ngrx/entity';
import { createEntityAdapter } from '@ngrx/entity';
import type { Selector } from '@ngrx/store';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { HomeActions } from '../actions/action-types';

export interface HomeState {
    discountOffers: EntityState<AffiliateDTO>;
    superCashBack: TeaserDataDTO | undefined;
    upcomingDeal: TeaserDataDTO | undefined;
    newsTicker: INewstickerDTO | undefined;
    dealsOfTheDay: EntityState<PublishedDealOfTheDayDto>;
    shopSettings: IShopSettingsModel | undefined;
}

function selectDiscountOfferId(discountOffer: AffiliateDTO): string {
    return discountOffer.affiliateId;
}

function selectDealOfTheDayId(dealOfTheDay: PublishedDealOfTheDayDto): string {
    return dealOfTheDay.id;
}

const discountOfferAdapter = createEntityAdapter<AffiliateDTO>({
    selectId: selectDiscountOfferId,
});

const dealsOfTheDayAdapter = createEntityAdapter<PublishedDealOfTheDayDto>({
    selectId: selectDealOfTheDayId,
});

const initialHomeState: HomeState = {
    discountOffers: discountOfferAdapter.getInitialState(),
    superCashBack: undefined,
    upcomingDeal: undefined,
    newsTicker: undefined,
    dealsOfTheDay: dealsOfTheDayAdapter.getInitialState(),
    shopSettings: undefined,
};

const homeReducer = createReducer(
    initialHomeState,
    on(
        HomeActions.superCashBackLoaded,
        (state, action) => ({ ...state, superCashBack: action.superCashBack }) satisfies HomeState,
    ),

    on(
        HomeActions.upcomingDealLoaded,
        (state, action) => ({ ...state, upcomingDeal: action.upcomingDeal }) satisfies HomeState,
    ),

    on(
        HomeActions.newsTickerLoaded,
        (state, action) => ({ ...state, newsTicker: action.newsTicker }) satisfies HomeState,
    ),

    on(HomeActions.discountOffersLoaded, (state, action) => {
        const discountOffers = discountOfferAdapter.setAll(action.discountOffers, state.discountOffers);
        return { ...state, discountOffers } satisfies HomeState;
    }),
    on(
        HomeActions.shopSettingsLoaded,
        (state, action) => ({ ...state, shopSettings: action.shopSettings }) satisfies HomeState,
    ),

    on(HomeActions.discountOfferLoaded, (state, action) => {
        const discountOffers = discountOfferAdapter.upsertOne(action.discountOffer, state.discountOffers);
        return { ...state, discountOffers } satisfies HomeState;
    }),

    on(HomeActions.dealsOfTheDayLoaded, (state, action) => {
        const dealsOfTheDay = dealsOfTheDayAdapter.setAll(action.dealsOfTheDay, state.dealsOfTheDay);
        return { ...state, dealsOfTheDay } satisfies HomeState;
    }),
);

const selectAllDiscountOffers = discountOfferAdapter.getSelectors().selectAll;
const selectAllDealsOfTheDay = dealsOfTheDayAdapter.getSelectors().selectAll;

const homeFeature = createFeature({
    name: 'home',
    reducer: homeReducer,
    extraSelectors: ({
        selectDiscountOffers,
        selectSuperCashBack,
        selectUpcomingDeal,
        selectHomeState,
        selectDealsOfTheDay,
    }) => ({
        selectDiscountOfferByDiscountOfferId: (discountOfferId: string): Selector<object, AffiliateDTO | undefined> =>
            createSelector(selectHomeState, (state) => state.discountOffers.entities[discountOfferId]),
        selectSuperCashBackDisplayStatus: createSelector(
            selectSuperCashBack,
            (superCashBack: TeaserDataDTO | undefined) => superCashBack?.show,
        ),
        selectUpcomingDealDisplayStatus: createSelector(
            selectUpcomingDeal,
            (upcomingDeal: TeaserDataDTO | undefined) => upcomingDeal?.show,
        ),
        selectDiscountOffers: createSelector(selectDiscountOffers, selectAllDiscountOffers),
        selectDealsOfTheDay: createSelector(selectDealsOfTheDay, selectAllDealsOfTheDay),
    }),
});

export const {
    name,
    reducer,
    selectNewsTicker,
    selectUpcomingDealDisplayStatus,
    selectUpcomingDeal,
    selectSuperCashBackDisplayStatus,
    selectSuperCashBack,
    selectDiscountOfferByDiscountOfferId,
    selectDiscountOffers,
    selectDealsOfTheDay,
    selectShopSettings,
} = homeFeature;
