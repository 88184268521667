<div class="logo-container">
    <img src="assets/twint.svg" alt="TWINT Logo" />
</div>

<div class="message-container">
    <img src="assets/payment-icon.svg" alt="payment icon" />
    <div>
        <p>{{ 'tsd_redirect_to_payment_message' | localize | async }}</p>
    </div>
</div>

<div class="button-container">
    <button (click)="onButtonAction()">
        {{ 'tsd_back_to_twint' | localize | async }}
    </button>
</div>
