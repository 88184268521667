import hexRgb from 'hex-rgb';

const ALPHA = 1;

export function convertHexColorAndOpacityToRgba(colorHexCode: string, opacity: number): string {
    let opacityStringValue: string;

    if (opacity > ALPHA) {
        opacityStringValue = `${opacity}%`;
    } else {
        opacityStringValue = `${opacity}`;
    }
    const { red, green, blue } = hexRgb(colorHexCode);
    return `rgba(${red}, ${green}, ${blue}, ${opacityStringValue})`;
}
