import type { Action } from '@ngrx/store';
import { createFeature, createReducer, on } from '@ngrx/store';
import { SuccessActions, allActionTypes } from '../actions/action-types';

export interface SuccessState {
    successKey: string | undefined;
}

export const initialState: SuccessState = {
    successKey: undefined,
};

const reducer = createReducer(
    initialState,
    on(...allActionTypes, (state, action: Action & { success?: string }) => {
        const successKey = action.success ?? '';
        let newState = { ...state };
        if (successKey) {
            newState = {
                ...state,
                successKey,
            };
        }
        return newState;
    }),
    on(SuccessActions.clearSuccessKey, (_state, _action) => {
        return {
            successKey: undefined,
        };
    }),
);

const successFeature = createFeature({ name: 'success', reducer });

export const {
    name: successFeatureKey,
    reducer: successReducer,

    // selectors
    selectSuccessKey,
} = successFeature;
