import { DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-coupon-limit',
    templateUrl: './coupon-limit.component.html',
    styleUrls: ['./coupon-limit.component.scss'],
})
export class CouponLimitComponent {
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onDismiss: EventEmitter<void> = new EventEmitter<void>();

    constructor(private readonly dialogRef: DialogRef) {}
    public dismiss(): void {
        this.onDismiss.emit();
        this.dialogRef.close();
    }
}
