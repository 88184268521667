import type { MultiLanguagesString } from '@modeso/types__tsd-lib-products-be';

export const getLanguage = (): Required<keyof MultiLanguagesString> => {
    const browserLanguage = navigator.language;
    if (browserLanguage.includes('de')) {
        return 'de-ch';
    } else if (browserLanguage.includes('fr')) {
        return 'fr-ch';
    } else if (browserLanguage.includes('it')) {
        return 'it-ch';
    } else {
        return 'en-us';
    }
};
