<div *ngIf="item" class="campaign-accessory-container">
    <img *ngIf="!isAddedToCart" class="addedicon" src="assets/checkedbubble.svg" />
    <div class="logo-description">
        <div class="right-box">
            <img
                [src]="item.thumbnail"
                alt="{{ item.productName }}"
                class="product-image"
                [ngClass]="{ 'product-image-with-no-background': item.imageType === imageTypes.NO_BG_IMAGE }"
            />
        </div>

        <div class="product-details">
            <h2 class="product-brand">{{ item.brand }}</h2>
            <p class="product-name">{{ item.productName }}</p>
            <p class="product-description" [innerHTML]="item.description | markdownToSafeHTML"></p>
        </div>
    </div>
    <div class="variations" *ngIf="item?.variationConfigurations?.length">
        <app-campaign-variation-list
            [configurations]="item.variationConfigurations || []"
            [dealStock]="item.dealStock || []"
            [orderLineVariation]="item.selectedConfig"
            [manualVariationSelection]="false"
            (selectVariation)="selectVariation($event)"
        />
    </div>
    <div class="quantity-price">
        <div class="quantity">
            <button class="quantity_btn_left" (click)="decrease()" [disabled]="item.quantity === MINIMUM_COUNTER_VALUE">
                -
            </button>
            <span class="quantity_value no-zoom">{{ item.quantity }}</span>
            <button
                class="quantity_btn_right"
                (click)="increase()"
                [disabled]="
                    (originalItem != null && item.quantity >= originalItem.maximumQuantity) ||
                    (item.maxValueAvailable != null && item.quantity >= item.maxValueAvailable)
                "
            >
                +
            </button>
        </div>
        <p class="price">
            {{ !totalPrice ? ('tsd_free' | localize | async) : currency + ' ' + (totalPrice | currencyNumber) }}
        </p>
    </div>

    <button class="action-btn" (click)="onAddOrRemove()" [disabled]="item.quantity < 1">
        {{ (isAddedToCart ? 'tsd_add' : 'tsd_remove') | localize | async }}
    </button>
</div>
