import type { MerchantDTO } from '@modeso/types__tsd-lib-products-be';
import { createAction } from '@ngrx/store';
import { MerchantPendingEnum, PrefixEnum } from './action-names.enum';

export const LoadMerchants = createAction(
    '[Home] Load merchants',
    (pending: string = `${PrefixEnum.BEGIN}_${MerchantPendingEnum.LOAD_MERCHANTS}`) => ({ pending }),
);

export const merchantsLoaded = createAction(
    '[Home] Merchants Loaded',
    (merchants: MerchantDTO[], pending: string = `${PrefixEnum.END}_${MerchantPendingEnum.LOAD_MERCHANTS}`) => ({
        merchants,
        pending,
    }),
);
