import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getLanguage } from '../../domain/utils/language';
import type { IAuthHandlerDelegate } from './authentication.session.interceptor';

@Injectable({
    providedIn: 'root',
})
export class SessionOrchestrator implements IAuthHandlerDelegate {
    language = getLanguage();

    constructor(private readonly router: Router) {}

    onSessionExpired(): void {
        if (
            !this.router.isActive(`${this.language}/login`, {
                paths: 'subset',
                queryParams: 'subset',
                fragment: 'ignored',
                matrixParams: 'ignored',
            })
        ) {
            void this.router.navigateByUrl(`${this.language}/login`);
        }
    }
}
