<div class="account-container" [ngClass]="{ 'overlay-div': isMenuOpen }">
    <div class="overlay-container my-overlay">
        <div class="supercashback" (click)="goToUrl('supercashback')" *ngIf="isSuperCashbackVisible && !isMenuOpen">
            <img src="assets/Super_Cashbac_Icon.svg" alt="Super Cash" class="center-image" />
        </div>

        <div id="lottie-container" #lottieContainer (click)="toggleMenu()" [matMenuTriggerFor]="menu"></div>
    </div>

    <mat-menu #menu="matMenu" class="home-menu" (close)="close()">
        <ng-container *ngFor="let menuItem of menuArray">
            <button class="menu-item" mat-menu-item [disableRipple]="true" (click)="menuItem.action()">
                <span class="column1">
                    <img [src]="menuItem.iconLink" class="img-dimension" />
                </span>
                <span class="column2 text-style" [ngStyle]="{ color: menuItem.fontColor }">
                    {{ menuItem.textLink | localize | async }}
                </span>
            </button>
        </ng-container>
    </mat-menu>
</div>
