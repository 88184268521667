import { Component, inject, type OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import type { AppState } from '@modeso/modeso-lib-core-fe';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import fastDeepEqual from 'fast-deep-equal/es6';
import { combineLatestWith, distinctUntilChanged, filter } from 'rxjs';
import { ZERO_LENGTH } from '../../../../domain/constants/constants';
import type { DiscountOfferImageSlider } from '../../../../domain/models/discount-offer-image-slider.interface';
import { AnalyticsPageName } from '../../../../domain/models/enums/page-name.enum';
import { AnalyticsActions } from '../../../../domain/store/actions/action-types';
import { loadDiscountOffers } from '../../../../domain/store/actions/home.actions';
import { selectDealsOfTheDay, selectDiscountOffers } from '../../../../domain/store/reducers/home.reducer';
import { isDiscountOffersLoading } from '../../../../domain/store/reducers/pending.reducer';
import { getFromSessionStorage } from '../../../../domain/utils/storage';
import { DiscountOffersUtils } from './../../../../domain/utils/discount-offers.utils';

const DISCOUNT_OFFERS_INDEX = 0;
@UntilDestroy()
@Component({
    selector: 'app-discount-offer-list',
    templateUrl: './discount-offer-list.component.html',
    styleUrls: ['./discount-offer-list.component.scss'],
})
export class DiscountOfferListComponent implements OnInit {
    protected discountOffersImageSlider: DiscountOfferImageSlider[] = [];

    private readonly store = inject<Store<AppState>>(Store<AppState>);
    private readonly router = inject(Router);

    protected isDiscountOffersLoading$ = this.store.select(isDiscountOffersLoading).pipe(takeUntilDestroyed());
    protected zeroLength = ZERO_LENGTH;

    ngOnInit(): void {
        this.dispatchDiscountOffers();
        this.dispatchAnalytics();
        this.getDiscountOffers();
    }

    goBack(): Promise<boolean> {
        return this.router.navigate(['../']);
    }

    /**
     * Dispatch discount offers to load them
     */
    private dispatchDiscountOffers(): void {
        this.store.dispatch(loadDiscountOffers());
    }

    /**
     * Dispatch analytics to save page view
     */
    private dispatchAnalytics(): void {
        this.store.dispatch(
            AnalyticsActions.savePageView({
                payload: {
                    productLine: null,
                    pageName: AnalyticsPageName.DISCOUNT_OFFERS,
                    terminalId: getFromSessionStorage('TERMINAL_ID') ?? '',
                },
            }),
        );
    }

    /**
     * Get discount offers from store and load deals of the day to hide them from discount offer slider
     */
    private getDiscountOffers(): void {
        const discountOffers$ = this.store.select(selectDiscountOffers).pipe(
            filter((discountOffers) => discountOffers != null && discountOffers.length > ZERO_LENGTH),
            distinctUntilChanged((previousDiscountOffers, currentDiscountOffers) =>
                fastDeepEqual(previousDiscountOffers, currentDiscountOffers),
            ),
            untilDestroyed(this),
        );

        // load deals of the day => to hide them from discount offer slider
        const dealsOfTheDays$ = this.store.select(selectDealsOfTheDay).pipe(
            filter((dealsOfTheDay) => dealsOfTheDay != null),
            distinctUntilChanged((previousDealOfTheDay, currentDealOfTheDay) =>
                fastDeepEqual(previousDealOfTheDay, currentDealOfTheDay),
            ),
            untilDestroyed(this),
        );

        discountOffers$
            .pipe(
                combineLatestWith(dealsOfTheDays$),
                filter((combination) => combination[DISCOUNT_OFFERS_INDEX].length > ZERO_LENGTH),
                untilDestroyed(this),
            )
            .subscribe(
                ([discountOffers, dealsOfTheDay]) =>
                    (this.discountOffersImageSlider =
                        DiscountOffersUtils.getDiscountOffersImageSliderBasedOnDealsOfTheDay(
                            discountOffers,
                            dealsOfTheDay,
                        )),
            );
    }
}
