<app-loading-spinner *ngIf="(isCreateOrderLoading$ | async) || displayLoadingSpinner; else showContentsOfThePage" />
<ng-template #showContentsOfThePage>
    <!-- page header -->
    <app-page-header (backButtonClick)="goBack()" />
    <div class="wrapper">
        <!-- product image slider -->
        <app-campaign-image-gallery [campaignImageSlider]="campaignImageSlider" [pillText]="imagePillText" />

        <!-- general information -->
        <app-campaign-information [basicInfo]="basicInfo" />

        <!-- variations -->
        <app-campaign-variation-list
            *ngIf="variationConfigurations.length > 0"
            [configurations]="variationConfigurations"
            [dealStock]="dealStock"
            [orderLineVariation]="selectedConfig"
            [manualVariationSelection]="manualVariationSelection"
            (selectVariation)="selectVariation($event)"
        />

        <!-- accordion info -->
        <app-information-accordion *ngIf="accordionData.length" [data]="accordionData" />

        <!-- checkout button -->
        <app-campaign-button
            [label]="'tsd_campaignButton_BuyNow' | localize | async"
            (buyNowEvent)="buyBow($event)"
            (inactiveButtonClicked)="onInactiveButtonClicked()"
            [data]="buttonData"
            [isOutOfStock]="isOutOfStock"
            [disabled]="((isCreateOrderLoading$ | async) ?? false) || isOutOfStock"
            [inactive]="manualVariationSelection && selectedConfig == null"
        />
    </div>
</ng-template>
