export enum AnalyticsPageName {
    AFFILIATE_SHOW = 'Affiliate-show',
    AFFILIATE_REDEEM = 'Affiliate-redeem',
    DISCOUNT_OFFERS = 'Discount Offers',
    CAMPAIGN_ACCESSORY_LIST = 'campaign-accessory-list',
    CAMPAIGN_DETAILS = 'campaign-details',
    ORDER_NOW_CLICKED = 'Order-Now-Clicked',
    ORDER_SUMMARY = 'Order-Summery',
    CAMPAIGN_SHIPPING = 'campaign-shipping',
    CONTACT = 'contact',
    MY_ORDERS = 'my-orders',
    MY_PROFILE = 'my-profile',
    NEWSLETTER = 'newsletter',
    START = 'START',
    SUPER_CASH_BACK = 'supercashback',
    UPCOMING_DEALS = 'upcomingdeals',
}
