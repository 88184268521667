/* eslint-disable @typescript-eslint/naming-convention */
import { InjectionToken } from '@angular/core';
import type * as DOMPurify from 'dompurify';

export const DOMPURIFY_CONFIG = new InjectionToken<DOMPurify.Config>('dompurify.config');

export const MODESO_DEFAULT_DOMPURIFY_CONFIG: DOMPurify.Config & {
    RETURN_DOM_FRAGMENT?: false | undefined;
    RETURN_DOM?: false | undefined;
} = {
    FORBID_ATTR: ['href'],
    RETURN_DOM_FRAGMENT: false,
    RETURN_DOM: false,
    ADD_TAGS: ['img'],
    ADD_ATTR: ['src', 'alt', 'width', 'height'],
};
