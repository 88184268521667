import { formatNumber } from '@angular/common';
import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';

@Pipe({
    name: 'currencyNumber',
})
export class CurrencyNumberPipe implements PipeTransform {
    static format(value: number): string {
        try {
            return formatNumber(value, 'de-CH', '1.2-2');
        } catch (error) {
            return value.toString();
        }
    }

    transform(value: number): string {
        return CurrencyNumberPipe.format(value);
    }
}
