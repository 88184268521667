import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type {
    GroupedOrders,
    ICartOperationResponse,
    ICheckoutCartRequest,
    ICheckoutResponse,
    ICreateOrUpdateCartRequest,
    ISalesOrder,
} from '@modeso/types__tsd-lib-cart-be';
import type { CouponValidationDTO, ICodeValidityCheckRequest } from '@modeso/types__twint-lib-coupons';
import { environment } from 'projects/tsd-project-shop/src/environments/environment';
import type { Observable } from 'rxjs';
import type { ICartService } from '../../domain/outbound/cart.service';
@Injectable()
export class SalesOrderService implements ICartService {
    private readonly SALES_ORDERS_ENDPOINT_BASE_URL = environment.backendUrl + `salesorders`;
    private readonly COUPON_ENDPOINT_BASE_URL = this.SALES_ORDERS_ENDPOINT_BASE_URL + `/coupons`;

    constructor(private readonly http: HttpClient) {}

    createOrUpdateCart(request: ICreateOrUpdateCartRequest): Observable<ICartOperationResponse> {
        const url = `${this.SALES_ORDERS_ENDPOINT_BASE_URL}/create`;
        return this.http.post<ICartOperationResponse>(url, request);
    }

    checkoutCart(request: ICheckoutCartRequest): Observable<ICheckoutResponse> {
        const url = this.SALES_ORDERS_ENDPOINT_BASE_URL + `/checkout`;
        return this.http.post<ICheckoutResponse>(url, request);
    }

    getOrders(): Observable<ISalesOrder[]> {
        const url = this.SALES_ORDERS_ENDPOINT_BASE_URL;
        return this.http.get<ISalesOrder[]>(url);
    }

    getOrdersGrouped(): Observable<GroupedOrders> {
        const url = this.SALES_ORDERS_ENDPOINT_BASE_URL + `/grouped`;
        return this.http.get<GroupedOrders>(url);
    }

    getOrderByOrderUuid(orderUuid: string): Observable<ISalesOrder> {
        const url = `${this.SALES_ORDERS_ENDPOINT_BASE_URL}/${orderUuid}`;
        return this.http.get<ISalesOrder>(url);
    }

    getCouponFeatureState(): Observable<boolean> {
        const url = `${this.COUPON_ENDPOINT_BASE_URL}/state`;
        return this.http.get<boolean>(url);
    }

    applyCouponCodeDiscount(body: ICodeValidityCheckRequest): Observable<CouponValidationDTO> {
        const url = `${this.COUPON_ENDPOINT_BASE_URL}/validateCode`;
        return this.http.post<CouponValidationDTO>(url, body);
    }
}
