import { DialogRef } from '@angular/cdk/dialog';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface IData {
    minUserAge: number;
    onDismiss: (val?: boolean) => void;
}
@Component({
    selector: 'app-tsd-user-age-check',
    templateUrl: './tsd-user-age-check.component.html',
    styleUrls: ['./tsd-user-age-check.component.scss'],
})
export class TsdUserAgeCheckComponent {
    @Input() minUserAge: number = 18;
    @Output() onDismiss: EventEmitter<boolean> = new EventEmitter<boolean>();
    constructor(
        private readonly dialogRef: DialogRef,
        @Inject(MAT_DIALOG_DATA) public data: IData,
    ) {}
    public dismiss(confirm: boolean = false): void {
        if (this.data?.onDismiss) {
            this.data.onDismiss(confirm);
        }
        this.onDismiss.emit(confirm);
        this.dialogRef.close();
    }

    public replaceText(text: string | null): string {
        return (text || '').replace('{minUserAge}', (this.data?.minUserAge || this.minUserAge || 0).toString());
    }
}
