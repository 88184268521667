import type { GroupedOrders, ICartOperationResponse, ISalesOrder } from '@modeso/types__tsd-lib-cart-be';
import type { CouponValidationDTO } from '@modeso/types__twint-lib-coupons';
import type { EntityAdapter } from '@ngrx/entity';
import { createEntityAdapter, type EntityState } from '@ngrx/entity';
import type { Selector } from '@ngrx/store';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { getFromSessionStorage } from '../../utils/storage';
import { OrderActions } from '../actions/action-types';

export interface OrdersState extends EntityState<ISalesOrder> {
    discount: CouponValidationDTO | undefined;
    couponFeatureDisplayStatus: boolean;
    paymentToken: string | undefined;
    currentPurchaseOrder: ICartOperationResponse | undefined;
    groupOrdersByMonth: GroupedOrders;
}

function selectId(order: ISalesOrder): string {
    if (order.orderUuid == null) {
        throw new Error('Order uuid is undefined or null');
    }
    return order.orderUuid;
}

const adapter: EntityAdapter<ISalesOrder> = createEntityAdapter<ISalesOrder>({
    selectId,
});

const initialOrderState: OrdersState = adapter.getInitialState({
    discount: undefined,
    couponFeatureDisplayStatus: false,
    paymentToken: undefined,
    currentPurchaseOrder: getFromSessionStorage<ICartOperationResponse>('CURRENT_PURCHASE_ORDER'),
    groupOrdersByMonth: {
        months: [],
        orders: {},
    },
});

const orderReducer = createReducer(
    initialOrderState,

    on(OrderActions.ordersLoaded, (state, action) => adapter.setAll(action.orders, state)),

    on(OrderActions.orderByUuidLoaded, (state, action) => adapter.setOne(action.order, state)),

    on(OrderActions.groupedOrdersLoaded, (state, action) => ({
        ...state,
        groupOrdersByMonth: action.groupedOrders,
    })),

    on(
        OrderActions.orderCreated,
        (state, action) => ({ ...state, currentPurchaseOrder: action.order }) satisfies OrdersState,
    ),

    on(
        OrderActions.orderUpdated,
        (state, action) => ({ ...state, currentPurchaseOrder: action.order }) satisfies OrdersState,
    ),

    on(
        OrderActions.orderCheckedOut,
        (state, action) =>
            ({
                ...state,
                currentPurchaseOrder: action.checkedOutOrder.cart,
                paymentToken: action.checkedOutOrder.registrationToken,
            }) satisfies OrdersState,
    ),

    on(OrderActions.applyCoupon, (state, _action) => ({
        ...state,
        discount: undefined,
    })),

    on(OrderActions.couponApplied, (state, action) => ({ ...state, discount: action.coupon }) satisfies OrdersState),

    on(
        OrderActions.couponFeatureLoaded,
        (state, action) =>
            ({
                ...state,
                couponFeatureDisplayStatus: action.couponFeatureDisplayStatus,
            }) satisfies OrdersState,
    ),

    on(
        OrderActions.resetPurchaseInformation,
        (state: OrdersState) =>
            ({
                ...state,
                discount: undefined,
                paymentToken: undefined,
                currentPurchaseOrder: undefined,
            }) satisfies OrdersState,
    ),
);

const { selectAll } = adapter.getSelectors();

const orderFeature = createFeature({
    name: 'order',
    reducer: orderReducer,
    extraSelectors: ({ selectEntities, selectOrderState }) => ({
        selectOrderByUuid: (orderUuid: string): Selector<object, ISalesOrder | undefined> =>
            createSelector(selectEntities, (entities) => entities[orderUuid]),
        selectOrders: createSelector(selectOrderState, selectAll),
    }),
});

export const {
    name,
    reducer,
    selectOrderByUuid,
    selectDiscount,
    selectCouponFeatureDisplayStatus,
    selectPaymentToken,
    selectCurrentPurchaseOrder,
    selectOrders,
    selectGroupOrdersByMonth,
} = orderFeature;
