import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import type { ErrorMessage } from '../../models/error-message.model';
import { ErrorActions } from '../actions/action-types';

export interface ErrorState extends ErrorMessage {}

export const initialState: ErrorState = {
    message: undefined,
    details: undefined,
    detailsPlain: undefined,
    status: undefined,
};

const reducer = createReducer(
    initialState,
    on(ErrorActions.setError, (_state, action) => {
        return {
            message: action.message ?? undefined,
            details: action.details ?? undefined,
            detailsPlain: action.detailsPlain ?? undefined,
            status: action.status ?? undefined,
        };
    }),
    on(ErrorActions.clearError, (_state, _action) => {
        return {
            message: undefined,
            details: undefined,
            detailsPlain: undefined,
            status: undefined,
        };
    }),
);

// this the new way of creating reducer and selectors
// help reduce code boilerplate
const errorFeature = createFeature({
    name: 'error',
    reducer,
    extraSelectors: ({ selectMessage, selectDetails }) => ({
        isErrorExist: createSelector(
            selectMessage,
            selectDetails,
            (message, details) => message != null || details != null,
        ),
    }),
});

export const {
    name: errorFeatureKey,
    reducer: errorReducer,

    // selectors
    selectMessage,
    selectDetails,
    selectDetailsPlain,
    selectStatus,
    isErrorExist,
} = errorFeature;
