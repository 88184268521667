import { createAction, props } from '@ngrx/store';
import type { IStartSessionRequest } from '../../models/start-session-request.interface';
import type { ITokenResponse } from '../../models/token-response.interface';

export const startSession = createAction('[Session] Start Session', props<{ payload: IStartSessionRequest }>());

export const sessionStarted = createAction('[Session] Session Started', props<{ payload: ITokenResponse }>());

export const loadNewRefreshToken = createAction('[Session] Load New Refresh Token');

export const newRefreshTokenLoaded = createAction(
    '[Session] New Refresh Token Loaded',
    props<{ payload: ITokenResponse }>(),
);
