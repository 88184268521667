/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs';
import { IUserService, USER_SERVICE } from '../../outbound/user.service';
import { NavigationService } from '../../services/navigate.service';
import { UserProfileActions } from '../actions/action-types';

@Injectable()
export class UserProfileEffects {
    constructor(
        private readonly actions$: Actions,
        @Inject(USER_SERVICE) private readonly userService: IUserService,
        private readonly navigationService: NavigationService,
    ) {}

    createUserProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserProfileActions.createUserProfile),
            switchMap((action) => {
                return this.userService.createOrUpdateUserProfile(action.userProfile).pipe(
                    map(() => UserProfileActions.userProfileCreated(action.userProfile)),
                    catchError(() => this.navigationService.navigateToErrorPage()),
                );
            }),
        ),
    );

    updateUserProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserProfileActions.updateUserProfile),
            switchMap((action) => {
                return this.userService.createOrUpdateUserProfile(action.userProfile).pipe(
                    map(() => UserProfileActions.userProfileUpdated(action.userProfile)),
                    catchError(() => this.navigationService.navigateToErrorPage()),
                );
            }),
        ),
    );

    deleteUserProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserProfileActions.deleteUserProfile),
            switchMap(() => this.userService.deleteCurrentUserProfile()),
            map(() => UserProfileActions.userProfileDeleted()),
        ),
    );

    loadUserProfile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserProfileActions.loadUserProfile),
            switchMap(() => this.userService.getCurrentUserProfile()),
            map((userProfile) => UserProfileActions.userProfileLoaded(userProfile)),
        ),
    );

    updateNewsLetterStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UserProfileActions.updateNewsLetterStatus),
            switchMap((action) => {
                return this.userService.updateNewsletterStatus(action.payload).pipe(
                    map(() => UserProfileActions.newsletterStatusUpdated(action.payload)),
                    catchError(() => this.navigationService.navigateToErrorPage()),
                );
            }),
        ),
    );
}
