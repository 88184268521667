import { ANALYTICS_SERVICE } from '../../domain/outbound/analytics.service';
import { CART_SERVICE } from '../../domain/outbound/cart.service';
import { PRODUCTS_SERVICE } from '../../domain/outbound/product.service';
import { SESSION_SERVICE } from '../../domain/outbound/session.service';
import { USER_SERVICE } from '../../domain/outbound/user.service';
import { AnalyticsService } from './analytics.service';
import { SalesOrderService } from './cart.service';
import { ProductsService } from './products.service';
import { SessionService } from './session.service';
import { UserService } from './user.service';

export const REST_CLIENT_SERVICES = [
    {
        provide: ANALYTICS_SERVICE,
        useClass: AnalyticsService,
    },
    {
        provide: CART_SERVICE,
        useClass: SalesOrderService,
    },
    {
        provide: PRODUCTS_SERVICE,
        useClass: ProductsService,
    },
    {
        provide: SESSION_SERVICE,
        useClass: SessionService,
    },
    {
        provide: USER_SERVICE,
        useClass: UserService,
    },
];
