/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { LOCAL_STORAGE_KEYS, SESSION_STORAGE_KEYS } from '../constants/constants';

type LocalStorageKeys = keyof typeof LOCAL_STORAGE_KEYS;
type SessionStorageKeys = keyof typeof SESSION_STORAGE_KEYS;

export const getFromStorage = <T = string>(key: LocalStorageKeys): T | undefined => {
    const data = localStorage.getItem(LOCAL_STORAGE_KEYS[key]);

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!data) return undefined;

    try {
        return JSON.parse(data) as T;
    } catch (error) {
        console.log(error);
        return undefined;
    }
};

export const saveToStorage = (key: LocalStorageKeys, data: any): void => {
    localStorage.setItem(LOCAL_STORAGE_KEYS[key], JSON.stringify(data));
};

export const removeFromStorage = (key: LocalStorageKeys): void => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS[key]);
};

export const getFromSessionStorage = <T = string>(key: SessionStorageKeys): T | undefined => {
    const data = sessionStorage.getItem(SESSION_STORAGE_KEYS[key]);

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!data) return undefined;

    return JSON.parse(data) as T;
};

export const saveToSessionStorage = (key: SessionStorageKeys, data: any): void => {
    sessionStorage.setItem(SESSION_STORAGE_KEYS[key], JSON.stringify(data));
};

export const removeFromSessionStorage = (key: SessionStorageKeys): void => {
    sessionStorage.removeItem(SESSION_STORAGE_KEYS[key]);
};
