/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import type { Action } from '@ngrx/store';
import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import {
    CampaignPendingEnum,
    HomePendingEnum,
    MerchantPendingEnum,
    OrderPendingEnum,
    PrefixEnum,
    UserProfilePendingEnum,
} from '../actions/action-names.enum';
import { ErrorActions, allActionTypes } from '../actions/action-types';

export interface PendingState {
    pendingActions: string[];
}

export const initialState: PendingState = {
    pendingActions: [CampaignPendingEnum.LOAD_CAMPAIGNS],
};

const reducer = createReducer(
    initialState,
    on(...allActionTypes, (state, action: Action & { pending?: string }) => {
        const pendingAction = action.pending ?? '';
        const actionIndex = 1;
        let newState = { ...state };
        if (pendingAction) {
            const pendingActionName = pendingAction.split('_')[actionIndex];
            if (pendingAction.startsWith(PrefixEnum.BEGIN)) {
                if (state.pendingActions.includes(pendingActionName)) {
                    return newState;
                } else {
                    newState = {
                        ...state,
                        pendingActions: [...state.pendingActions, pendingActionName],
                    };
                }
            } else if (pendingAction.startsWith(PrefixEnum.END)) {
                const newPendingActions = newState.pendingActions.filter((query) => query !== pendingActionName);
                newState = {
                    ...state,
                    pendingActions: [...newPendingActions],
                };
            }
        }
        return newState;
    }),
    on(ErrorActions.setError, (_state, _action) => {
        return {
            pendingActions: [],
        };
    }),
);

const pendingFeature = createFeature({
    name: 'pending',
    reducer,
    extraSelectors: ({ selectPendingActions }) => ({
        // campaign selectors
        isCampaignsLoading: createSelector(selectPendingActions, (pendingQueries) =>
            pendingQueries.some((query) => query === CampaignPendingEnum.LOAD_CAMPAIGNS),
        ),
        isCampaignLoading: createSelector(selectPendingActions, (pendingQueries) =>
            pendingQueries.some((query) => query === CampaignPendingEnum.LOAD_CAMPAIGN),
        ),
        // home selectors
        isSuperCashBackLoading: createSelector(selectPendingActions, (pendingQueries) =>
            pendingQueries.some((query) => query === HomePendingEnum.LOAD_SUPER_CASHBACK),
        ),
        isUpcomingDealLoading: createSelector(selectPendingActions, (pendingQueries) =>
            pendingQueries.some((query) => query === HomePendingEnum.LOAD_UPCOMING_DEAL),
        ),
        isNewsTickerLoading: createSelector(selectPendingActions, (pendingQueries) =>
            pendingQueries.some((query) => query === HomePendingEnum.LOAD_NEWS_TICKER),
        ),
        isDiscountOffersLoading: createSelector(selectPendingActions, (pendingQueries) =>
            pendingQueries.some((query) => query === HomePendingEnum.LOAD_DISCOUNT_OFFERS),
        ),
        isDiscountOfferLoading: createSelector(selectPendingActions, (pendingQueries) =>
            pendingQueries.some((query) => query === HomePendingEnum.LOAD_DISCOUNT_OFFER),
        ),
        // order selectors
        isOrdersLoading: createSelector(selectPendingActions, (pendingQueries) =>
            pendingQueries.some((query) => query === OrderPendingEnum.LOAD_ORDERS_GROUPED),
        ),
        isOrderByUuidLoading: createSelector(selectPendingActions, (pendingQueries) =>
            pendingQueries.some((query) => query === OrderPendingEnum.LOAD_ORDER_BY_UUID),
        ),
        isCreateOrderLoading: createSelector(selectPendingActions, (pendingQueries) =>
            pendingQueries.some((query) => query === OrderPendingEnum.CREATE_ORDER),
        ),
        isUpdateOrderLoading: createSelector(selectPendingActions, (pendingQueries) =>
            pendingQueries.some((query) => query === OrderPendingEnum.UPDATE_ORDER),
        ),
        isCheckOutOrderLoading: createSelector(selectPendingActions, (pendingQueries) =>
            pendingQueries.some((query) => query === OrderPendingEnum.CHECKOUT_ORDER),
        ),
        isApplyCouponLoading: createSelector(selectPendingActions, (pendingQueries) =>
            pendingQueries.some((query) => query === OrderPendingEnum.APPLY_COUPON),
        ),
        isCouponFeatureLoading: createSelector(selectPendingActions, (pendingQueries) =>
            pendingQueries.some((query) => query === OrderPendingEnum.LOAD_COUPON_FEATURE),
        ),
        // user profile selectors
        isCreateUserProfileLoading: createSelector(selectPendingActions, (pendingQueries) =>
            pendingQueries.some((query) => query === UserProfilePendingEnum.CREATE_USER_PROFILE),
        ),
        isUpdateUserProfileLoading: createSelector(selectPendingActions, (pendingQueries) =>
            pendingQueries.some((query) => query === UserProfilePendingEnum.UPDATE_USER_PROFILE),
        ),
        isDeleteUserProfileLoading: createSelector(selectPendingActions, (pendingQueries) =>
            pendingQueries.some((query) => query === UserProfilePendingEnum.DELETE_USER_PROFILE),
        ),
        isUserProfileLoading: createSelector(selectPendingActions, (pendingQueries) =>
            pendingQueries.some((query) => query === UserProfilePendingEnum.LOAD_USER_PROFILE),
        ),
        isUpdateNewsLetterStatusLoading: createSelector(selectPendingActions, (pendingQueries) =>
            pendingQueries.some((query) => query === UserProfilePendingEnum.UPDATE_NEWSLETTER_STATUS),
        ),
        // merchants
        isMerchantsLoading: createSelector(selectPendingActions, (pendingQueries) =>
            pendingQueries.some((query) => query === MerchantPendingEnum.LOAD_MERCHANTS),
        ),
    }),
});

export const {
    name: pendingFeatureKey,
    reducer: pendingReducer,

    // selectors
    // campaign selectors
    isCampaignsLoading,
    isCampaignLoading,
    // home selectors
    isSuperCashBackLoading,
    isUpcomingDealLoading,
    isNewsTickerLoading,
    isDiscountOffersLoading,
    isDiscountOfferLoading,
    // order selectors
    isOrdersLoading,
    isOrderByUuidLoading,
    isCreateOrderLoading,
    isUpdateOrderLoading,
    isCheckOutOrderLoading,
    isApplyCouponLoading,
    isCouponFeatureLoading,
    // user profile selectors
    isCreateUserProfileLoading,
    isUpdateUserProfileLoading,
    isDeleteUserProfileLoading,
    isUserProfileLoading,
    isUpdateNewsLetterStatusLoading,
    // merchants
    isMerchantsLoading,
} = pendingFeature;
