import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DealOfTheDayType } from '@modeso/types__tsd-lib-products-be';
import { ZERO_LENGTH } from '../../../../domain/constants/constants';
import type { DealOfTheDay } from '../../../../domain/models/deal-of-the-day.interface';
import { getLanguage } from '../../../../domain/utils/language';
import { replaceSpacesWithDashes } from '../../../../domain/utils/url-with-name.utils';

@Component({
    selector: 'app-deal-of-the-day',
    templateUrl: './deal-of-the-day.component.html',
    styleUrls: ['./deal-of-the-day.component.scss'],
})
export class DealOfTheDayComponent {
    @Input({ required: true }) dealsOfTheDay: DealOfTheDay[] = [];
    @Input({ required: true }) icon: string | undefined;

    private readonly language = getLanguage();

    constructor(private readonly router: Router) {}

    protected dealOfTheDayTypes = DealOfTheDayType;

    protected onCampaignClick(id: string | undefined): void {
        if (id == null) {
            throw new Error('The id of the clicked deal of the deal campaign is undefined');
        }
        const campaignName = this.dealsOfTheDay.find((deal) => deal.id === id)?.campaignName;
        const formattedName = replaceSpacesWithDashes(campaignName);
        void this.router.navigate([`${this.language}`, 'campaign', formattedName, id]);
    }

    protected onDiscountOfferClicked(id: string | undefined): void {
        if (id == null) {
            throw new Error('The id of the clicked deal of the deal discount offers is undefined');
        }
        const discountOffer = this.dealsOfTheDay.find((deal) => deal.id === id);
        const discountOfferBrandName = discountOffer?.discountOfferBrandName;
        const formattedName = replaceSpacesWithDashes(discountOfferBrandName);
        void this.router.navigate([`${this.language}`, 'discountoffers', formattedName, id]);
    }

    protected onNewsClicked(redirectLink?: string): void {
        if (redirectLink == null || redirectLink.trim().length === ZERO_LENGTH) {
            return;
        }

        window.location.href = redirectLink;
    }
}
