import { Component, Input } from '@angular/core';
import type { SafeUrl } from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-campaign-redirect-to-payment',
    templateUrl: './campaign-redirect-to-payment.component.html',
    styleUrls: ['./campaign-redirect-to-payment.component.scss'],
})
export class CampaignRedirectToPaymentComponent {
    @Input({ required: true }) redirectURL!: string;

    constructor(private readonly sanitizer: DomSanitizer) {}

    onButtonAction(): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(this.redirectURL);
    }
}
